import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Nav, NavItem, NavLink } from "reactstrap";

import { icons } from "./constants";

import {
  HistoryLogSections,
  HistoryLogRoles,
} from "src/modules/data-protection/constants";
import Spinner from "react-bootstrap/Spinner";
import * as locales from 'react-date-range/dist/locale';
import { DateRangePicker, defaultInputRanges, defaultStaticRanges  } from "react-date-range";

const TableFilter = ({
  t,
  supplierId,
  onFilterChanged,
  onOkButtonClicked,
  defaultValues,
  handleFetchUsersQuery,
  ...rest
}) => {
  const [filters, setFilters] = useState({
    fromDate: defaultValues.fromDate || null,
    toDate: defaultValues.toDate || null,
    user: defaultValues.user || [],
    sections: defaultValues.sections || [],
    roles: defaultValues.roles || [],
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("date");
  const dateInputRef = useRef();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const staticRangesLabels = {
    Today: t("Today"),
    Yesterday: t("Yesterday"),
    "This Week": t("This Week"),
    "Last Week": t("Last Week"),
    "This Month": t("This Month"),
    "Last Month": t("Last Month")
  };
  
  const inputRangesLabels = {
    "days up to today": t("days up to today"),
    "days starting today": t("days starting today")
  };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item;
  }

  const staticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );
  const inputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

  const renderUserRolesFilterList = () => {
    const result = [];

    for (const roleKey in HistoryLogRoles) {
      result.push(
        <div className="filter-item" key={roleKey}>
          <Form.Check
            checked={filters.roles.find((r) => r === roleKey) ? true : false}
            onChange={({ target }) => {
              if (target.checked) {
                setFilters((filters) => {
                  const result = {
                    ...filters,
                    roles: [...filters.roles, roleKey],
                  };

                  onFilterChanged && onFilterChanged("roles", result);

                  return result;
                });
              } else {
                setFilters((filters) => {
                  const result = {
                    ...filters,
                    roles: [...filters.roles.filter((id) => id !== roleKey)],
                  };

                  onFilterChanged && onFilterChanged("roles", result);

                  return result;
                });
              }
            }}
            type="checkbox"
            id={`user-role-${roleKey}-filter`}
            label={t(HistoryLogRoles[roleKey])}
          />
        </div>
      );
    }

    return result;
  };

  const renderActivitiesFilterList = () => {
    const result = [];

    for (const sectionKey in HistoryLogSections) {
      result.push(
        <div className="filter-item" key={sectionKey}>
          <Form.Check
            checked={
              filters.sections.find((s) => s === sectionKey) ? true : false
            }
            onChange={({ target }) => {
              if (target.checked) {
                setFilters((filters) => {
                  const result = {
                    ...filters,
                    sections: [...filters.sections, sectionKey],
                  };

                  onFilterChanged && onFilterChanged("sections", result);

                  return result;
                });
              } else {
                setFilters((filters) => {
                  const result = {
                    ...filters,
                    sections: [
                      ...filters.sections.filter((id) => id !== sectionKey),
                    ],
                  };

                  onFilterChanged && onFilterChanged("sections", result);

                  return result;
                });
              }
            }}
            type="checkbox"
            id={`activity-${sectionKey}-filter`}
            label={t(HistoryLogSections[sectionKey])}
          />
        </div>
      );
    }

    return result;
  };

  const renderUsersFilterList = () => {
    if (
      handleFetchUsersQuery.isFetching ||
      handleFetchUsersQuery.isLoading
    ) {
      return (
        <div className="text-center">
          <Spinner animation="border" variant="primary" size="md" />
        </div>
      );
    }

    return (handleFetchUsersQuery.data || []).map((user) => {
      return (
        <div className="filter-item" key={user.id}>
          <Form.Check
            checked={filters.user.find((uId) => uId === user.id) ? true : false}
            onChange={({ target }) => {
              if (target.checked) {
                setFilters((filters) => {
                  const result = {
                    ...filters,
                    user: [...filters.user, user.id],
                  };

                  onFilterChanged && onFilterChanged("user", result);

                  return result;
                });
              } else {
                setFilters((filters) => {
                  const result = {
                    ...filters,
                    user: [...filters.user.filter((id) => id !== user.id)],
                  };

                  onFilterChanged && onFilterChanged("user", result);

                  return result;
                });
              }
            }}
            type="checkbox"
            id={`user-${user.id}-filter`}
            label={`${user.first_name + " " + user.last_name}`}
          />
        </div>
      );
    });
  };

  const renderDateFilter = () => {
    return (
      <DateRangePicker
        locale={ locales[rest?.lng] || locales["enUS"]}
        scroll={{ enabled: true }}
        ranges={[
            {
                startDate: filters.fromDate,
                endDate: filters.toDate,
                key: "selection",
              }
            ]}
        onChange={(range) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              fromDate: range.selection.startDate,
              toDate: range.selection.endDate,
            }));
          }}
        staticRanges={staticRanges}
        inputRanges={inputRanges}
        startDatePlaceholder={t("Early")}
        endDatePlaceholder={t("Continuous")}
        rangeColors={["#5664d2"]}
      />
    );
  };

  const renderNavContent = () => {
    switch (activeTab) {
      case "date":
        return renderDateFilter();

      case "user":
        return renderUsersFilterList();

      case "user_role":
        return renderUserRolesFilterList();

      case "activity":
        return renderActivitiesFilterList();

      default:
        return null;
    }
  };
  
  useEffect(() => {
    if (activeTab === "date" && dateInputRef?.current) {
      console.log(dateInputRef);
      dateInputRef.current.focus();
    }
  }, [activeTab]);

  return (
    <div className="custom-table-filter">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="secondary" outline>
          <i className="ri-filter-3-fill"></i>
          <span>{t("Filter")}</span>
        </DropdownToggle>

        <DropdownMenu>
          <div className="filters-container">
            <div className="search-input">
              <InputGroup>
                <Form.Control
                  placeholder="Search"
                  aria-describedby="search-result-input"
                  style={{ borderRight: "none" }}
                />
                <InputGroup.Text
                  className="bg-transparent border-left-0"
                  id="search-result-input"
                  style={{ borderLeft: "none" }}
                >
                  <i className="ri-search-line"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>

            <div className="navs-container">
              <Nav tabs>
                <NavItem
                  onClick={() => setActiveTab("date")}
                  className={`${activeTab === "date" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "date"
                          ? icons.date.active
                          : icons.date.default
                      }
                      alt=""
                    />
                    <span>{t("Date")}</span>
                  </NavLink>
                </NavItem>

                <NavItem
                  onClick={() => setActiveTab("user_role")}
                  className={`${activeTab === "user_role" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "user_role"
                          ? icons.role.active
                          : icons.role.default
                      }
                      alt=""
                    />
                    <span>{t("User Role")}</span>
                  </NavLink>
                </NavItem>
                <NavItem
                  onClick={() => {
                    setActiveTab("user");
                    handleFetchUsersQuery.refetch();
                  }}
                  className={`${activeTab === "user" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "user"
                          ? icons.user.active
                          : icons.user.default
                      }
                      alt=""
                    />
                    <span>{t("User")}</span>
                  </NavLink>
                </NavItem>

                <NavItem
                  onClick={() => setActiveTab("activity")}
                  className={`${activeTab === "activity" && "active"}`}
                >
                  <NavLink>
                    <img
                      src={
                        activeTab === "activity"
                          ? icons.activity.active
                          : icons.activity.default
                      }
                      alt=""
                    />
                    <span>{t("Activity")}</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="navs-content-container">{renderNavContent()}</div>

            <div className="actions-container d-flex align-items-center justify-content-end">
              <Button
                  color="secondary"
                  size="sm"
                  className="me-2"
                  onClick={() =>
                    setFilters({
                      fromDate: null,
                      toDate: null,
                      user: [],
                      sections: [],
                      roles: [],
                    })
                  }
                >
                  {t("Reset")}
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  onOkButtonClicked && onOkButtonClicked(filters);
                  toggle();
                }}
              >
                {t("OK")}
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>

      {/* <Col className="col-12 col-md-3 mb-1 d-flex justify-content-end">
      <Button className="btn btn-primary ms-2"  onClick={handleDownloadPDF}>
        
        {t("Download PDF")}
        <span className="ps-1">
        </span>
      </Button>
      </Col> */}
    </div>
  );
};

export default withNamespaces()(TableFilter);
