import {
    ACTION_HANDLE_UPDATE_NOTIFICATIONS_LIST,
    ACTION_MARK_NOTIFICATION_AS_READ_IN_LIST,
    ACTION_SET_NOTIFICATION_READ_REQUEST_INPROCESS,
    ACTION_HANDLE_EMPTY_NOTIFICATIONS_LIST,
    ACTION_HANDLE_UPDATE_IS_LOADING,
    ACTION_REMOVE_NOTIFICATION_IN_LOCAL_LIST,
    ACTION_NOTIFICATION_IS_DELETING
} from "./actionTypes";

const initialState = {
    isLoading   :   false,
    totalCount  :   0,
    pageIndex   :   1,
    pageSize    :   10,
    list        :   []
};

export const AppNotification = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_HANDLE_EMPTY_NOTIFICATIONS_LIST:
        return {...initialState};
    case ACTION_HANDLE_UPDATE_NOTIFICATIONS_LIST:
        return {
            ...state,
            totalCount  :   action.payload.totalCount,
            pageIndex   :   action.payload.pageIndex || 1,
            list        :   (action.payload.list || []).map((item) => {
                return {
                    ...item,
                    currentState: {
                        isRead:  parseInt(item.status) === 1,
                        isDeleting: false,
                        isLoading: false,
                    }
                }
            })
        }
    case ACTION_MARK_NOTIFICATION_AS_READ_IN_LIST:
        return {
            ...state,
            list        :   state.list.map((notification) => {
                return {
                    ...notification,
                    currentState: {
                        ...notification.currentState,
                        isRead: notification.id === action.payload.notificationId ? true : notification.currentState.isRead
                    }
                }
            })
        }
    case ACTION_SET_NOTIFICATION_READ_REQUEST_INPROCESS:
        return {
            ...state,
            list        :   state.list.map((notification) => {
                return {
                    ...notification,
                    currentState: {
                        ...notification.currentState,
                        isLoading: (notification.id === action.payload.notificationId) ?  action.payload.newStatus : notification.currentState.isLoading
                    }
                }
            })
        }
    case ACTION_REMOVE_NOTIFICATION_IN_LOCAL_LIST:
        return {
            ...state,
            totalCount  :   (state.totalCount - 1),
            list        :   state.list.filter((notification) => notification.id !== action.payload.notificationId)
        }

    case ACTION_NOTIFICATION_IS_DELETING:
        return {
            ...state,
            list        :   state.list.map((notification) => {
                return {
                    ...notification,
                    currentState: {
                        ...notification.currentState,
                        isDeleting: (notification.id === action.payload.notificationId) ?  action.payload.status : notification.currentState.isDeleting
                    }
                }
            })
        }

    case ACTION_HANDLE_UPDATE_IS_LOADING:
        return {
            ...state,
            isLoading  :   action.payload.status
        }
    default:
      return state;
  }
};
