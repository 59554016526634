
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import axios from 'axios';
import { AUTH_API_DOMAIN_URL } from "../../common/constants";
import {
    API_URL_DOCUMENT_USER_DOWNLOAD_BASE
} from 'src/modules/3rd-party-management/constants'
import { toast } from "react-toastify";

const availableModulesRoutes = {
    wb: AUTH_API_DOMAIN_URL,
    cm: AUTH_API_DOMAIN_URL,
    tp: API_URL_DOCUMENT_USER_DOWNLOAD_BASE
};

const Policy = ({
    lng,
    token,
    organization,
    modules,
    history
}) => {
    const [content, setContent] = useState("");

    useEffect(() => {
        if(modules.active && availableModulesRoutes[modules.active]){
            const type = window.location.href.includes('user_policy') ? 'user' : window.location.href.includes('data_privacy') ? "privacy" : "";
            const langId = (organization.languages || []).find(l => l.language_code === lng.replace('_', '-'))?.language?.id;
            
            const fetchUrl = `${(availableModulesRoutes[modules.active]).replace(/^\/+|\/+$/g, '')}/app/policy_type/${type}/language/${langId}/company/${organization.id}`;
            
            axios.get(fetchUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then((response) => {
                if((response.headers['content-type'] || '').toLowerCase() === 'application/pdf'){
                    window.open(fetchUrl);
        
                    return history.goBack();
                }
        
                if (response.data.data) {
                    setContent(response.data.data.policy);
                }
            }).catch(() => {
                toast('An error occurd while downloading the data', {
                    type: 'error'
                });

                return history.goBack();
            });
        }
    }, [lng, modules.active, token]);

    return (
        <div className="ps-3 pt-4">
            <div className="mt-5 pt-5 pb-5 mb-5" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
}

const mapStatetoProps = (state) => {
    const { user, token } = state.Login;
    const { App } = state;
    const organization = state.Organization;
    return { user, token, App, organization, modules: state.Modules };
};

export default connect(mapStatetoProps, {})(withNamespaces()(Policy));
