/**************************************** NOTIFICATION SETTINGS ******************************************* */
export const NotificationSections = (module) =>
  module === "gd"
    ? {
        risk: "Risk Assessment",
        task: "Task Management",
        dsr: "Data Subject Request",
        dbi: "Data Breach Incident",
        comment: "Comment",
        tom: "TOM"
      }
    : module === "tp"
    ? {
        risk: "Risk Assessment",
        task: "Task Management",
        case: "Case",
        comment: "Comment",
        supplier: "Supplier Management"
      }
    : {};

export const NotificationActions = (module) =>
  module === "gd"
    ? {
        comment: {
          comment_report:
            "I would like to receive an email when a user submits a comment",
          comment_mention:
            "I would like to receive an email when I am mentioned in a comment",
        },
        dbi: {
          new_dbi_reported:
            "I would like to receive an email when a user reports a new data breach incident",
          dbi_assign:
            "I would like to receive an email when I am assigned to a data breach incident",
          not_read_dbi:
            "I would like to receive an email when a data breach incident has not been opened after six days",
          not_closed_dbi:
            "I would like to receive an email when a data breach incident has not been closed within three months",
          status_dbi_report:
            "I would like to receive an email when the status of a data breach incident changes from closed to pending deletion",
        },
        dsr: {
          new_dsr_reported:
            "I would like to receive an email when a user submits a data subject request",
          dsr_assign:
            "I would like to receive an email when I am assigned to a data subject request",
          not_read_dsr:
            "I would like to receive an email when a new data subject request has not been opened after six days",
          not_closed_dsr:
            "I would like to receive an email when a data subject request has not been closed within three months",
          status_dsr_report:
            "I would like to receive an email when the status of a data subject request changes from closed to pending deletion",
        },
        risk: {
          risk_assign:
            "I would like to receive an email when a risk is assigned to me",
        },
        task: {
          task_comment:
            "I would like to receive an email when a user comments on a task",
          task_assign:
            "I would like to receive an email when a task is assigned to me",
        },
      }
    : {};
