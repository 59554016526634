import { withNamespaces } from "react-i18next";
import { AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import { Button, Col, Label, Row } from "reactstrap";

const QuestionnaireItem = (props) => {
    const {
        index,
        t,
        question,
        isDraggable,
        isDeletable,
        isEditable,
        selectedLanguage,
        isActive,
        onEditButtonClicked,
        onQuestionTypeChange,
        onQuestionTitleChange,
        onQuestionMandatoryStatusChange,
        onMultipleChoiceOptionChange,
        onMultipleChoiceOptionDeleteButtonClicked,
        onQuestionDeleteButtonClicked,
        onAddNewOption,
        dragHandleProps,
    } = props;
    
    const vars = {
		spacing: 64,
		borderRadius: 9,
		borderColor: "#BAC1FF",
	};
	
	const styles = {
		box: {
			marginBottom: 20,
		},
	
		box__head: {
			height: vars.spacing,
			display: "flex",
			alignItems: "Center",
			justifyContent: "space-between",
			backgroundColor: "#F7F8FF",
			borderRadius: vars.borderRadius,
		},
		box__head__button: {
			width: vars.spacing,
			height: vars.spacing,
			minWidth: vars.spacing,
			color: "#545B9A",
			display: "flex",
			cursor: "pointer",
			alignItems: "center",
			justifyContent: "center",
			textAlign: "center",
			border: 0,
			outline: 0,
			backgroundColor: "transparent",
		},
	
		box__body: {
			padding: vars.spacing / 1.5,
			marginLeft: vars.spacing - 1,
			marginRight: vars.spacing - 1,
			marginBottom: vars.spacing - 1,
			border: "1px solid #E5E8FF",
			borderTop: 0,
			borderBottomLeftRadius: 9,
			borderBottomRightRadius: 9,
		},
	
		multichoiceOption: {
			width: "100%",
			height: 42,
			display: "flex",
			alignItems: "center",
			marginBottom: 12,
			border: `1px solid ${vars.borderColor}`,
			borderRadius: vars.borderRadius,
		},
		multichoiceOption__label: {
			width: 111,
			minWidth: 111,
			height: "100%",
			display: "flex",
			textAlign: "center",
			justifyContent: "center",
			alignItems: "center",
			borderRight: `1px solid ${vars.borderColor}`,
		},
		multichoiceOption__button: {
			width: 45,
			minWidth: 45,
			height: "100%",
			display: "flex",
			textAlign: "center",
			cursor: "pointer",
			justifyContent: "center",
			alignItems: "center",
			borderLeft: `1px solid ${vars.borderColor}`,
		},
		multichoiceOption__option: {
			width: "100%",
			height: "100%",
			position: "relative",
		},
		multichoiceOption__option__input: {
			control: (base) => ({
				...base,
				backgroundColor: "inherit",
			}),
		},
		multichoiceOption__type__select: {
			borderLeft: `1px solid ${vars.borderColor} !important`,
		},
		multichoiceOption__type: {
			width: "100%",
			height: "100%",
			position: "relative",
		},
		multichoiceOption__type__input: {
			control: (base) => ({
				...base,
				height: 42,
				border: "0 !important",
				boxShadow: "0 !important",
				borderRadius: 0,
				borderLeft: `1px solid ${vars.borderColor} !important`,
				backgroundColor: "transparent !important",
			}),
		},
	};

	const questionTypes = [
     
		{
			title: t("Date"),
			value: "date",
		},
        {
			title: t("Legal Entity"),
			value: "legal_entity",
		},
		{
			title: t("Switch"),
			value: "switch",
		},
		{
			title: t("Text Input"),
			value: "text",
		},
		{
			title: t("Text Area"),
			value: "textarea",
		},
		{
			title: t("Radiobox"),
			value: "radiobox",
		},
		{
			title: t("Checkbox"),
			value: "checkbox",
		},
		{
			title: t("Select Box"),
			value: "select",
		},
        {
			title: t("Multi Select"),
			value: "multi_select",
		},
        {
			title: t("Category"),
			value: "category",
		},
        {
			title: t("Multi Select Country"),
			value: "country_multi_select",
		},
        {
			title: t("Country List"),
			value: "country_list",
		},
		{
			title: t("File Upload"),
			value: "file_upload",
		}
	];

	const customQuestionTypes = [
		{
			title: t("Date"),
			value: "date",
		},
		{
			title: t("Switch"),
			value: "switch",
		},
		{
			title: t("Text Input"),
			value: "text",
		},
		{
			title: t("Text Area"),
			value: "textarea",
		},
		{
			title: t("File Upload"),
			value: "file_upload",
		},
		{
			title: t("Radiobox"),
			value: "radiobox",
		},
		{
			title: t("Checkbox"),
			value: "checkbox",
		}
	];

	const multipleChoiceInputFields = [
		"select", "radiobox", "checkbox", "multi_select"
	];

    return (
        <li>
            <div style={{ ...styles.box, cursor: (!isDraggable) ? 'not-allowed' : 'move' }} className={question.type === "initial" || !isDeletable && "disable-question-box"} {...dragHandleProps} draggable>
                <div style={styles.box__head}>
                    <button disabled={!isDraggable} className="btn--drag-and-drop" type="button" style={{ ...styles.box__head__button, borderRight: "1px solid #E5E8FF", cursor: !isDraggable ? 'not-allowed' : 'move' }} title={t("Drag and Drop")}>
                        <i className="ri-arrow-up-down-fill"></i>
                    </button>

                    <div style={{ width: "100%" }}>
                        <span style={{ padding: "0 20px 0 25px", color: "#1A1D30", fontWeight: "bolder" }}>{`Q${index + 1}:`}</span>
                        <span style={{ color: "#5D5E6E", fontWeight: "bolder" }}>{t(question.title) || ""}</span>
                    </div>

                    <div style={{ display: "flex" }}>
                        <button
                            type="button"
                            title={t("Edit")}
                            style={{ ...styles.box__head__button, borderLeft: "1px solid #E5E8FF" }}
                            disabled={!isEditable}
                            onClick={() => {
                                onEditButtonClicked && onEditButtonClicked();
                            }}>
                            <i className="ri-edit-line"></i>
                        </button>

                        <button
                            type="button"
                            disabled={!isDeletable || selectedLanguage !== "en"}
                            title={t("Delete")}
                            style={{ ...styles.box__head__button, borderLeft: "1px solid #E5E8FF", color: "#7f0000" }}
                            onClick={() => { onQuestionDeleteButtonClicked && onQuestionDeleteButtonClicked(index) }}>
                            <i className="ri-delete-bin-line"></i>
                        </button>
                    </div>
                </div>

                {isActive ? (
                    <div style={styles.box__body}>
                        <Row>
                            <Col xs={8}>
                                <div>
                                    <Label style={{ color: "#1A1D30" }} className="form-label">
                                        {t("Title")}
                                    </Label>

                                    <AvField
                                        disabled={question.isCategory}
                                        type="text"
                                        name={`${"question-title"}-${index}`}
                                        value={t(question.title) || ""}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        className="dissco--question-genrator--input"
                                        onChange={(e) => onQuestionTitleChange && onQuestionTitleChange(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div style={{ position: "relative" }}>
                                    <Label style={{ color: "#1A1D30" }} className="form-label">
                                        {t("Type")}
                                    </Label>
                                    <AvField
                                        type="select"
                                        name={`${"question-type"}-${index}`}
                                        style={{ border: "#BAC1FF !important" }}
                                        value={question.type || ""}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        disabled={selectedLanguage !== "en"}
                                        className="dissco--question-genrator--input"
                                        onChange={(e) => onQuestionTypeChange(e.target.value)}>
                                        {questionTypes.map((questionType, index) => (
                                            <option key={`question-type-${index}`} value={questionType.value}>
                                                {t(questionType.title)}
                                            </option>
                                        ))}
                                    </AvField>
                                    <i className="ri-arrow-down-s-line" style={{ position: "absolute", bottom: 6, right: 15, fontSize: 20 }}></i>
                                </div>
                            </Col>
                            {multipleChoiceInputFields.includes(question.type) && (
                                <Col xs={12}>
                                    {question?.options?.length ? (
                                        <div>
                                            <Label style={{ color: "#1A1D30" }} className="form-label mt-4">
                                                {t("Options")}
                                            </Label>
                                            {question.options.map((option, optionIndex) => (
                                                <div key={optionIndex} id={`option-${optionIndex}`} style={styles.multichoiceOption}>
                                                    <div style={{ ...styles.multichoiceOption__label }}>
                                                        <span style={{ whiteSpace: "nowrap" }}>{`${t("Option")} ${optionIndex + 1}`}</span>
                                                    </div>
                                                    <div style={{ ...styles.multichoiceOption__option }}>
                                                        <AvField
                                                            type="text"
                                                            name={`${"question-option-"}-${index}-${optionIndex}`}
                                                            style={styles.multichoiceOption__option__input}
                                                            value={t(option.title) || ""}
                                                            placeholder={t("Enter title")}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            className="dissco--question-genrator--form-control dissco--question-genrator--input"
                                                            onChange={(e) => onMultipleChoiceOptionChange && onMultipleChoiceOptionChange(e.target.value, optionIndex, index, 'title')}
                                                        />
                                                    </div>
                                                    <div style={{ ...styles.multichoiceOption__type, borderLeft: `1px solid ${vars.borderColor}` }}>
                                                        <AvField
                                                            type="select"
                                                            name={`${"question-option-type-"}-${index}-${optionIndex}`}
                                                            style={styles.multichoiceOption__type__select}
                                                            value={option.type || ""}
                                                            className="dissco--question-genrator--form-control dissco--question-genrator--input"
                                                            placeholder={t("Select Type")}
                                                            disabled={selectedLanguage !== "en"}
                                                            validate={{
                                                                required: { value: true },
                                                            }}
                                                            onChange={(e) => onMultipleChoiceOptionChange && onMultipleChoiceOptionChange(e.target.value, optionIndex, index, 'type')}>
                                                            <option value={null}>{t("Select Type")}</option>
                                                            {customQuestionTypes.map((f, j) => (
                                                                <option key={j} value={f.value}>
                                                                    {t(f.title)}
                                                                </option>
                                                            ))}
                                                        </AvField>
                                                        <i className="ri-arrow-down-s-line" style={{ position: "absolute", top: 6, right: 15, fontSize: 20 }}></i>
                                                    </div>
                                                    <div style={{ ...styles.multichoiceOption__button }} onClick={() => onMultipleChoiceOptionDeleteButtonClicked && onMultipleChoiceOptionDeleteButtonClicked(option.id, question.id, optionIndex, index)}>
                                                        <button type="button" title={t("Delete")} style={{ backgroundColor: "transparent", border: 0, outline: 0, color: "#505d69" }}>
                                                            <i className="ri-delete-bin-line" style={{ top: 3, position: "relative", fontSize: "96%" }}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}
                                    {selectedLanguage === "en" ? (
                                        <div>
                                            <Button color="secondary" size="md" outline block style={{ width: "100%" }} onClick={() => onAddNewOption(question.id)} className="dissco--question-genrator--button dissco--question-genrator--button--md mt-3">{`+ ${t("Add Options")}`}</Button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </Col>
                            )}
                            <Col xs={12} className="mt-4">
                                <AvCheckboxGroup name={`${"question-is-mandatory"}-${index}`} className="dissco--question-genrator--checkbox" onChange={(e) => onQuestionMandatoryStatusChange && onQuestionMandatoryStatusChange(e.target.checked) }>
                                    <Row>
                                        <Col>
                                            <AvCheckbox label={t("Mandatory Answer")} value="isMandatory" checked={ question.isMandatory } />
                                        </Col>
                                    </Row>
                                </AvCheckboxGroup>
                            </Col>
                        </Row>
                    </div>
                ) : null}
            </div>
        </li>
    )
};

export default withNamespaces()(QuestionnaireItem);