import React, { memo, useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import MessageBox from "src/modules/data-protection/components/MessageBox";
import ReportIncidenService from "../../components/api/ReportIncidenService";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Label } from "reactstrap";

const MessageComponent = ({ t, caseId, type, isReportDeleted = false }) => {
  const [mentionSuggestionList, setMentionSuggestionList] = useState([]);

  const messageSettings = {
    messageListUrl: type === "internal" ? "report.listCommentInternal" : "report.listComment",
    sendMessageUrl: type === "internal" ? "report.addCommentInternal" : "report.addComment",
    attachmentDownloadUrl: "/report/attachment/{attachmentId}/download",
    attachmentUploadUrl: "report.uploadAttachment",
    attachmentDeleteUrl: "attachment/",
    payloadId: "report_case_id",
    assetId: caseId,
    mention: {
      enable: type === "internal" ?  true: false,
      suggestionList: mentionSuggestionList,
    },
  };

  const handleFetchCommunicationMentionSuggestionListQuery = useQuery({
    queryKey: ["fetch-communication-mention-suggestion-list-query"],
    queryFn: async () => {
      const service = new ReportIncidenService();
      return await service.mentionCommentSearch(caseId, {
        query: "",
      });
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);
      toast(t("An error occurred while fetching analyst admins list."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    
    const { data } = handleFetchCommunicationMentionSuggestionListQuery ?? {};
    if (data && Array.isArray(data)) {
      const list = data.map((item) => ({
        text: item.full_name,
        url: item.id,
        value: item.full_name,
      }));
      setMentionSuggestionList(list);
    }
  }, [handleFetchCommunicationMentionSuggestionListQuery.data, type]);

  if (
    handleFetchCommunicationMentionSuggestionListQuery.isLoading ||
    handleFetchCommunicationMentionSuggestionListQuery.isFetching ||
    !caseId
  ) {
    return (
      <div className="dt-field dt-skeleton" style={{ width: "100%", height: "320px" }}></div>
    );
  }

  if(isReportDeleted) {
    return (
      <Label className="text-danger d-flex justify-content-center">{t("The case has been deleted.")}</Label>
    );
  }

  return (
    <MessageBox
      settings={{
        ...messageSettings,
        headerOptions: {
          displayFilters: true,
          title: type === "internal" ? "Internal Communication" : "Messages",
          tooltipText: type === "internal" ? "Visible only to internal users. You can mention someone using '@'." : "Attention: This message will be sent to the reporting person."
        },
      }}
    />
  );
};

export default withNamespaces()(memo(MessageComponent));
