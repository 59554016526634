import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class DataProtectionHardwareService {
  static instance;
  resource = `${API_BASE_URL}/hardware/`;

  constructor() {}

  static getInstance() {
    
    if (!DataProtectionHardwareService.instance) {
      DataProtectionHardwareService.instance =
        new DataProtectionHardwareService();
    }


    return DataProtectionHardwareService.instance;
  }

	async list(pageIndex, pageSize, filters) {
		const response = await axios.get(`${this.resource}list`, {
			params: {
				pageSize: pageSize,
				pageIndex: pageIndex,
				...filters
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('LOCAL_STORAGE_KEY_ACCESS_TOKEN')}`,
			},
		});

    return response.data.data;
  }

  async downloadTemplate() {
    const response = await axios.get(`${this.resource}template/download`, {
      maxBodyLength: Infinity,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data;
  }

  async getCount() {
    const response = await axios.get(`${API_BASE_URL}/hardware_count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data;
  }


  async manufacturers(pageSize, pageIndex, sortOrder, sortField) {
    const response = await axios.get(`${this.resource}manufacturers`, {
      params: {
        pageSize,
        pageIndex,
        sortOrder,
        sortField,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async models(pageSize, pageIndex, sortOrder, sortField) {
    const response = await axios.get(`${this.resource}models`, {
      params: {
        pageSize,
        pageIndex,
        sortOrder,
        sortField,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async operatingSystems(pageSize, pageIndex, sortOrder, sortField) {
    const response = await axios.get(`${this.resource}operating_systems`, {
      params: {
        pageSize,
        pageIndex,
        sortOrder,
        sortField,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async deviceTypes() {
    const response = await axios.get(`${this.resource}types`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async create(items) {
    const response = await axios.post(`${this.resource}create`, items, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async changeStatus(data) {
    const response = await axios.put(`${this.resource}change_status`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async changeAssignedAnalyst(data) {
    const response = await axios.post(`${this.resource}change_analyst`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`
      },
    });

    return response.data.data;
  }

  async changePriority(data) {
    const response = await axios.put(`${this.resource}change_priority`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`
      },
    });

    return response.data.data;
  }


  async AddEntity(data) {
    const response = await axios.post(`${this.resource}add_legal`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`
      },
    });

    return response.data.data;
  }

  async RemoveEntity(data) {
    const response = await axios.delete(`${this.resource}remove_legal`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN")}`
        },
        data: data // Pass the data here
    });

    return response.data.data;
}

  async downloadCSV() {
    const response = await axios.get(`${this.resource}download`, {
      maxBodyLength: Infinity,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data;
  }
 
  async details(hardwareId) {
    const response = await axios.get(`${this.resource}${hardwareId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async updateDetails(hardwareId, payload) {
    const response = await axios.post(`${this.resource}${hardwareId}/edit`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`
      },
    });

    return response.data.data;
  }

  async delete(id, reason, erasedBy, erasedAt, disposedBy, disposedAt) {
    if(!id) {
      return;
    }
    
    const data = {
      reason: reason,
      erasedBy: erasedBy,
      erasedAt: erasedAt,
      disposedBy: disposedBy,
      disposedAt: disposedAt,
    };
    const response = await axios.post(`${this.resource}${id}/delete`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }

  async uploadCSV(formData) {
    const response = await axios.post(`${this.resource}upload_csv`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    })
    return response.data.data
  }

  async handoverHardware(id, data) {
    const response = await axios.post(`${this.resource}${id}/handover`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    })
    return response.data.data
  }

  async toggleFlag(data){
    const response = await axios.put(`${this.resource}toggle_flag`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`
      },
    });

    return response.data.data;
  }

  // Approve Selection
  async approveSelection(payload) {
    const response = await axios.post(`${this.resource}multi_approve`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
        )}`,
      },
    });

    return response.data.data;
  }
}
