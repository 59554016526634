import { Editor } from "draft-js";
import { withNamespaces } from "react-i18next";
import { MdOutlineModeEdit } from "react-icons/md";
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";
import LocalizedPhoneInput from "src/modules/3rd-party-management/components/LocalizedPhoneInput";

const Creditor = ({
    t,
    goToSpecificTab,
    goToPrevTab,
    onSubmit,
    isLoading,
    values
}) => {
    const handleBack = () => {
        goToPrevTab();
    };

    const {
        companyDetails,
        contacts,
        factory
    } = values;

    const factoryAddresses = (factory.factory_addresses || []);
    const officeAddresses = (factory.office_addresses || []);
    const products = (factory.products || []);

    return (
        <>
            <div className="confirm-form-sections-container">
                {/* Company Detials */}
                <div className="section">
                    <div className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`1. ${t("Company Details")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(1)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>
                    <div className="section-body mt-0">
                        <Row>
                            <Col lg="4" md="4" sm="12">
                                <Label for="companyName">
                                    {t("Company Name")}: 
                                </Label>
                                <p>{companyDetails.company_name}</p>
                            </Col>

                            <Col lg="4" md="4" sm="12">
                                <Label for="industry">{t("Industries")}: </Label>
                                <p style={{ whiteSpace: 'pre-line' }}>
                                    {(companyDetails.industries || []).map((industry) => industry.label).join('\n')}
                                </p>
                            </Col>

                            <Col lg="4" md="4" sm="12">
                                <Label for="vatNumber">{t("VAT ID")}: </Label>
                                <p>{t(companyDetails.vat_number)}</p>
                            </Col>
                        </Row>

                        <Row>
                            {isFeatureEnabledForActiveModule('entity_management') && (
                                <Col lg="4" md="6" sm="12">
                                    <Label>{t("Connected Legal Entities")}: </Label>
                                    <p>{(companyDetails.entities || []).map((legal) => legal.label).join(', ')}</p>
                                </Col>
                            )}
                            
                            <Col lg="4" md="6" sm="12">
                                <Label for="companySize">{t("Company Size")}: </Label>
                                <p>{t(companyDetails.company_size)}</p>
                            </Col>

                            <Col lg="4" md="6" sm="12">
                                <Label for="website">{t("Website")}: </Label>
                                <p>{companyDetails.website}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Label for="description">
                                    {t("Description")}: 
                                </Label>
                                <Editor
                                    editorState={companyDetails.description}
                                    readOnly={true}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* Factory Sites and Products */}
                <div className="section">
                    <div
                        className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`2. ${t("Factory Sites and Products")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(2)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>

                    <div className="section-body mt-0">
                        {/* Office Address */}
                        <label>{t("Office Address")}</label>
                        <ol>
                            {officeAddresses.map((address, i) => {
                                return (
                                    <li key={i}>
                                        <Row className="mb-2">
                                            <Col md="3" sm="12">
                                                <Label for="country">{t("Country")}: </Label>
                                                <p>{t(address?.country?.label)}</p>
                                            </Col>

                                            <Col md="2" sm="12">
                                                <Label for="postal-code">{t("Postal Code")}: </Label>
                                                <p>{t(address?.postalCode)}</p>
                                            </Col>

                                            <Col md="2" sm="12">
                                                <Label for="city">{t("City")}: </Label>
                                                <p>{t(address?.city)}</p>
                                            </Col>

                                            <Col md="5" sm="12">
                                                <Label for="extra">{t("Street, Number")}: </Label>
                                                <p>{t(address?.extra)}</p>
                                            </Col>
                                        </Row>
                                    </li>
                                );
                            })}
                        </ol>

                        {/* Factory Address */}
                        <label>{t("Factory Location")}</label>
                        {factoryAddresses.length > 0 ? (
                            <ol>
                                {factoryAddresses.map((address, i) => {
                                    return (
                                        <li key={i}>
                                            <Row className="mb-2">
                                                <Col md="3" sm="12">
                                                    <Label for="country">{t("Country")}: </Label>
                                                    <p>{t(address.country?.label)}</p>
                                                </Col>

                                                <Col md="2" sm="12">
                                                    <Label for="postal-code">{t("Postal Code")}: </Label>
                                                    <p>{t(address.postalCode)}</p>
                                                </Col>

                                                <Col md="2" sm="12">
                                                    <Label for="city">{t("City")}: </Label>
                                                    <p>{t(address.city)}</p>
                                                </Col>

                                                <Col md="5" sm="12">
                                                    <Label for="extra">{t("Street, Number")}: </Label>
                                                    <p>{t(address.extra)}</p>
                                                </Col>
                                            </Row>
                                        </li>
                                    )
                                })}
                            </ol>
                        ) : (
                            <div className="alert alert-warning">
                                <p className="m-0">{t('This list is empty')}</p>
                            </div>
                        )}

                        {/* Products */}
                        <div hidden={!products?.length}>
                            <label>{t("List of products supplied")}</label>
                            <Row className="mb-2">
                                <Col sm='12'>
                                    <div className="table-container">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "25%" }}>{t("Product Name")}</th>
                                                    <th style={{ width: "75%" }}>{t("Description")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products.map((product, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ width: "25%" }}>
                                                                <p>
                                                                    {product.product.label}
                                                                </p>
                                                            </td>
                                                            <td style={{ width: "75%" }}>
                                                                <p>
                                                                    {product.description}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                {/* Contact Person */}
                <div className="section">
                    <div
                        className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`3. ${t("Contact Person")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(3)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>

                    <div className="section-body mt-0">
                        {contacts.filter((contact) => !contact.isTemp).length > 0 ? (
                            <ol>
                                {contacts.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <Row>
                                                <Col md="4" sm="12">
                                                    <Label for="fullName">{t("Full Name")}: </Label>
                                                    <p>{`(${t(item.title)}) ${item.firstName} ${item.lastName}`}</p>
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label for="position">{t("Position")}: </Label>
                                                    <p>{t(item.position)}</p>
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label for="language">{t("Language")}: </Label>
                                                    <p>{t(item.language?.label)}</p>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="4" sm="12">
                                                    <Label for="email">{t("Email")}: </Label>
                                                    <p>{item.email}</p>
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label for="phone">{t("Phone Number")}: </Label>
                                                    <LocalizedPhoneInput
                                                        disabled={true}
                                                        buttonStyle={{
                                                            display: 'none'
                                                        }}
                                                        dropdownStyle={{
                                                            border: 'none'
                                                        }}
                                                        inputStyle={{
                                                            border: 'none',
                                                            paddingLeft: '0px',
                                                            color: '#000',
                                                            fontSize: '14px',
                                                            background: 'transparent'
                                                        }}
                                                        disableDropdown={true}
                                                        value={ item.phoneNumber }
                                                    />
                                                </Col>
                                            </Row>
                                        </li>
                                    );
                                })}
                            </ol>
                        ) : (
                            <div className="alert alert-warning">
                                <p className="m-0">{t('This list is empty')}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Row className="mb-4">
                <FormGroup
                    style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "space-between",
                    }}
                    className="actions-buttons-container"
                >
                    <Button color="primary" disabled={isLoading} onClick={handleBack} outline>
                        {t("Back")}
                    </Button>
                    <Button color="primary" disabled={isLoading} onClick={onSubmit}>
                        {isLoading ? (
                            <>
                                {t("Creating...")}
                            </>
                        ) : (
                            <>
                                {t("Create")}
                            </>
                        )}
                    </Button>
                </FormGroup>
            </Row>

            <Modal isOpen={isLoading}>
                <ModalHeader>
                    <div>
                        <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                        {t('Creating new third party')}
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    <p>
                        {t("This might take several minutes.please don't close or refresh the page")}
                    </p>
                </ModalBody>
            </Modal>
        </>
    )
}

const OwnCompany = ({
    t,
    goToSpecificTab,
    goToPrevTab,
    onSubmit,
    isLoading,
    values
}) => {
    const handleBack = () => {
        goToPrevTab();
    };

    const {
        companyDetails,
        contacts,
        factory
    } = values;

    const factoryAddresses = (factory.factory_addresses || []);
    const officeAddresses = (factory.office_addresses || []);
    const products = (factory.products || []);

    return (
        <>
            <div className="confirm-form-sections-container">
                <div className="section">
                    <div
                        className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`1. ${t("Company Details")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(1)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>
                    <div className="section-body mt-0">
                        <Row>
                            <Col lg="4" md="4" sm="12">
                                <Label>{t("Connected Legal Entity")}: </Label>
                                <p>{companyDetails.company_name}</p>
                            </Col>

                            <Col lg="4" md="4" sm="12">
                                <Label for="industry">{t("Industries")}: </Label>
                                <p style={{ whiteSpace: 'pre-line' }}>
                                    {(companyDetails.industries || []).map((industry) => industry.label).join('\n')}
                                </p>
                            </Col>

                            <Col lg="4" md="4" sm="12">
                                <Label for="vatNumber">{t("VAT ID")}: </Label>
                                <p>{companyDetails.vat_number}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="4" sm="12">
                                <Label for="companySize">{t("Company Size")}: </Label>
                                <p>{t(companyDetails.company_size)}</p>
                            </Col>

                            <Col md="8" sm="12">
                                <Label for="website">{t("Website")}: </Label>
                                <p>{companyDetails.website}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Label for="description">{t("Description")}: </Label>
                                <Editor
                                    editorState={companyDetails.description}
                                    readOnly={true}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="section">
                    <div
                        className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`2. ${t("Factory Sites and Products")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(2)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>
                    <div className="section-body mt-0">
                        <label>{t("Office Address")}</label>
                        <ol>
                            {officeAddresses.map((address, i) => {
                                return (
                                    <li key={i}>
                                        <Row className="mb-2">
                                            <Col md="3" sm="12">
                                                <Label for="country">{t("Country")}: </Label>
                                                <p>{t(address.country?.label)}</p>
                                            </Col>

                                            <Col md="2" sm="12">
                                                <Label for="postal-code">{t("Postal Code")}: </Label>
                                                <p>{address.postalCode}</p>
                                            </Col>

                                            <Col md="2" sm="12">
                                                <Label for="city">{t("City")}: </Label>
                                                <p>{t(address.city)}</p>
                                            </Col>

                                            <Col md="5" sm="12">
                                                <Label for="extra">{t("Street, Number")}: </Label>
                                                <p>{address.extra}</p>
                                            </Col>
                                        </Row>
                                    </li>
                                );
                            })}
                        </ol>

                        <label>{t("Factory Location")}</label>
                        {factoryAddresses.length > 0 ? (
                            <ol>
                                {factoryAddresses.map((address, i) => {
                                    return (
                                        <li key={i}>
                                            <Row className="mb-2">
                                                <Col md="3" sm="12">
                                                    <Label for="country">{t("Country")}: </Label>
                                                    <p>{t(address.country?.label)}</p>
                                                </Col>

                                                <Col md="2" sm="12">
                                                    <Label for="postal-code">{t("Postal Code")}: </Label>
                                                    <p>{address.postalCode}</p>
                                                </Col>

                                                <Col md="2" sm="12">
                                                    <Label for="city">{t("City")}: </Label>
                                                    <p>{address.city}</p>
                                                </Col>

                                                <Col md="5" sm="12">
                                                    <Label for="extra">{t("Street, Number")}: </Label>
                                                    <p>{address.extra}</p>
                                                </Col>
                                            </Row>
                                        </li>
                                    )
                                })}
                            </ol>
                        ) : (
                            <div className="alert alert-warning">
                                <p className="m-0">{t('This list is empty')}</p>
                            </div>
                        )}

                        <div hidden={!products?.length}>
                            <label>{t("List of products supplied")}</label>
                            <Row className="mb-2">
                                <Col sm='12'>
                                    <div className="table-container">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "25%" }}>{t("Product Name")}</th>
                                                    <th style={{ width: "75%" }}>{t("Description")}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {products.map((product, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ width: "25%" }}>
                                                                <p>
                                                                    {product.product.label}
                                                                </p>
                                                            </td>
                                                            <td style={{ width: "75%" }}>
                                                                <p>
                                                                    {product.description}
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <div
                        className="section-header"
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{`3. ${t("Responsible Person")}`}</span>
                        <Button color="primary" onClick={() => goToSpecificTab(3)} outline className="edit-section-btn">
                            <MdOutlineModeEdit />
                        </Button>
                    </div>

                    <div className="section-body mt-0">
                        {contacts.length > 0 ? (
                            <ol>
                                {contacts.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <Row>
                                                <Col md="4" sm="12">
                                                    <Label>{t("First Name")}: </Label>
                                                    <p>{item?.firstName}</p>
                                                </Col>

                                                <Col md="4" sm="12">
                                                    <Label>{t("Last Name")}: </Label>
                                                    <p>{item?.lastName}</p>
                                                </Col>
                                            </Row>
                                        </li>
                                    );
                                })}
                            </ol>
                        ) : (
                            <div className="alert alert-warning">
                                <p className="m-0">{t('This list is empty')}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Row className="mb-4">
                <FormGroup
                    style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "space-between",
                    }}
                    className="actions-buttons-container"
                >
                    <Button color="primary" disabled={isLoading} onClick={handleBack} outline>
                        {t("Back")}
                    </Button>
                    <Button color="primary" disabled={isLoading} onClick={onSubmit}>
                        {isLoading ? (
                            <>
                                {t("Creating...")}
                            </>
                        ) : (
                            <>
                                {t("Create")}
                            </>
                        )}
                    </Button>
                </FormGroup>
            </Row>

            <Modal isOpen={isLoading}>  
                <ModalHeader>
                    <div>
                        <Spinner className="me-2" animation="border" variant="info" size="sm"/>
                        {t('Creating new own company')}
                    </div>
                </ModalHeader>
                
                <ModalBody>
                    <p>
                        {t("This might take several minutes.please don't close or refresh the page")}
                    </p>
                </ModalBody>
            </Modal>
        </>
    )
}

const ReviewReport = ({
  supplierType,
  ...elseProps
}) => {
    if(supplierType === 'own'){
        return (
            <OwnCompany 
                {...elseProps}
            />
        );
    }

    return (
        <Creditor 
            {...elseProps}
        />
    );
};

export default withNamespaces()(ReviewReport);
