import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Label, ListGroup, ListGroupItem, Badge } from "reactstrap";
import Select from "react-select";
import { API_BASE_URL, EMPTY_LIST } from "src/common/constants";
import UserUtils from "src/services/utils/UserUtils";
import axios from "axios";
import { toast } from "react-toastify";

const TPDDThirdPartiesList = ({
    t,
    assignedAnalyst,
    user,
    reportIsDeleted,
    reportCaseId,
    defaultValues = [],
    token,
    modules
}) => {
    const userUtils = new UserUtils();
    const [hasTPModule, setHasTPModule] = useState(false);
    const [expandedThirdparties, setExpandedThirdparties] = useState(false);
    const [selectedThirdParties, setSelectedThirdParties] = useState({
        isLoading: false,
        items: defaultValues.map((item) => {
            return {
                value: item.id,
                label: item.name
            }
        })
    });
    const [thirdParties, setThirdParties] = useState({
        isLoading: false,
        items: []
    });

    const userType = userUtils.isAnalystAdmin(user) ? 'admin' : (
        userUtils.isAnalyst(user) ? 'analyst' : 'else'
    );

    const handleFetchThirdParties = () => {
        setThirdParties((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });
        
        axios.get(`${API_BASE_URL}/organization/third_parties`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({ data }) => {
            if(data.data && Array.isArray(data.data)){
                setThirdParties((currentState) => {
                    return {
                        ...currentState,
                        isLoading: false,
                        items: data.data.map((thirdparty) => {
                            return {
                                label: thirdparty.name,
                                value: thirdparty.id
                            }
                        })
                    }
                });
            }
        }).catch(() => {
            setThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("Failed to fetch third parties list."), {
                type: "error",
            });
        });
    }

    const handleAddThirdParty = (id) => {
        setSelectedThirdParties((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });

        axios.post(
            `${API_BASE_URL}/report_case/add_third_party`,
            {
                reportCaseId: reportCaseId,
                thirdPartyId: id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then(({ data }) => {
            setSelectedThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false,
                    items: (data?.data?.third_parties || []).map((thirdParty) => {
                        return {
                            value: thirdParty.id,
                            label: thirdParty.name
                        }
                    })
                }
            });

            toast(t("The selected third party has been successfully added to the list."), {
                type: "success",
            });
        }).catch(() => {
            setSelectedThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("An error occurred while attempting to add the third party to the list."), {
                type: "error",
            });
        })
    }

    const handleRemoveThirdParty = (id) => {
        setSelectedThirdParties((currentState) => {
            return {
                ...currentState,
                isLoading: true
            }
        });

        axios.post(
            `${API_BASE_URL}/report_case/remove_third_party`,
            {
                reportCaseId: reportCaseId,
                thirdPartyId: id
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then(({ data }) => {
            setSelectedThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false,
                    items: (data?.data?.third_parties || []).map((thirdParty) => {
                        return {
                            value: thirdParty.id,
                            label: thirdParty.name
                        }
                    })
                }
            });

            toast(t("The third party has been successfully removed from the list."), {
                type: "success",
            });
        }).catch(() => {
            setSelectedThirdParties((currentState) => {
                return {
                    ...currentState,
                    isLoading: false
                }
            });

            toast(t("An error occurred while attempting to remove the third party from the list."), {
                type: "error",
            });
        })
    }

    useEffect(() => {
        setHasTPModule(
            !modules.isLoading && (modules.list || []).findIndex((moduleItem) => {
                return moduleItem.name === 'tp'
            }) > -1
        )
    }, [ modules ]);

    useEffect(() => {
        if(hasTPModule){
            handleFetchThirdParties();
        }
    }, [ hasTPModule ]);

    if(!hasTPModule || userType === 'else' || (
        userType === 'analyst' && user.id !== assignedAnalyst.user?.value
    )) return null;

    return (
        <div className="module__tpdd-thirdparties-selection">
            <Label className="form-label text-dark d-flex justify-content-between">
                {t("Select third parties to associate with this incident")}:
                <span
                    onClick={() => setExpandedThirdparties(!expandedThirdparties)}
                    className="expand-btn btn-primary"
                >
                    {expandedThirdparties
                    ? t("Collapse list")
                    : t("Expand list")}
                </span>
            </Label>

            <div className="items-container">
                <div>   
                    {selectedThirdParties.items.length > 0 ? (
                        <ListGroup
                            type="inline"
                            style={{
                                maxHeight: expandedThirdparties ? "171px" : "51px"
                            }}
                        >
                            {selectedThirdParties.items.map((thirdparty, index) => {
                                return (
                                    <ListGroupItem key={index}>
                                        {thirdparty.label}
                                        {(userType === 'admin' || !assignedAnalyst.isReadOnly) && (
                                            <Badge
                                                className="delete-btn text-dark pt-2"
                                                color="light"
                                                onClick={() => {
                                                    handleRemoveThirdParty(thirdparty.value);
                                                }}
                                            >
                                            X
                                            </Badge>
                                        )}
                                    </ListGroupItem>
                                )
                            })}   
                        </ListGroup>
                    ) : (
                        <p
                            className="empty-list-message"
                            style={{
                                maxHeight: expandedThirdparties ? "163px" : "43px",
                            }}
                        >
                            {t(EMPTY_LIST)}
                        </p>
                    )}

                    {!reportIsDeleted && (
                        <div className="mt-2">
                            <Select
                                isLoading={thirdParties.isLoading}
                                isDisabled={thirdParties.isLoading || selectedThirdParties.isLoading}
                                className={"select--filter-categories"}
                                classNamePrefix="select2-selection"
                                options={(thirdParties.items).filter((thirdParty) => {
                                    return selectedThirdParties.items.findIndex((item) => {
                                        return thirdParty.value === item.value
                                    }) < 0
                                })}
                                value={[]}
                                onChange={(e) => {
                                    handleAddThirdParty(e.value)
                                }}
                                placeholder={t("Select third parties")}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

const mapStatetoProps = (state) => {
    const { token, user } = state.Login;
    const { Modules } = state;
  
    return {
        token,
        user,
        modules: Modules
    };
};

export default withNamespaces()(
    connect(
        mapStatetoProps
    )(
        TPDDThirdPartiesList
    )
);