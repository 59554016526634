import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import {
  Row,
  CardBody,
  Col,
  Input,
  Tooltip,
} from "reactstrap";

const MessageBoxHeader = ({ t, handlerSearchMessage, settings = {
  displayFilters : true,
  title : 'Contact Person'
} }) => {
  const [searchValue, setSearchValue] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior (e.g., form submission)
      handlerSearch();
    }
  };

  const handlerSearch = () => {
    handlerSearchMessage(searchValue);
    setSearchValue("");
  };

  return (
    <React.Fragment>
      <CardBody className="border-bottom">
        <div className="">
          <Row>
            <Col md={5} xs={9}>
              <h5 className="font-size-15 mt-2">
                {t(settings?.title)}
                {settings?.tooltipText &&  <span className="ms-2">
                    <i className="fa fa-info-circle"
                        aria-hidden="true"
                        id="tooltipId" />
                    <Tooltip
                        placement="top"
                        isOpen={tooltipOpen}
                        target="tooltipId"
                        toggle={toggle}>
                         {t(settings?.tooltipText)}
                    </Tooltip>
                </span>}
              </h5>
              
            </Col>
            {
              settings && settings?.displayFilters && (
                <Col md={7} xs={3}>
                  <div className="form-inline float-md-end">
                    <div className="search-box ms-2">
                      <div className="position-relative">
                        <Input
                          type="text"
                          className="form-control rounded message-search-input"
                          placeholder={`${t("Search")}...`}
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                            handlerSearchMessage(e.target.value);
                          }}
                          onKeyDown={handleKeyDown}
                        />
                        <i class="mdi mdi-magnify message-search-icon"></i>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            }
          </Row>
        </div>
      </CardBody>
    </React.Fragment>
  );
};
export default withNamespaces()(MessageBoxHeader);
