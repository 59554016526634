import { memo, useEffect, useState } from 'react';

import Select from "react-select";
import { Col, Row, Label, Tooltip} from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { AvField } from 'availity-reactstrap-validation';
import AiFlagWithTooltip from '../../../AiFlagWithTooltip';

const CustomSelect = (props) => {
    const {
        questionIndex,
        id, 
        title, 
        isMandatory, 
        key,
        elRefIdPrefix,
        options,
        defaultValue,
        helpText,
        onChange, 
        isAIFilled,
        isDisabled,
        isMulti,
        t
    } = props;
    
    const [selectedOption, setSelectedOption] = useState(
        defaultValue && Array.isArray(defaultValue) && defaultValue.length > 0 
          ? defaultValue.map(dv => ({
              label: props.t(dv.title),
              baseLabel: dv.title,
              value: dv.value
            }))
          : null
      );
  
    const [helpTooltipTextStatus, setHelpTooltipTextStatus] = useState(false);

    useEffect(() => {
        selectedOption && setSelectedOption((currentState) => {
                return [...currentState].map(option => {
                    return {
                        ...option,
                        label: t(option?.baseLabel)
                    }
                })
        })
    }, [t])

    return (
        <Row key={key}>
            <Col sm="12">
                <div className="mb-3 position-relative">
                    <Label
                        className="form-label"
                        htmlFor={`${elRefIdPrefix}-question-${id}`}>
                        {
                            parseInt(questionIndex) >= 0 ? (questionIndex + 1 + '. ') : ''
                        } {props.t(title)}

                        {
                            helpText && (
                                <span className="ms-2 tooltip-container help-text-icon">
                                    <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        id={`${elRefIdPrefix}-question-${id}-help-text`}
                                    />
                                    <Tooltip
                                        style={{
                                            backgroundColor: '#899ba1',
                                            fontWeight: 'bold'
                                        }}
                                        placement="top"
                                        isOpen={ helpTooltipTextStatus }
                                        target={`${elRefIdPrefix}-question-${id}-help-text`}
                                        toggle={() =>
                                            setHelpTooltipTextStatus(!helpTooltipTextStatus)
                                        }>
                                            {
                                                <p>
                                                    {props.t(helpText)}
                                                </p>
                                            }
                                    </Tooltip>
                                </span>
                            )
                        }
                    </Label>
                    <div className="input-container">
                        <Select
                            className="mt-1 text-capitalize"
                            placeholder={props.t("Select") + "..."}
                            id={`${elRefIdPrefix}-question-${id}`}
                            value={selectedOption}
                            onChange={(newOption) => {
                                setSelectedOption(newOption);
                                onChange && onChange(newOption);
                            }}
                            options={(options || []).map((optionItem) => {
                                return {
                                    label : props.t(optionItem.title),
                                    value : optionItem.id,
                                    baseLabel: optionItem.title
                                }
                            })}
                            menuPortalTarget={document.body}
                            isMulti={isMulti || false}
                            // isLoading={true}
                            isDisabled={isDisabled}
                        />
                        <AvField
                            className="d-none"
                            name={"question[" + id + "]"}
                            placeholder=""
                            type="text"
                            errorMessage={props.t(
                                "This field cannot be blank"
                            )}
                            validate={{
                                required: { value: isMandatory }
                            }}
                            value={selectedOption} />
                        <AiFlagWithTooltip isAIFilled={isAIFilled} />
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default withNamespaces()(memo(CustomSelect));