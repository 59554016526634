import { memo, useEffect, useState } from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';

const Tabs = memo(({t, totalCount, assignedToMeCount, deletedCount, reportedByMeCount}) => {
    const [activeTab, setActiveTab] = useState("");
    const history = useHistory();
    const location = useLocation();

    const isActiveTab = (currentTab, tab) => currentTab === tab;

    useEffect(() => {
      setActiveTab((new URLSearchParams(location.search)).get('tab'));
    }, [ location.search ]);

    useEffect(() => {
      if(!(new URLSearchParams(location.search)).get('tab') && !(new URLSearchParams(location.search)).get('softwareId')){
        history.push({
          search : '?tab=all'
        });
      }
    }, [location.search]);

    let tabsLinks = [
      {
          key: "all",
          title: `${t("All Incidents")} ${totalCount ? "(" + totalCount + ")" : ""}`
      },
      // {
      //     key: "assigned-to-me",
      //     title: `${t("Assigned to Me")} ${assignedToMeCount ? "(" + assignedToMeCount + ")" : ""}`
      // },
      {
        key: "reported-by-me",
        title: `${t("Reported By Me")} ${reportedByMeCount ? "(" + reportedByMeCount + ")" : ""}`
    },
      {
        key: "deleted-reports",
        title: `${t("Deleted Cases")} ${deletedCount ? "(" + deletedCount + ")" : ""}`
    },

  ];

    return (
      <div style={{
        backgroundColor: '#D1D2D6',
      }}>
        <Nav tabs className="custom-style-1">
            {
              tabsLinks.map((link) => {
                return (
                  <NavItem className={isActiveTab(activeTab, link.key) ? 'active': ''} key={link.key}>
                      <NavLink
                        className={isActiveTab(activeTab, link.key) ? 'active': ''}
                        onClick={() => history.push({
                            search : '?tab=' + link.key
                        })}>
                          {t(link.title)}
                      </NavLink>
                  </NavItem>
                )
              })
            }
        </Nav>
      </div>
	  );
});

export default Tabs;
