import { withNamespaces } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { EditorState } from "draft-js";

import SuccessModal from "./components/SuccessModal";

import { convertToHTML, convertFromHTML } from "draft-convert";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';

import {
  confirmPromptMakeReadyToShow,
  confirmPromptHide,
  confirmPromptUpdateAlertProps
} from 'src/store/actions';

import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/ThirdpartyUsersService";
import IndustriesService from "src/modules/3rd-party-management/apis/IndustriesService";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";

import PageHeader from "src/modules/3rd-party-management/components/page-header";
import Tabs from './components/tabs';
import CompanyDetails from "./pages/company-details";
import FactorySitesAndProducts from './pages/factory';
import Contacts from './pages/contacts';
import ReviewReport from './pages/review';
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";

const NewThirdParty = ({ 
    t, 
    lng,
    user, 
    organization, 
    exitConfirmPromptOptions, 
    makeConfirmPromptReadyToShow, 
    hideConfirmPrompt, 
    updateConfirmPromptAlertProps,
    modules: {
        featuresLoadingStatus   :   currentModuleFeaturesLoadingStatus
    }
}) => {
    const location = useLocation();
    const tabsActionsRef = useRef();

    const [activeTab, setActiveTab] = useState({});

    const [ stepsData, setStepsData ] = useState({
        companyDetails: null,
        factory: null,
        contacts: null
    });

    const [ selectedLegalDataIsLoading, setSelectedLegalDataIsLoading ] = useState(false);  

    const searchParams = new URLSearchParams(location.search);
    const supplierType = searchParams.get('type') === 'own' ? 'own' : 'creditor'

    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [ dataShouldBeSaved, setDataShouldBeSaved ] = useState(false);

    const handleCreateThirdPartyMutation = useMutation({
        mutationFn: async (payload) => {
            const service = ThirdpartyUsersService.getInstance();

            return await service.create(payload);
        },
        onSuccess: () => {
            removeDataFromStorage();
            hideConfirmPrompt();
            setOpenSuccessModal(true);
        },
        onError: () => {
            toast(t("An error occurred while creating third party."), {
                type: "error",
            });
        },
    });

    const handleFetchIndustriesListQuery = useQuery({
        queryKey: [
            '3rd-party-management-fetch-industries-list-query',
            lng
        ],
        queryFn: async () => {
            const service = IndustriesService.getInstance();

            return await service.fetchList({
                language: lng !== 'en' ? lng : undefined
            });
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching industires list.'), {
                type: 'error',
            });
        }
    });

    const {
        data        :   legalEntitiesData,
        isFetching  :   legalEntitiesListIsFetching,
        refetch     :   fetchLegalsList
    } = useQuery({
        queryKey: [
            '3rd-party-management-fetch-legal-entities-list',
            supplierType
        ],
        queryFn: async () => {
        const service = CommonService.getInstance();
            return await service.fetchLegalEntities();
        },
        enabled: false,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching entities.'), {
                type: 'error',
            });
        }
    });

    const countriesQuery = useQuery({
        queryKey: ['3rd-party-management-fetch-countries-list'],
        queryFn: async () => {
            const service = ThirdpartyUsersService.getInstance();
            return await service.fetchCountries();
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching supplier products list.'), {
                type: 'error',
            });
        },
    });

    const {
        data        :   selectedLegalData,
        refetch     :   fetchLegalEntityDetailsQuery,
        isFetching  :   legalDetailsQueryIsFetching
    } = useQuery({
        queryKey: ['3rd-party-management-fetch-legal-entity-details', stepsData?.companyDetails?.entities?.value],
        queryFn: async ({
            queryKey
        }) => {
            setSelectedLegalDataIsLoading(true);
            const service = CommonService.getInstance();

            return await service.fetchLegalEntityDetails(queryKey[1]);
        },
        enabled: false,
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching the details of entity.'), {
                type: 'error',
            });
        }
    });

    const memoizedEntitiesList = useMemo(() => {
        return (legalEntitiesData?.legalEntities || []).map((legal) => {
            return {
                value   :   legal.id,
                label   :   legal.title
            }
        }) 
    }, [ legalEntitiesData ]);

    const memoizedIndustriesList = useMemo(() => {
        if(handleFetchIndustriesListQuery?.data){
            const data = Array.isArray(handleFetchIndustriesListQuery.data) ? handleFetchIndustriesListQuery.data : [];

            return data.map((industry) => {
                return {
                    value : industry.id,
                    label : `${industry.class} - ${industry.title}`,
                    keywords: industry?.keywords || []
                };
            });
        }
        return [];
    }, [ handleFetchIndustriesListQuery.data ]);

    const memoizedCountriesList = useMemo(() => {
        return (countriesQuery.data || []).map((country) => {
            return {
                value: country.id,
                baseLabel: country.name,
                label: t(country.name),
                code: country.code,
            };
        })
    }, [ countriesQuery.data, t ]);

    useEffect(() => {
        if(exitConfirmPromptOptions.show){
            const continueAction = () => {
                exitConfirmPromptOptions.callbackFunc && exitConfirmPromptOptions.callbackFunc(true);
                hideConfirmPrompt();
            }

            if(exitConfirmPromptOptions.confirmStatus){
                saveDataOnTheStorage()
                .then(() => continueAction())
                .catch(() => {
                    toast(t("An error occurred while saving data."), {
                        type: "error",
                    });

                    continueAction();
                });
            }
            else{
                removeDataFromStorage();

                setTimeout(() => {
                    continueAction();
                }, 250);
            }
        }
    }, [ exitConfirmPromptOptions.confirmStatus ]);

    useEffect(() => {
        if(dataShouldBeSaved && !exitConfirmPromptOptions.readyToShow){
            makeConfirmPromptReadyToShow({
                title       : t('As you are leaving the page'),
                message     : t('Do you want to save the current state to continue later?'),
                alertProps  : {
                ...exitConfirmPromptOptions.alertProps,
                    confirmBtn  : {
                        ...exitConfirmPromptOptions.alertProps.confirmBtn,
                        enabled : true,
                        title : t('Yes, save the current state')
                    },
                    cancelBtn  : {
                        ...exitConfirmPromptOptions.alertProps.cancelBtn,
                        enabled : true,
                        title : t('No')
                    }
                }
            });
        }
    }, [ dataShouldBeSaved ]);

    useEffect(() => {
        const prevData = localStorage.getItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA');

        if(prevData){
            try{
                const parsedData = JSON.parse(prevData);

                if(parsedData){
                    setStepsData({
                        companyDetails: parsedData.companyDetails ? {
                            ...parsedData.companyDetails,
                            description: EditorState.createWithContent(convertFromHTML(parsedData.companyDetails.description || ''))
                        } : {},
                        factory: parsedData.factory || {}
                    });
                }
            }
            catch(error){
                removeDataFromStorage();
            }
        }
    }, []);

    useEffect(() => {
        if(currentModuleFeaturesLoadingStatus === 'loaded' && isFeatureEnabledForActiveModule('entity_management')){
            fetchLegalsList();
        }
    }, [ currentModuleFeaturesLoadingStatus ]);

    useEffect(() => {
        const selectedLegalId = stepsData?.companyDetails?.entities?.value;

        if(supplierType === 'own' && selectedLegalId){
            fetchLegalEntityDetailsQuery();
        }
    }, [ stepsData.companyDetails?.entities?.value ]);

    useEffect(() => {
        const gotLocations = selectedLegalData?.legal_entity_locations || [];
        if(memoizedCountriesList.length > 0 && selectedLegalDataIsLoading){
            setStepsData((currenState) => {
                return {
                    ...currenState,
                    factory: {
                        ...currenState.factory,
                        office_addresses: gotLocations.map((address) => {
                            return {
                                country: memoizedCountriesList.find((country) => country.value === address.country.id),
                                city: address.city,
                                postalCode: address.postal_code,
                                extra: address.address
                            }
                        })
                    }
                }
            });

            if(!gotLocations.length){
                setSelectedLegalDataIsLoading(false);
            }
        }
    }, [ selectedLegalData, memoizedCountriesList ]);

    useEffect(() => {
        if(supplierType === 'own' && selectedLegalDataIsLoading){
            setSelectedLegalDataIsLoading(false);
        }
    }, [ stepsData.factory?.office_addresses ]);
    
    const handleCreateThirdparty = () => {

        const {
            companyDetails,
            factory,
            contacts
        } = stepsData;

        const languages = organization.languages.map(l => {
            return {
                name: l.name,
                direction: l.direction,
                language: l.language?.id,
                isDefault: l.is_default,
                module: l.module?.id
            }
        })

        const products = (factory.products || []).map((product) => {
            return {
                name: product.product.value,
                description: product.description,
            };
        });

        const supplierLocations = [];

        (factory.office_addresses || []).forEach((address) => {
            supplierLocations.push({
                type: "office",
                country: address.country.value,
                city: address.city,
                postalCode: address.postalCode, 
                address: address.extra,
            });
        });

        (factory.factory_addresses || []).forEach((address) => {
            supplierLocations.push({
                type: "manufacture",
                country: address.country.value,
                city: address.city,
                postalCode: address.postalCode, 
                address: address.extra,
            });
        });

        const data = {
            isOwnCompany: supplierType === 'own' ? true : undefined,
            token: localStorage.getItem("LOCAL_STORAGE_KEY_ACCESS_TOKEN"),
            name: companyDetails.company_name,
            languages: languages,
            products: products,
            supplierLocations: supplierLocations,
            industries: companyDetails.industries.map(industry => String(industry?.value)),
            companySize: companyDetails.company_size,
            vatNumber: companyDetails.vat_number,
            websiteUrl: companyDetails.website,
            description: companyDetails.description.getCurrentContent().hasText()
                ? convertToHTML(companyDetails.description.getCurrentContent())
                : "",
            isPartner: 0,
            partnerAnalysts: [String(user?.id)],
            partner: Number(organization?.customerId),
            hasDashboard: 0,
            subscriptionType: ""
        };

        if(supplierType === 'own'){
            data.responsiblePersons = contacts.map((p) => {
                return p.id.toString()
            })

            data.legalEntities = [companyDetails.entities.value.toString()]

            data.countryCode = factory.office_addresses[0]?.country?.code
        }
        else{
            data.contactPersons = contacts.map((c) => {
                return {
                    title       : c.title,
                    name        : c.firstName,
                    lastName    : c.lastName,
                    email       : c.email,
                    phoneNumber : c.phoneNumber,
                    position    : c.position,
                    language    : c.language?.value,
                };
            });

            data.legalEntities = (companyDetails.entities || []).map((legal) => {
                return legal.value.toString()
            })

            
            data.countryCode = factory.office_addresses[0]?.country?.code
        }

        handleCreateThirdPartyMutation.mutate(data)
    }

    const saveDataOnTheStorage = () => {
        return new Promise((res, rej) => {
            updateConfirmPromptAlertProps({
                ...exitConfirmPromptOptions.alertProps,
                confirmBtn  : {
                    ...exitConfirmPromptOptions.alertProps.confirmBtn,
                    title   : (
                        <>
                            <Spinner className="me-2" animation="border" variant="white" size="sm"/>{t('Saving...')}
                        </>
                    ),
                    loading : true
                }
            });

            try{
                const description = stepsData.companyDetails.description
        
                localStorage.setItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA', JSON.stringify({
                    ...stepsData,
                    contacts: undefined,
                    companyDetails: {
                        ...stepsData.companyDetails,
                        description: description.getCurrentContent().hasText() ? 
                            convertToHTML(description.getCurrentContent()) : 
                            ""
                    }
                }));

                res();
            }
            catch(error){
                rej();
            }
        });
    }

    const removeDataFromStorage = () => {
        localStorage.removeItem('MODULE_TPDD_NEW_THIRDPARTY_SAVED_DATA');
    }
    
    return (
        <div className="page-content new-thirdparty-page">
            {openSuccessModal && <SuccessModal supplierType={supplierType} setModal={setOpenSuccessModal} modal={openSuccessModal} />}

            <Container fluid>
                <PageHeader title={t(supplierType === 'own' ? 'New Own Company' : 'New Third Party')} />

                <Row>
                    <Col sm='12'>
                        <Tabs 
                            supplierType={supplierType}
                            actionsRef={tabsActionsRef}
                            onActiveTabChanged={(tab) => setActiveTab(tab)}
                        />
                    </Col>
                </Row>

                {activeTab.name === 'company-details' && (
                    <CompanyDetails
                        setCompanyDetailsTabCompleted={(companyDetailsData) => {
                            setStepsData({
                                ...stepsData,
                                companyDetails: companyDetailsData
                            });

                            if(tabsActionsRef.current){
                                tabsActionsRef.current.goToNext()
                            }
                            
                            setDataShouldBeSaved(true);
                        }}
                        supplierType={supplierType}
                        memoizedIndustriesList={memoizedIndustriesList}
                        memoizedEntitiesList={memoizedEntitiesList}
                        defaultValues={stepsData?.companyDetails}
                        isLoading={handleFetchIndustriesListQuery.isFetching || legalEntitiesListIsFetching}
                    />
                )}

                {activeTab.name === 'factory-sites-and-products' && (
                    <FactorySitesAndProducts
                        selectedIndustryIds={ 
                            (stepsData.companyDetails?.industries || []).map((industry) => industry.value) 
                        }
                        setCompanyDetailsTabCompleted={(factoryData) => {
                            setStepsData({
                                ...stepsData,
                                factory: factoryData
                            });

                            if(tabsActionsRef.current){
                                tabsActionsRef.current.goToNext()
                            }
                        }}
                        goToPrevTab={(factoryCurrentState) => {
                            setStepsData({
                                ...stepsData,
                                factory: factoryCurrentState
                            });

                            if(tabsActionsRef.current){
                                tabsActionsRef.current.goToPrev()
                            }
                        }}
                        supplierType={supplierType}
                        defaultValues={stepsData?.factory}
                        memoizedCountriesList={memoizedCountriesList}
                        isLoading={countriesQuery.isFetching || legalDetailsQueryIsFetching || selectedLegalDataIsLoading}
                    />
                )}

                {activeTab.name === "contact-person" && (
                    <Contacts
                        setCompanyDetailsTabCompleted={(contacts) => {
                            setStepsData({
                                ...stepsData,
                                contacts: contacts
                            });

                            if(tabsActionsRef.current){
                                tabsActionsRef.current.goToNext()
                            }
                        }}
                        goToPrevTab={(contactsCurrentState) => {
                            setStepsData({
                                ...stepsData,
                                contacts: contactsCurrentState
                            });

                            if(tabsActionsRef.current){
                                tabsActionsRef.current.goToPrev()
                            }
                        }}
                        supplierType={supplierType}
                        defaultValues={stepsData?.contacts}
                    />
                )}

                {activeTab.name === "finalize" && (
                    <ReviewReport
                        goToSpecificTab={(payload) => {
                            if(tabsActionsRef.current){
                                tabsActionsRef.current.goToSpecificTab(payload)
                            }
                        }}
                        goToPrevTab={() => {
                            if(tabsActionsRef.current){
                                tabsActionsRef.current.goToPrev()
                            }
                        }}
                        supplierType={supplierType}
                        values={stepsData}
                        onSubmit={handleCreateThirdparty}
                        isLoading={handleCreateThirdPartyMutation.isLoading}
                    />
                )}
            </Container>
        </div>
    );
};

const mapStatetoProps = (state) => {
    const { token } = state.Login;
    const { Organization } = state;
    return {
        user: state.Login.user,
        token,
        organization: Organization,
        exitConfirmPromptOptions : state.ExitConfirmPrompt,
        modules: state.Modules
    };
};

export default withNamespaces()(
    withRouter(connect(mapStatetoProps, {
        makeConfirmPromptReadyToShow  : (payload)  =>  confirmPromptMakeReadyToShow(payload),
        hideConfirmPrompt             : ()  =>  confirmPromptHide(),
        updateConfirmPromptAlertProps : (payload)  =>  confirmPromptUpdateAlertProps(payload)
    })(NewThirdParty))
);
