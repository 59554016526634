import {
    ROLE_ADMIN,
    ROLE_ANALYST,
    ROLE_ANALYST_ADMIN
} from "src/components/constants";

import {
    ROUTE_PATH_ADMIN_DASHBOARD,
    ROUTE_PATH_ADMIN_KANBAN_BOARD,
    ROUTE_PATH_ADMIN_BREACH_INCIDENT_REQUEST,
    ROUTE_PATH_ADMIN_DATA_SUBJECT_REQUEST,
    ROUTE_PATH_ADMIN_HARDWARES_LIST,
    ROUTE_PATH_ADMIN_SOFTWARES_LIST,
    ROUTE_PATH_ADMIN_PROCESSING_DIRECTORIES_LIST,
    ROUTE_PATH_ADMIN_LATEST_NEWS_LIST,
    ROUTE_PATH_ADMIN_ADMINISTRATION_SETTINGS,
    ROUTE_PATH_ADMIN_ADMINISTRATION_QUESTIONAIRE,
    ROUTE_PATH_ADMIN_ADMINISTRATION_CUSTOMIZATION
} from "src/modules/data-protection/constants"

import {
    ROUTE_PATH_ADMIN_DOCUMENTS,
    ROUTE_PATH_ADMIN_E_LEARNING,
    ROUTE_PATH_ADMIN_TUTORIAL,
    ROUTE_PATH_ADMIN_HISTORY_LOG,
    ROUTE_PATH_ADMIN_RISK_MANAGMENT
} from "src/common/constants";

const AnalystMenuItems = [
    {
        titleKey    :   "Dashboard",
        path        :   ROUTE_PATH_ADMIN_DASHBOARD,
        icon        :   "ri-home-4-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Kanban Board",
        path        :   ROUTE_PATH_ADMIN_KANBAN_BOARD,
        icon        :   "ri-bar-chart-2-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Incident Register",
        path        :   ROUTE_PATH_ADMIN_BREACH_INCIDENT_REQUEST,
        icon        :   "ri-file-list-3-fill",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Data Subject Request",
        path        :   ROUTE_PATH_ADMIN_DATA_SUBJECT_REQUEST,
        icon        :   "ri-file-list-3-fill",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Assets Register",
        path        :   null,
        icon        :   "ri-computer-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null,
        items: [
            {
                titleKey    :   "Hardware",
                path        :   ROUTE_PATH_ADMIN_HARDWARES_LIST,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            },
            {
                titleKey    :   "Software",
                path        :   ROUTE_PATH_ADMIN_SOFTWARES_LIST,
                linkType    :   "react-router",
                authProtected   :   true,
                settingKey      :   null,
                moduleFeatures  :   [],
                roles           :   [],
                customValidator :   null
            }
        ]
    },
    {
        titleKey    :   "Processing Directories",
        path        :   ROUTE_PATH_ADMIN_PROCESSING_DIRECTORIES_LIST,
        icon        :   "ri-bar-chart-2-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [],
        customValidator :   null
    },
    {
        titleKey    :   "Risk Management",
        path        :   ROUTE_PATH_ADMIN_RISK_MANAGMENT,
        icon        :   "ri-error-warning-line",
        linkType    :   "react-router",
        authProtected   :   true,
        settingKey      :   null,
        moduleFeatures  :   [],
        roles           :   [
            ROLE_ADMIN,
            ROLE_ANALYST,
            ROLE_ANALYST_ADMIN
        ],
        customValidator :   null
    },
];

export default AnalystMenuItems;