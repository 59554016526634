import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { withNamespaces } from "react-i18next";
import { toast } from "react-toastify";
import TableFilter from "src/modules/3rd-party-management/components/table-filter";
import SuppliersFilterService from "src/modules/3rd-party-management/apis/SuppliersFilterService";
import CommonService from "src/modules/3rd-party-management/apis/CommonService";
import DateUtils from "src/services/utils/DateUtils";
import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";

const FilteringComponent = ({
    t,
    lng,
    onChange,
    modules: {
        featuresLoadingStatus   :   currentModuleFeaturesLoadingStatus
    },
    usedIn
}) => {
    const [ tableFilters, setTableFilters ] = useState({
        'createdAt[from]'   :   null,
        'createdAt[to]'     :   null,
        legal               :   null,
        flag                :   null,
        supplierStatus      :   null,
        country             :   null,
        highestRisk         :   null,
        industries          :   [],
        products            :   []
    });

    const [ industriesFilter, setIndustriesFilter ] = useState({
        pageSize: 50,
        pageIndex: 1,
        name: '',
        language: lng
    });

    const [ productsFilter, setProductsFilter ] = useState({
        pageSize: 100,
        pageIndex: 1,
        industries: [],
        productName: '',
        language: lng
    });

    const dateUtils = new DateUtils();

    const {
        isFetching: countriesFetching,
        isLoading: countriesLoading,
        data: countriesData
    } = useQuery({
		queryKey: [
            '3rd-party-management-fetch-company-countries-list-query',
            usedIn
        ],
		queryFn: async () => {
			return await (SuppliersFilterService.getInstance()).fetchCountries();
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching countries list.'), {
				type: 'error',
			});
		}
	});

    const {
        isFetching: productsFetching,
        isLoading: productsLoading,
        data: productsData,
        refetch: refetchIndustriesProducts
    } = useQuery({
        queryKey: [
            '3rd-party-management-fetch-products-list-query',
            usedIn,
            (new URLSearchParams(
                Object.fromEntries(Object.entries(productsFilter).filter(([_key, value]) => {
                    return !value ? false : (Array.isArray(value) ? (value.length > 0) : true)
                }))
            )).toString()
        ],
        queryFn: async () => {
            return await (SuppliersFilterService.getInstance()).fetchProducts({
                ...productsFilter,
                ownCompany: usedIn === 'own' ? true : undefined,
                industries: (productsFilter.industries || []).join(',') || undefined
            });
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onError: () => {
            toast(t('An error occurred while fetching products list.'), {
                type: 'error'
            });
        }
    });

    const {
        isFetching: industriesFetching,
        isLoading: industriesLoading,
        data: industriesData,
        refetch: refetchIndustriesList
    } = useQuery({
		queryKey: [
            '3rd-party-management-fetch-supplier-industries-list-query',
            usedIn,
            (new URLSearchParams(
                Object.fromEntries(Object.entries(industriesFilter).filter(([_key, value]) => {
                    return !value ? false : (Array.isArray(value) ? (value.length > 0) : true)
                }))
            )).toString()
        ],
		queryFn: async () => {
            return await (SuppliersFilterService.getInstance()).fetchIndustries({
                ...industriesFilter,
                ownCompany: usedIn === 'own' ? true : undefined,
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching industries list.'), {
				type: 'error',
			});
		}
	});

    const {
        data        :   legalEntitiesData,
        isFetching  :   legalEntitiesFetching,
        isLoading   :   legalEntitiesLoading,
        refetch     :   fetchLegalsList
    } = useQuery({
        queryKey: [
            '3rd-party-management-fetch-legal-entities-list',
            usedIn
        ],
        queryFn: async () => {
            return await (CommonService.getInstance()).fetchLegalEntities({
                ownCompanies: usedIn === 'own' ? true : undefined
            });
        },
        cacheTime: 0,
        refetchOnWindowFocus: false,
        enabled: false,
        onError: () => {
            toast(t('An error occurred while fetching entities.'), {
                type: 'error',
            });
        }
    });

    const tableFilterTabs = useMemo(() => {
        const filtersList = [
            {
                type: 'date',
                name: 'filter-date',
                title: 'Creation Date',
                icon: <i className="ri-calendar-event-line" />,
                defaultValue: {
                    from: tableFilters['createdAt[from]'],
                    to: tableFilters['createdAt[to]']
                }
            }
        ];

        if(currentModuleFeaturesLoadingStatus === 'loaded' && isFeatureEnabledForActiveModule('entity_management')){
            filtersList.push({
                type: 'radio_list',
                name: 'legal',
                title: 'Connected Legal Entities',
                icon: <i className="ri-git-branch-line" />,
                isLoading: legalEntitiesFetching || legalEntitiesLoading,
                defaultValue: tableFilters?.legal,
                data: legalEntitiesData?.legalEntities || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.title
                }
            });
        }


        return [
            ...filtersList,
            {
                type: 'radio_list',
                name: 'country',
                title: 'Country',
                icon: <i className="ri-global-line" />,
                isLoading: countriesFetching || countriesLoading,
                defaultValue: tableFilters?.country,
                data: countriesData || [],
                searchInListOption: {
                    enabled: true,
                    remote: false
                },
                formatter: (item) => {
                    return item.name
                }
            },
            {
                type: 'radio_list',
                name: 'supplierStatus',
                title: 'Status',
                icon: <i className="ri-toggle-line" />,
                isLoading: false,
                defaultValue: tableFilters?.supplierStatus,
                data: [
                    {
                        id      :   0,
                        title   :   'New'
                    },
                    {
                        id      :   1,
                        title   :   'Under Review'
                    },
                    {
                        id      :   2,
                        title   :   'Approved'
                    },
                    {
                        id      :   3,
                        title   :   'Rejected'
                    }
                ],
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'radio_list',
                name: 'flag',
                title: 'Flag Status',
                icon: <i className="ri-flag-line" />,
                isLoading: false,
                defaultValue: tableFilters?.flag,
                data: [
                    {
                        id      :   1,
                        title   :   'Flagged'
                    },
                    {
                        id      :   0,
                        title   :   'Unflagged'
                    }
                ],
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'radio_list',
                name: 'highestRisk',
                title: 'Risk Rate',
                icon: <i className="ri-alert-line" />,
                isLoading: false,
                defaultValue: tableFilters?.highestRisk,
                data: [
                    {
                        id      :   'insignificant',
                        title   :   'Insignificant'
                    },
                    {
                        id      :   'minor',
                        title   :   'Minor'
                    },
                    {
                        id      :   'noticeable',
                        title   :   'Noticeable'
                    },
                    {
                        id      :   'critical',
                        title   :   'Critical'
                    },
                    {
                        id      :   'existence - threatening',
                        title   :   'Existence - threatening'
                    },
                ],
                formatter: (item) => {
                    return item.title
                }
            },
            {
                type: 'check_list',
                name: 'industries',
                title: 'Industry',
                icon: <i className="ri-building-line" />,
                isLoading: industriesFetching || industriesLoading,
                defaultValue: tableFilters?.industries,
                data: industriesData || {},
                searchInListOption: {
                    enabled: true,
                    remote: true,
                    defaultValue: industriesFilter?.name,
                    handler: (value) => {
                        setIndustriesFilter((currentState) => {
                            return {...currentState, pageIndex: 1, name: value}
                        })
                    }
                },
                pagination: {
                    enabled: true,
                    totalCountKey: 'itemsCount',
                    pageIndexKey: 'pageIndex',
                    pageSizeKey: 'pageSize',
                    dataKey: 'industries',
                    handler: ({ pageIndex, pageSize }) => {
                        setIndustriesFilter((currentState) => {
                            return {...currentState, pageIndex: pageIndex, pageSize: pageSize}
                        })
                    }
                },
                onFilterChange: (industriesList) => {
                    setProductsFilter((currentState) => {
                        return {...currentState,pageIndex: 1, industries: industriesList}
                    })
                },
                formatter: (item) => {
                    return `${item.class} - ${t(item.title)}`
                }
            },
            {
                type: 'check_list',
                name: 'products',
                title: 'Products',
                icon: <i className="ri-product-hunt-line" />,
                isLoading: productsFetching || productsLoading,
                defaultValue: tableFilters?.products,
                data: productsData || {},
                pagination: {
                    enabled: true,
                    totalCountKey: 'itemsCount',
                    pageIndexKey: 'pageIndex',
                    pageSizeKey: 'pageSize',
                    dataKey: 'products',
                    handler: ({ pageIndex, pageSize }) => {
                        setProductsFilter((currentState) => {
                            return {...currentState, pageIndex: pageIndex, pageSize: pageSize}
                        })
                    }
                },
                searchInListOption: {
                    enabled: true,
                    remote: true,
                    defaultValue: productsFilter?.productName,
                    handler: (value) => {
                        setProductsFilter((currentState) => {
                            return {...currentState, pageIndex: 1, productName: value}
                        })
                    }
                },
                formatter: (item) => {
                    return `${item.class} - ${t(item.name)}`
                }
            }
        ]
    }, [
        tableFilters,
        countriesFetching,
        countriesLoading,
        legalEntitiesFetching,
        legalEntitiesLoading,
        industriesFetching,
        industriesLoading,
        productsFilter,
        industriesFilter,
        productsFetching,
        productsLoading,
        productsData,
        currentModuleFeaturesLoadingStatus,
        t
    ]);

    const onTableFilterOkButtonClicked = (filters) => {
        if (filters) {
            const result = {}

            if ('filter-date' in filters) {
                result['createdAt[from]'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.from),
                    'YYYY-MM-DD'
                )

                result['createdAt[to]'] = dateUtils.convertDateToDate(
                    new Date(filters['filter-date']?.to),
                    'YYYY-MM-DD'
                )
            }

            if ('country' in filters) {
                result.country = filters.country !== 'all' ? filters.country : undefined
            }

            if ('legal' in filters) {
                result.legal = filters.legal !== 'all' ? filters.legal : undefined
            }

            if ('supplierStatus' in filters) {
                result.supplierStatus = filters.supplierStatus !== 'all' ? filters.supplierStatus : undefined
            }

            if ('flag' in filters) {
                result.flag = filters.flag !== 'all' ? filters.flag : undefined
            }

            if ('highestRisk' in filters) {
                result.highestRisk = filters.highestRisk !== 'all' ? filters.highestRisk : undefined
            }

            result.industries = filters.industries?.length > 0 ? filters.industries : undefined

            result.products = filters.products?.length > 0 ? filters.products : undefined
            
            setTableFilters({
                ...tableFilters,
                ...result
            })
        } else {
            setTableFilters({
                'createdAt[from]': null,
                'createdAt[to]': null,
                flag: null,
                supplierStatus: null,
                country: null,
                legal: null,
                highestRisk: null,
                industries: [],
                products:[]
            })
        }
    }

    useEffect(() => {
        refetchIndustriesProducts();
    }, [productsFilter]);

    useEffect(() => {
        refetchIndustriesList();
    }, [industriesFilter]);

    useEffect(() => {
        onChange && onChange(tableFilters)
    }, [ tableFilters ]);

    useEffect(() => {
        setIndustriesFilter((currentState) => {
            return {
                ...currentState,
                language: lng
            }
        });

        setProductsFilter((currentState) => {
            return {
                ...currentState,
                language: lng
            }
        });
    }, [ lng ]);

    useEffect(() => {
        if(currentModuleFeaturesLoadingStatus === 'loaded' && isFeatureEnabledForActiveModule('entity_management')){
            fetchLegalsList();
        }
    }, [ currentModuleFeaturesLoadingStatus ])

    return (
        <TableFilter
            className="me-2"
            defaultActiveTab='filter-date'
            tabs={tableFilterTabs}
            onOkButtonClicked={
                onTableFilterOkButtonClicked
            }
        />
    )
}

const mapStateToProps = (state) => {
	return {
		modules				:	state.Modules
	};
};

export default withNamespaces()(connect(mapStateToProps, null)(
    FilteringComponent
));
