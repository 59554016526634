import axios from 'axios';

import {
    API_BASE_URL
} from '../constants/index';

export default class SupplierLegalEntityService {
	static instance;

	static getInstance() {
		if (!SupplierLegalEntityService.instance) {
			SupplierLegalEntityService.instance = new SupplierLegalEntityService();
		}

		return SupplierLegalEntityService.instance;
	}

    async fetchSupplierEntities(supplierId, params = {}) {
        const response = await axios.get(`${API_BASE_URL}/supplier/connected_legal/${supplierId}/list`, {
            params  :   params,
            headers :   {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            },
        });

        return response.data.data || []
    } 

    async addConnectedLegal(payload){
        const response = await axios.post(`${API_BASE_URL}/supplier/add_connected_legal`, payload,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                    )}`
                }
            }
        );
  
        return response.data?.data || null;
    }

    async removeConnectedLegal(legalId){
        return await axios.delete(`${API_BASE_URL}/supplier/connected_legal/${legalId}/delete`, {
            params: {
                legalId: legalId
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem(
                    "LOCAL_STORAGE_KEY_ACCESS_TOKEN"
                )}`
            }
        });
    }
}
