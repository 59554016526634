// import { withRouter } from "react-router-dom";
import OverviewHeader from "src/modules/data-protection/pages/software/overview-header";
import MessageBox from "src/modules/data-protection/components/MessageBox";
import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
import DataProtectionSoftwareService from "src/modules/data-protection/apis/DataProtectionSoftwareService";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

const InternalCommunication = ({ t, assetId }) => {
  const [mentionSuggestionList, setMentionSuggestionList] = useState([]);

  const handleFetchCommunicationMentionSuggestionListQuery = useQuery({
    queryKey: ["fetch-communication-mention-suggestion-list-query"],
    queryFn: async () => {
      const service = new DataProtectionSoftwareService();
      return await service.mentionCommentSearch(assetId, {
        query: "",
      });
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (process.env.NODE_ENV === "development") console.error(error);
      toast(t("An error occurred while fetching analyst admins list."), {
        type: "error",
      });
    },
  });

  useEffect(() => {
    const { data } = handleFetchCommunicationMentionSuggestionListQuery ?? {};
    if (data && Array.isArray(data)) {
      const list = data.map((item) => ({
        text: item.full_name,
        url: item.id,
        value: item.full_name,
      }));
      setMentionSuggestionList(list);
    }
  }, [handleFetchCommunicationMentionSuggestionListQuery.data]);

  return (
    <div className="p-4 d-flex flex-column gap-4">
      <OverviewHeader assetId={assetId} />
      {handleFetchCommunicationMentionSuggestionListQuery.isLoading ||
      handleFetchCommunicationMentionSuggestionListQuery.isFetching ||
      !assetId ? (
        <div
          className="dt-field dt-skeleton"
          style={{ width: "100%", height: "320px" }}
        ></div>
      ) : (
        <MessageBox
          settings={{
            messageListUrl: "report.listCommentInternal",
            sendMessageUrl: "report.addCommentInternal",
            attachmentDownloadUrl: "report/attachment/",
            attachmentUploadUrl: "report.uploadAttachment",
            attachmentDeleteUrl: "attachment/",
            assetId: String(assetId),
            mention: {
              enable: true,
              suggestionList: mentionSuggestionList,
            },
            headerOptions: {
              displayFilters: true,
              title:  "Internal Communication" ,
              tooltipText: "Visible only to internal users. You can mention someone using '@'.",
            },
          }}
        />
      )}
    </div>
  );
};

export default (withRouter(InternalCommunication));