import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Label } from "reactstrap";
import MultiSelect from "../../../../../../../components/MultiSelect";
import UserUtils from "src/services/utils/UserUtils";
import ReportIncidenService from "../../../../components/api/ReportIncidenService";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

const Index = (props) => {
  const { t, report, caseId, currentUser, reportLoading } = props;

  const [relatedAssets, setRelatedAssets] = useState([]);
  const [relatedAssetOptions, setRelatedAssetOptions] = useState([]);

  const userUtils = new UserUtils();
  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(currentUser);
  };

  const handleRemoveAssetsMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.RemoveAssets(payload);
    },
  });

  const handleAddAssetsMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.AddAssets(payload);
    },
  });

  const handleFetchRelatedAssets = useQuery({
    queryKey: ["data-protection-assetes-contnet"],
    queryFn: async () => {
      const service = ReportIncidenService.getInstance();
      return await service.FetchRelatedAssets();
    },
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast(t("An error occurred while related assets."), {
        type: "error",
      });
    },
  });

  const handleRemoveAssetsChange = (item) => {
    handleRemoveAssetsMutation.mutate(
      {
        caseId: caseId,
        assetId: item[0].id,
      },
      {
        onSuccess: () => {
          toast(t("Related assets updated successfully."), {
            type: "success",
          });
        },
        onError: (error) => {
          toast(t("Failed to update related assets."), {
            type: "error",
          });
          // Refetch Assets if mutation fails
          setRelatedAssets([...relatedAssets]);
        },
      }
    );
  };

  const handleAddAssetsChange = (item) => {
    handleAddAssetsMutation.mutate(
      {
        caseId: caseId,
        assetId: item[0].id,
      },
      {
        onSuccess: () => {
          toast(t("Related assets updated successfully."), {
            type: "success",
          });
        },
        onError: (error) => {
          toast(t("Failed to update related assets."), {
            type: "error",
          });
          // Refetch Assets if mutation fails
          setRelatedAssets([...relatedAssets]);
        },
      }
    );
  };

  const relatedAssetLoading =
    handleFetchRelatedAssets.isFetching || handleFetchRelatedAssets.isLoading;

  //its just check the array lenght for determine the item add or removed
  const determineChangesAndSendToBackend = (
    currentValues,
    previousValues,
    removeCallback,
    addCallback
  ) => {
    const removedItems = previousValues.filter(
      (item) => !currentValues.find((val) => val.id === item.id)
    );
    const addedItems = currentValues.filter(
      (val) => !previousValues.find((item) => item.id === val.id)
    );

    if (removedItems.length > 0 && addedItems.length === 0) {
      removeCallback(removedItems);
    } else if (addedItems.length > 0 && removedItems.length === 0) {
      addCallback(addedItems);
    } else {
      return;
    }
  };
  //checking for the add item or remove item then call the query based on them
  const handleMultiSelectChangeAssets = (selectedValues) => {
    determineChangesAndSendToBackend(
      selectedValues,
      relatedAssets,
      (removedItems) => {
        handleRemoveAssetsChange(removedItems);
      },
      (addedItems) => {
        handleAddAssetsChange(addedItems);
      }
    );
    setRelatedAssets(selectedValues);
  };

  useEffect(() => {
    if (
      !reportLoading &&
      report &&
      handleFetchRelatedAssets.data?.assets?.length > 0
    ) {
      setRelatedAssetOptions(handleFetchRelatedAssets.data?.assets);
      const assetsObjects = handleFetchRelatedAssets.data?.assets
        .filter((asset) =>
          report?.related_assets.some(
            (relatedAsset) => relatedAsset.id === asset.id
          )
        )
        .map((asset) => ({ ...asset, title: asset.name }));
      setRelatedAssets(assetsObjects);
    }
  }, [handleFetchRelatedAssets.data, report, reportLoading]);

  return (
    <div style={{ maxWidth: "396px" }}>
      {/* Add New Related Asset */}
      <Label>{t("Related Assets")}</Label>
      {!relatedAssetLoading && !reportLoading ? (
        <MultiSelect
          options={
            relatedAssetOptions && relatedAssetOptions?.length > 0
              ? relatedAssetOptions?.map((asset) => ({
                  ...asset,
                  title: asset.name, // Change 'name' property to 'title'
                }))
              : []
          }
          isDisabled={report?.is_read_only_analyst}
          selectedValues={relatedAssets}
          onChange={handleMultiSelectChangeAssets}
          placeholder={t("Select...")}
        />
      ) : (
        // skeleton
        <>
          <p
            className={`dt-field dt-skeleton ${
              isAnalystOrAnalystAdmin ? "dt-select-list" : ""
            }`}
          ></p>
          <p className="dt-field dt-skeleton dt-update-date"></p>
        </>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { token, currentUser } = state.Login;
  return {
    currentUser,
    token,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(Index));
