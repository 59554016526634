import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { useEffect, useState, useImperativeHandle } from 'react';
import { Row, Col, Label, Button, FormFeedback, Alert } from "reactstrap";
import MaterialInput from "@material-ui/core/Input";
import InputMask from "react-input-mask";
import { useMutation } from '@tanstack/react-query';
import FollowUpForm from 'src/modules/data-protection/components/FollowUpForm';
import axios from 'axios';
import { API_BASE_URL } from 'src/modules/data-protection/constants';
import { toast } from 'react-toastify';

const FollowUp = ({
    t,
    isActiveTab,

    otpError,
    
    actionsRef,
    user,
    token,
    onUserFilledOtpInput,
    onBackButtonClicked
}) => {
    const enableOtpResendIn = 60;

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [otp, setOtp] = useState();
    const [otpSent, setOtpSent] = useState(false);
    const [otpCountdown, setOtpCountdown] = useState(enableOtpResendIn);
    const [resendInterval, setResendInterval] = useState();

    const {
        mutate: sendOptMutatation,
        isLoading: otpIsSending,
        status: sendOtpMutationCurrentStatus
    } = useMutation({
        mutationKey: ["data-protection-send-otp"],
        mutationFn: async () => {
            return await axios.post(`${API_BASE_URL}/auth/user/otp-request`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        },
        onSuccess: () => {
            setOtpSent(true);
        },
        onError: (error) => {
            toast(t("An error occured while sending otp code."), {
                type: "error",
            });
        }
    });

    useEffect(() => {
        if(token && !otpSent && isActiveTab){
            sendOptMutatation();
        }
    }, [ token, isActiveTab]);

    useImperativeHandle(actionsRef, () => {
        return {
            resetOtpForm  :   () => {
                setOtp('');
                setOtpSent(false);
            }
        }
    });

    useEffect(() => {
        if(sendOtpMutationCurrentStatus === 'success'){
            setOtpCountdown(enableOtpResendIn);

            setResendInterval(setInterval(() => {
                setOtpCountdown((currentState) => {
                    return currentState - 1;
                })
            }, 1000));
        }
    }, [sendOtpMutationCurrentStatus])

    useEffect(() => {
        if(otpCountdown <= 0){
            clearInterval(resendInterval);
        }
    }, [ otpCountdown ])

    return (
        isActiveTab ? (
            <>
                {!token && !user ? (
                    <FollowUpForm 
                        username={username}
                        password={password}
                        setUsername={setUsername}
                        setPassword={setPassword}
                        onBackButtonClicked={() => onBackButtonClicked && onBackButtonClicked() }
                        settings={{
                            buttons: {
                                back : {
                                    title : "Back"
                                },
                                confirm: {
                                    title : "Submit"
                                }
                            },
                            inputs : {
                                email	:	{
                                    required	:	true
                                }
                            }
                        }}
                    /> 
                ) : (
                    <>
                        {otpIsSending ? (
                            <Alert color="info" role="alert">
                                <i className="mdi mdi-check-all me-2"></i>
                                    <b>
                                        {t("Sending OTP...")}
                                    </b>
                                <p>
                                    {t(
                                        "We are sending an otp code to your email."
                                    )}
                                </p>
                            </Alert>
                        ) : (
                            <div>
                                <Row className='mb-3'>
                                    <Col sm="6">
                                        <Label className="form-label" htmlFor="otp">
                                            {t("OTP Code")}
                                        </Label>
                                        <div className='d-flex align-items-end'>
                                            <InputMask
                                                autoFocus
                                                mask="9-9-9-9-9-9"
                                                className="form-control input-color me-2"
                                                value={otp}
                                                onChange={(e) => {
                                                    setOtp(e.target.value)
                                                }}
                                            >
                                                {(inputProps) => (
                                                    <MaterialInput {...inputProps} disableUnderline />
                                                )}
                                            </InputMask>
                                            <Button
                                                disabled={otpCountdown > 0}
                                                style={{
                                                    width: '170px'
                                                }}
                                                type="button"
                                                onClick={() => {
                                                    sendOptMutatation()
                                                }}
                                                color="primary"
                                            >
                                                {otpCountdown > 0 ? (
                                                    <>{t("Resend OTP in")} {otpCountdown} </>
                                                ) : (
                                                    <>{t("Resend")}</>
                                                )}
                                            
                                            </Button>
                                            
                                        </div>
                                        {otpError && (
                                            <FormFeedback invalid className='d-block'>{t(otpError)}</FormFeedback>
                                        )}
                                    </Col>
                                    <Col sm="6" className='align-content-end'>
                                        {
                                            otpCountdown > 0 
                                            ?
                                                <p className='ms-3 align-items-end text-primary'>
                                                    {
                                                        t("An OTP code has been sent to your registered email address. Please enter the code.")
                                                    }
                                                </p>
                                            :
                                                <></>
                                        }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" className="d-flex justify-content-between align-items-center col-sm-12">
                                        <Button
                                            onClick={() => onBackButtonClicked && onBackButtonClicked()}
                                            color="btn btn-outline-light"
                                            type="button">
                                            {t("Back")}
                                        </Button>

                                        <Button
                                            onClick={() => {
                                                const val = otp.replaceAll("-", "").replaceAll("_", "");
                                                if(val.length !== 6){
                                                    toast(t('This field is invalid'), {
                                                        type: "error",
                                                    });
                                                }
                                                else{
                                                    onUserFilledOtpInput && onUserFilledOtpInput(val);
                                                }
                                            }}
                                            color="btn btn-primary"
                                            type="button">
                                            {t('Send')}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </>
                )}
            </>
        ) : null
    )
}

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	
	return {
		token,
		user
	};
};

export default withNamespaces()(
	connect(mapStatetoProps, {})(
		FollowUp
	)
);