import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import delay from '@redux-saga/delay-p'

import axios from "axios";

import {
    ACTION_HANDLE_START_FETCHING_NOTIFICATIONS,
    ACTION_MARK_ALL_NOTIFICATIONS_AS_READ,
    ACTION_MARK_NOTIFICATION_AS_READ,
    ACTION_HANDLE_LOAD_MORE_NOTIFICATIONS,
    ACTION_REMOVE_NOTIFICATION_IN_LIST
} from "./actionTypes";

import {
    markNotificationAsReadLocalList,
    emptyNotificationsList,
    updateNotificationsList,
    updateIsLoadingStatus,
    removeNotificationInLocalList,
    setNotificationReadReqeustInprocess,
    notificationIsDeleting
} from "./actions";

import {
  MODULES_NOTIFICATIONS_ROUTES
} from "../../common/constants";

import store from "../";

const fetchNotificationListFromRemote = (pageIndex = 1) => {
    return new Promise((resolve, reject) => {
        const { token } = store.getState().Login;
        const { Modules } = store.getState();

        if(!MODULES_NOTIFICATIONS_ROUTES[Modules?.active]?.fetch){
            return (reject("No Notification fetch url!"));
        }

        if (!token) {
            return (reject("NO TOKEN AVAILABLE! Fetching notifications was blocked to prevent 401 errors."));
        }

        axios.get(MODULES_NOTIFICATIONS_ROUTES[Modules?.active].fetch , {
            params: {
                pageIndex: pageIndex,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(resolve)
        .catch(reject)
    });
};

const markNotificationAsReadOnRemote = async (id) => {
    const { token } = store.getState().Login;
    const { Modules } = store.getState();

    try {
        const response = await axios.post(
            MODULES_NOTIFICATIONS_ROUTES[Modules.active].markAsRead,
            {
                id: id,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (err) {
        throw err.response.data.error;
    }
};

const removeSelectedNotification = async (id) => {
    const { token } = store.getState().Login;
    const { Modules } = store.getState();
    try {
        const response = await axios.delete(
            `${MODULES_NOTIFICATIONS_ROUTES[Modules.active].removeNotificationBaseUrl}${id}/remove`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (err) {
        throw err.response.data.error;
    }
};

const markAllNotificationsAsReadOnRemote = async (id) => {
    const { token } = store.getState().Login;
    const { Modules } = store.getState();

    try {
        const response = await axios.post(
            MODULES_NOTIFICATIONS_ROUTES[Modules.active].markAllAsRead,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        return response.data;
    } catch (err) {
        throw err.response.data.error;
    }
};

function* startFetchingNotifications() {
    yield put(emptyNotificationsList());

    while (true) {
        try{
            const {
                data : {
                    data
                }
            } = yield fetchNotificationListFromRemote();

            const { AppNotification } = store.getState();

            const gotPageSize = parseInt(data?.pageSize || 0);
            const gotPageIndex = parseInt(data?.pageIndex || 0);
            const gotTotalCount = parseInt(data?.itemsCount || 0);

            if(
                AppNotification.totalCount !== gotTotalCount || 
                AppNotification.pageSize !== gotPageSize || 
                AppNotification.pageIndex !== gotPageIndex
            ){
                yield put(updateNotificationsList({
                    totalCount  :   gotTotalCount,
                    pageSize    :   gotPageSize,
                    pageIndex   :   gotPageIndex,
                    list        :   data?.notifications || []
                }))
            }
        }
        catch(err){
            console.log(err)
        }

        yield delay(15000);
    }
}

function* markNotificationAsRead({
    payload : {
        notificationId
    }
}) {
  try {
    yield put(setNotificationReadReqeustInprocess({
        notificationId: notificationId,
        newStatus: true
    }));

    try{
        yield call(markNotificationAsReadOnRemote, notificationId);
        yield put(markNotificationAsReadLocalList({notificationId}));
    }
    catch(err){
        throw err.response.data.error;
    }

    yield put(setNotificationReadReqeustInprocess({
        notificationId: notificationId,
        newStatus: false
    }));
  } catch (error) {}
}

function* removeNotification({
    payload : {
        notificationId
    }
}) {
  try {
    yield put(notificationIsDeleting({notificationId: notificationId, status: true}));
    yield call(removeSelectedNotification, notificationId);
    yield put(removeNotificationInLocalList({notificationId}));
  } catch (error) {}
}

function* markAllNotificationsAsRead() {
  try {
    yield put(emptyNotificationsList())
    yield call(markAllNotificationsAsReadOnRemote);
  } catch (error) {}
}

function* loadMoreNotifications() {
    try {
        const { AppNotification } = store.getState();

        yield put(updateIsLoadingStatus({
            status: true
        }));

        try{
            const nextPageIndex = AppNotification.pageIndex + 1;

            const {
                data : {
                    data
                }
            } = yield fetchNotificationListFromRemote(nextPageIndex);

            const nextNotifications = data?.notifications || [];

            if(nextNotifications.length > 0){
                yield put(updateNotificationsList({
                    totalCount  :   parseInt(data?.itemsCount || 0),
                    pageIndex   :   nextPageIndex,
                    list        :   [
                        ...AppNotification.list,
                        ...nextNotifications
                    ]
                }));
            }
        }
        catch(error){
            console.log(error)
        }

        yield put(updateIsLoadingStatus({
            status: false
        }));
    } catch (error) {}
  }

export function* watchMarkAllNotificationsAsRead() {
    yield takeEvery(ACTION_MARK_ALL_NOTIFICATIONS_AS_READ, markAllNotificationsAsRead);
}

export function* watchMarkNotificationAsRead() {
    yield takeEvery(ACTION_MARK_NOTIFICATION_AS_READ, markNotificationAsRead);
}

export function* watchRemoveNotification() {
    yield takeEvery(ACTION_REMOVE_NOTIFICATION_IN_LIST, removeNotification);
}

export function* watchStartFetchingNotifications() {
    yield takeEvery(ACTION_HANDLE_START_FETCHING_NOTIFICATIONS, startFetchingNotifications);
}

export function* watchLoadMoreNotifications() {
    yield takeEvery(ACTION_HANDLE_LOAD_MORE_NOTIFICATIONS, loadMoreNotifications);
}

function* notificationSaga() {
  yield all([
    fork(watchStartFetchingNotifications),
    fork(watchMarkNotificationAsRead),
    fork(watchRemoveNotification),
    fork(watchMarkAllNotificationsAsRead),
    fork(watchLoadMoreNotifications)
  ]);
}

export default notificationSaga;
