import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { withNamespaces } from "react-i18next";

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    textAlign: "center",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  text: {
    zIndex: 1,
    marginTop: 70,
    marginBottom: 80,
  },
  titleText: {
    zIndex: 1,
    marginTop: 70,
    marginBottom: 80,
    fontWeight: "600",
    fontSize: 16,
  },
  orgName: {
    fontWeight: "600",
    fontSize: 18,
  },
  downloadedAt: {
    marginTop: 65,
    marginBottom: 10,
    fontWeight: "600",
    fontSize: 14,
  },
  date: {
    fontWeight: "400",
    fontSize: 12,
    marginBottom: 5
  },
  cityCountryContainer: {
    flexDirection: "row", 
    justifyContent: "center", 
    marginTop: 10, 
  },
  cityCountryText: {
    fontSize: 16,
    fontWeight: "400",
  },
});

const FirstPageContent = (props) => {
  const { t, name, city, country, downloadedAt, created, updated } = props;
  return (
    <View style={styles.section}>
      <Text style={styles.titleText}>
        {t("Technical And Organizational Measures")}
      </Text>
      <Text style={styles.orgName}>{t(name).toUpperCase()}</Text>
      <View style={styles.cityCountryContainer}>
        <Text style={styles.cityCountryText}>
          {`${t(city)}, ${t(country)}`}
        </Text>
      </View>

      <Text style={styles.downloadedAt}>{`${t(
        "Document downloaded on"
      )}: ${downloadedAt}`}</Text>

      <Text style={styles.date}>{`${t("Created")}: ${created}`}</Text>

      <Text style={styles.date}>{`${t("Last Update")}: ${updated}`}</Text>
    </View>
  );
};

export default withNamespaces()(FirstPageContent);
