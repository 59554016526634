import { Fragment } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import NotificationItem from '../../components/NotificationItem';
import { SupplierStatuses } from 'src/modules/3rd-party-management/constants';

const Handler = ({
    notifications,
    t
}) => {
    const handleTranslateOnType = (notification) => {
		const notificationType = notification.message_type;

		switch (notificationType) {
            /**
             * admins
             */
            case 'admin_report_submitted': {
                return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('Your report has been submitted')}.</span>
					</Fragment>
				);
            }
            case 'report_request_expiration_over': {
                const {
                    supplier
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The report request deadline has expired')}.</span>
						<span style={{ display: 'block' }}>
							{t('Supplier ID')}: {supplier}
						</span>
					</Fragment>
				);
			}
            case 'report_request_assigned':{
                const { supplier } = notification.data;

                return (
                    <Fragment>
                        <span style={{ display: 'block' }}>
                            {t('You have a new report request')}.
                        </span>
                        <span style={{ display: 'block' }}>
							{t('Supplier ID')}: {supplier}
						</span>
                    </Fragment>
                );
            }
            case 'supplier_replace_contact_person': {
                const { supplier } = notification.data;

                return (
                    <Fragment>
                        <span style={{ display: 'block' }}>
                            {t('The contact person changed')}.
                        </span>
                        <span style={{ display: 'block' }}>
							{t('Supplier ID')}: {supplier}
						</span>
                    </Fragment>
                );
            }
            case 'supplier_assigned':{
                const { supplier } = notification.data;

                return (
                    <Fragment>
                        <span style={{ display: 'block' }}>{t('A supplier was assigned to you')}.</span>
                        <span style={{ display: 'block' }}>
							{t('Supplier ID')}: {supplier}
						</span>
                    </Fragment>
                );
            }
            case 'new_case_reported': {
                const { supplier } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('You have received a new report')}.</span>
						<span style={{ display: 'block' }}>
							{t('Supplier ID')}: {supplier}
						</span>
					</Fragment>
				);
			}
            case 'new_comment': {
                const { supplier } = notification.data;

                return (
					<Fragment>
						<span style={{ display: 'block' }}>
							{t('New comment from supplier')}.
						</span>
						<span style={{ display: 'block' }}>
							{t('Supplier ID')}: {supplier}
						</span>
					</Fragment>
				);
			}
            case 'new_internal_comment': {
                const { supplier } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('You have received a new internal comment')}.</span>
						<span style={{ display: 'block' }}>
							{t('Supplier ID')}: {supplier}
						</span>
					</Fragment>
				);
			}
            case 'change_supplier_status':{
                const { 
                    supplier,
                    new_status
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t(`Supplier status changed to ${SupplierStatuses[new_status]?.title}`)}.</span>
						<span style={{ display: 'block' }}>
							{t('Supplier ID')}: {supplier}
						</span>
					</Fragment>
				);
            }
            /**
             * admins
             * risks
             */
            case 'risk_assign': {
                const { 
                    secondaryRiskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A new risk has been assigned to you.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryRiskId}
						</span>
					</Fragment>
				);
			}
            case 'risk_comment': {
                const { 
                    secondaryRiskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A comment was added to your risk.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryRiskId}
						</span>
					</Fragment>
				);
			}
            case 'risk_change_description': {
                const { 
                    secondaryRiskId
                } = notification.data;
                return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('Description of your risk was changed')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryRiskId}
						</span>
					</Fragment>
				);
			}
            case 'risk_rating': {
                const { 
                    riskRate	
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The rating of your risk has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('New rating')}: {t(riskRate)}
						</span>
					</Fragment>
				);
			}
            /**
             * admins
             * tasks
             */
            case 'new_task_created': {
                const { 
                    secondaryTaskId	
                } = notification.data;
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A new task has been assigned to you.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
					</Fragment>
				);
			}
			case 'task_status_new': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('New')}
						</span>
					</Fragment>
				);
			}
			case 'task_status_in_progress': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('In Progress')}
						</span>
					</Fragment>
				);
			}
			case 'task_status_review': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('Review')}
						</span>
					</Fragment>
				);
			}
			case 'task_status_delete': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('Deleted')}
						</span>
					</Fragment>
				);
			}
			case 'task_status_close': {
                const {
                    secondaryTaskId
                } = notification.data;
                
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The status of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New status')}: {t('Closed')}
						</span>
					</Fragment>
				);
			}
			case 'task_priority_low': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The priority of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New priority')}: {t('Low')}
						</span>
					</Fragment>
				);
			}
			case 'task_priority_medium': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The priority of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New priority')}: {t('Medium')}
						</span>
					</Fragment>
				);
            }
			case 'task_priority_high': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The priority of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
						<span style={{ display: 'block' }}>
							{t('New priority')}: {t('High')}
						</span>
					</Fragment>
				);
            }
			case 'task_comment':{
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('A comment was added to your task')}.</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
					</Fragment>
				);
            }
			case 'task_before_overdue': {
                const {
                    taskId,
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The deadline for the task is soon.')}</span>
						<span style={{ display: 'block' }}>
							{secondaryTaskId
								? '\n' + t('Task ID') + ': ' + secondaryTaskId
								: taskId
								? '\n' + t('Task ID') + ': ' + taskId
								: ''}
						</span>
					</Fragment>
				);
			}
			case 'new_task': {
				return <span style={{ display: 'block' }}>{t('You created a new task')}.</span>;
			}
			case 'task_change_deadline': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('Deadline of your task was changed')}.</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
					</Fragment>
				);
			}
			case 'task_change_description': {
                const {
                    secondaryTaskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The description of your task has been changed.')}</span>
						<span style={{ display: 'block' }}>
							{t('ID')}: {secondaryTaskId}
						</span>
					</Fragment>
				);
			}
			case 'task_overdue': {
                const {
                    secondaryTaskId,
                    taskId
                } = notification.data;

				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('The deadline for your task is today.')}</span>
						<span style={{ display: 'block' }}>
							{secondaryTaskId
								? '\n' + t('Task ID') + ': ' + secondaryTaskId
								: taskId
								? '\n' + t('Task ID') + ': ' + taskId
								: ''}
						</span>
					</Fragment>
				);
			}

            /**
             * third parties
             */
            case 'new': {
				return (
					<Fragment>
						<span style={{ display: 'block' }}>{t('Your report has been submitted')}</span>
					</Fragment>
				);
			}
            case 'new_supplier_comment': {
                return (
					<Fragment>
						<span style={{ display: 'block' }}>
                            {t('You have received a new comment')}.
                        </span>
					</Fragment>
				);
            }


			default: {
				return t(notificationType);
			}
		}
	}

    const handleRenderNotification = (notification, index) => {
		let link = '#';

		if (typeof notification.id !== 'undefined') {
			switch (notification.message_type) {
                /**
                 * admins
                 */
                case 'admin_report_submitted':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=reports`
                    break;
                case 'report_request_expiration_over':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=basic-information`
                    break;
                case 'report_request_assigned':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=bafa-questionnaire`
                    break;
                case 'supplier_replace_contact_person':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=basic-information`
                    break;
                case 'supplier_assigned':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=overview`
                    break;
                case 'new_comment':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=communication`
                    break;
                case 'new_case_reported':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=reports`
                    break;
                case 'new_internal_comment':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=internal-communication`
                    break;
                case 'change_supplier_status':
                        link = `3rd-party/suppliers/${notification.data.supplier}/details?tab=overview`
                    break;
                /**
                 *  admins
                 * risks 
                 **/    
                case 'risk_assign':
                case 'risk_comment':
                case 'risk_change_description':
                case 'risk_rating':
                        link = `3rd-party/suppliers/${notification.data.supplier}/risks/${notification.data.riskId}/details`
                    break;

                /**
                 * admins 
                 * tasks
                 */
                
                case 'new_task_created':
                case 'task_status_new':
                case 'task_status_in_progress':
                case 'task_status_review':
                case 'task_status_delete':
                case 'task_status_close':
                case 'task_priority_low':
                case 'task_priority_medium':
                case 'task_priority_high':
                case 'task_comment':
                case 'task_before_overdue':
                case 'new_task':
                case 'task_change_deadline':
                case 'task_change_description':
                case 'task_overdue':
                        link = `3rd-party/suppliers/${notification.data.supplier}/tasks/${notification.data.taskId}/details`
                    break;
                
                /**
                 * third parties
                 */
                case 'new':
                        link = "3rd-party/reports"
                    break;
                case 'new_supplier_comment':
                        link = "3rd-party/reports"
                    break;

				default: break;
			}
		}

        return (
            <NotificationItem 
                index={index}
                link={link} 
                notification={notification}
                content={handleTranslateOnType(notification)}
            />
        )
	};

    return (
        <>
            {(notifications?.list || []).map((notification, index) => {
                return (
                    <div key={notification.id}
                        style={{
                            animationName: notification.hasMotion ? 'newNotificationAnimation' : '',
                            animationDuration: '0.6s',
                            animationIterationCount: 1,
                            overflow: 'hidden',
                            transition: 'opacity 0.3s ease 0s, max-height 0.3s ease 0.3s',
                        }}
                    >
                        {handleRenderNotification(notification, index)}
                    </div>
                );
            })}
        </>
    );
}

const mapStatetoProps = (state) => {
	return {
        organization    :   state.Organization,
        user            :   state.Login?.user,
        token           :   state.Login?.token,
		notifications   :   state.AppNotification,
        modules         :   state.Modules
	};
};

export default connect(
    mapStatetoProps, null
)(withNamespaces()(
    Handler
));