import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  errorNotification,
  successNotification,
} from "../../../../../store/actions";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Button, Tooltip, UncontrolledTooltip } from "reactstrap";
import cs from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import DataProtectionHardwareService from "../../../apis/DataProtectionHardwareService";
import DataProtectionADService from "../../../apis/DataProtectionADService";
import CommonService from "../../../apis/CommonService";
import overlayFactory from "react-bootstrap-table2-overlay";

// icons
import flagOnIcon from "src/modules/data-protection/assets/icons/flag-icon.png";
import flagOffIcon from "src/modules/data-protection/assets/icons/flag-icon-off.png";
import highPriorityIcon from "src/modules/data-protection/assets/icons/priority-high.png";
import mediumPriorityIcon from "src/modules/data-protection/assets/icons/priority-medium.png";
import lowPriorityIcon from "src/modules/data-protection/assets/icons/priority-low.png";
import UploadIcon from "src/modules/data-protection/assets/icons/file_upload.svg";
import DownloadIcon from "src/modules/data-protection/assets/icons/file_download.svg";
import LaptopIcon from "src/modules/data-protection/assets/icons/laptop.svg";
import TabletIcon from "src/modules/data-protection/assets/icons/tablet.svg";
import PrinterIcon from "src/modules/data-protection/assets/icons/printer.svg";
import DesktopIcon from "src/modules/data-protection/assets/icons/monitor.svg";
import SmartphoneIcon from "src/modules/data-protection/assets/icons/smartphone.svg";
import ScannerIcon from "src/modules/data-protection/assets/icons/scanner.svg";
import VoipPhoneIcon from "src/modules/data-protection/assets/icons/voipPhone.svg";
import WatchIcon from "src/modules/data-protection/assets/icons/watch.svg";
import WebCamIcon from "src/modules/data-protection/assets/icons/webcam.svg";
import HeadsetIcon from "src/modules/data-protection/assets/icons/headset.svg";
import ExternalHardDeriveIcon from "src/modules/data-protection/assets/icons/externalHardDrives.svg";
import USBFlashDriveIcon from "src/modules/data-protection/assets/icons/usbFlashDrives.svg";
import MonitorsIcon from "src/modules/data-protection/assets/icons/monitors.svg";
import KeyboardAndMiceIcon from "src/modules/data-protection/assets/icons/keyboardAndMice.svg";
import NetworkRouterIcon from "src/modules/data-protection/assets/icons/networkRouter.svg";
import NetworkSwitchIcon from "src/modules/data-protection/assets/icons/networkSwitch.svg";
import FirewallIcon from "src/modules/data-protection/assets/icons/fireWall.svg";
import LaserPointerIcon from "src/modules/data-protection/assets/icons/laserPointer.svg";
import VideoConferencingIcon from "src/modules/data-protection/assets/icons/videoConferencingSystem.svg";
import DockingStationIcon from "src/modules/data-protection/assets/icons/dockingStation.svg";
import BarCodeScannerIcon from "src/modules/data-protection/assets/icons/barcodeScanner.svg";
import PosSystemIcon from "src/modules/data-protection/assets/icons/posSystem.svg";
import BiometricTimeClockIcon from "src/modules/data-protection/assets/icons/biometricTimeClock.svg";
import SecurityCameraIcon from "src/modules/data-protection/assets/icons/securityCamera.svg";
import ProjectorIcon from "src/modules/data-protection/assets/icons/projector.svg";
import LabelPrinterIcon from "src/modules/data-protection/assets/icons/labelPrinter.svg";
import CreditCardReaderIcon from "src/modules/data-protection/assets/icons/creditCardReader.svg";
import DigitalVoiceRecorderIcon from "src/modules/data-protection/assets/icons/digitalVoiceRecorder.svg";
import CashRegisterIcon from "src/modules/data-protection/assets/icons/cashRegister.svg";
import ElectronicSigniturePadIcon from "src/modules/data-protection/assets/icons/electronicSigniturePad.svg";
import DocumentCameraIcon from "src/modules/data-protection/assets/icons/documentCamera.svg";
import DigitalWhiteBoardIcon from "src/modules/data-protection/assets/icons/digitalWhiteBoard.svg";
import CompanyOwnedVehiclesWithGPSIcon from "src/modules/data-protection/assets/icons/companyOwnedVehiclesWithGPS.svg";
import MobilePosDeviceIcon from "src/modules/data-protection/assets/icons/mobilePosDevice.svg";
import BiometricAccessControlSystemIcon from "src/modules/data-protection/assets/icons/biometricAccessControlSystem.svg";
import RFIDScannerIcon from "src/modules/data-protection/assets/icons/rfidScanner.svg";
import IndustrialControlSystemIcon from "src/modules/data-protection/assets/icons/industrialControlSystem.svg";
import InteractiveKioskIcon from "src/modules/data-protection/assets/icons/interactiveKiosk.svg";
// styles
import "./style.scss";
// components
import PageBody from "../../../components/page-body";
import PageContent from "../../../components/page-content";
import Deletion from "../modal/deletion";
import HandoverModal from "../modal/handover";
import useUploadCSV from "../../../hooks/useUploadCSV";
import Spinner from "react-bootstrap/Spinner";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { EMPTY_LIST } from "src/common/constants";
import TableFilter from "../components/table-filter";
import {Assets_STATUS_LIST} from "../../../constants"
import { useFilterQuery } from "src/modules/data-protection/hooks/useFilters";
import CommonServices from "src/modules/data-protection/apis/CommonService";

const DataProtectionHardwareList = (props) => {
  const { t } = props;

  const [tableFilters, setTableFilters] = useState({
    pageIndex: 1,
    pageSize: 25,
    status: null,
    flag: null,
    owner: null,
    sortField :null,
    sortOrder: null
  });

  const [tableResultTotalCount, setTableResultTotalCount] = useState(0);
  const [selectedFiltersDisplay, setSelectedFiltersDisplay] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [ownerSelected, setOwnerSelected] = useState(null);
  const [statusState, setStatusState] = useState(null);
  const [flagState, setFlagState] = useState(null);
  const [handoverTooltipOpen, setHandoverTooltipOpen] = useState(false);

  const toggleHandoverTooltip = () => setHandoverTooltipOpen(!handoverTooltipOpen);
  
  const HardwareStatuses = {
    NEW: t("New"),
    IN_PROGRESS: t("In Progress"),
    OPEN: t("Open"),
    APPROVED: t("Approved"),
    ASSIGNED: t("Assigned"),
    DELETED: t("Deleted"),
  };

  const HARDWARE_TYPES_ICONS = {
    DESKTOP_COMPUTERS: DesktopIcon,
    LAPTOP_COMPUTERS: LaptopIcon,
    SMARTPHONES: SmartphoneIcon,
    TABLETS: TabletIcon,
    PRINTERS: PrinterIcon,
    SCANNERS: ScannerIcon,
    VOIP_PHONES: VoipPhoneIcon,
    WEBCAMS: WebCamIcon,
    HEADSETS: HeadsetIcon,
    EXTERNAL_HARD_DRIVES: ExternalHardDeriveIcon,
    USB_FLASH_DRIVES: USBFlashDriveIcon,
    MONITORS: MonitorsIcon,
    KEYBOARDS_AND_MICE: KeyboardAndMiceIcon,
    NETWORK_ROUTERS: NetworkRouterIcon,
    NETWORK_SWITCHES: NetworkSwitchIcon,
    FIREWALLS: FirewallIcon,
    LASER_POINTERS: LaserPointerIcon,
    VIDEO_CONFERENCING_SYSTEMS: VideoConferencingIcon,
    DOCKING_STATIONS: DockingStationIcon,
    BARCODE_SCANNERS: BarCodeScannerIcon,
    POS_SYSTEMS: PosSystemIcon,
    BIOMETRIC_TIME_CLOCKS: BiometricTimeClockIcon,
    SECURITY_CAMERAS: SecurityCameraIcon,
    PROJECTORS: ProjectorIcon,
    LABEL_PRINTERS: LabelPrinterIcon,
    CREDIT_CARD_READERS: CreditCardReaderIcon,
    DIGITAL_VOICE_RECORDERS: DigitalVoiceRecorderIcon,
    CASH_REGISTERS: CashRegisterIcon,
    ELECTRONIC_SIGNATURE_PADS: ElectronicSigniturePadIcon,
    DOCUMENT_CAMERAS: DocumentCameraIcon,
    DIGITAL_WHITEBOARDS: DigitalWhiteBoardIcon,
    COMPANY_OWNED_VEHICLES_WITH_GPS: CompanyOwnedVehiclesWithGPSIcon,
    MOBILE_POS_DEVICES: MobilePosDeviceIcon,
    BIOMETRIC_ACCESS_CONTROL_SYSTEMS: BiometricAccessControlSystemIcon,
    RFID_SCANNERS: RFIDScannerIcon,
    INDUSTRIAL_CONTROL_SYSTEMS: IndustrialControlSystemIcon,
    INTERACTIVE_KIOSKS: InteractiveKioskIcon,
    WEARABLE_DEVICES: WatchIcon,
  };

  const { openUploadDialog, uploadCSV } = useUploadCSV(
    DataProtectionHardwareService,
    (data) => {
      toast(props.t("csv uploaded successfully"), {
        type: "success",
      });

      handleFetchHardwareListQuery.refetch();
    },

    (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);
      const errors = [];
      if (error?.response?.data) {
        const errorData = error.response.data.error;
        Object.keys(errorData).forEach((key, index) => {
          if (typeof errorData[key] === "string") {
            errors.push(
              <li>{`${props.t("Line")} ${key}: ${errorData[key]}`}</li>
            );
          }
          if (typeof errorData[key] === "object") {
            Object.keys(errorData[key]).forEach((errorKey) => {
              errors.push(
                <li>{`${props.t("Line")} ${key}: ${
                  errorData[key][errorKey]
                }`}</li>
              );
            });
          }
        });
      }
      toast(<ul>{errors}</ul>, {
        type: "error",
        style: {
          width: "max-content",
        },
      });
      handleFetchHardwareListQuery.refetch();
    }
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const tab = searchParams.get("tab");
  const [hardwares, setHardwares] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [activeTab, setActiveTab] = useState("ALL");

  const [showDeletionModal, setDeletionModal] = useState(false);
  const [showHandOverModal, setHandoverModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [deletionComplete, setDeletionComplete] = useState(false);

  const [syncWithAzureRunning, setSyncWithAzureRunning] = useState(false);
  
  const userFilterQuery = useFilterQuery({
    Service: CommonServices,
    method: "analystAdminsAndAnalysts",
    params: {},
    filterKey: ["user"],
  });

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      // Select all ids
      const allIds = hardwares.assets.map((software) => software.id);
      setSelectedIds(allIds);
    } else {
      // Deselect all
      setSelectedIds([]);
    }
  };

  const tableColumns = [
    {
      dataField: "checkbox",
      text: (
        <input
          type="checkbox"
          className="custom-checkbox"
          checked={
            selectedIds.length > 0 && selectedIds.length === hardwares.assets.length
          }
          onChange={handleSelectAllChange}
          style={{ marginLeft: 'auto', marginRight: 'auto' }} // Center the checkbox
        />
      ),
      sort: false,
      key: 1,
      formatter: (cellContent, row) => {
        return (
          <input
            type="checkbox"
            className="custom-checkbox"
            checked={selectedIds.includes(row.id)}
            onChange={() => handleCheckboxChange(row.id)}
          />
        );
      },
      style: {
        width: "1%",
        textAlign: "center",
      },
    },
    {
      dataField: "asset_id",
      text: t("Asset ID"),
      sort: false,
      key: 2,
      style: {
        width: "6.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        row.status !== "DELETED" ? <Link
          to={`hardwares/${row.id}`}
          className="text-truncate text-capitalize text-left"
        >
          {row.asset_id}
        </Link> : 
        <span
        className="text-truncate text-capitalize text-left"
      >
        {row.asset_id}
      </span>
      ),
    },
    {
      dataField: "asset_type",
      text: t("Type"),
      sort: true,
      key: 3,
      style: {
        width: "11.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <p className="text-truncate text-capitalize text-left me-3">
          {HARDWARE_TYPES_ICONS[row.asset_type] ? (
            <>
              <img
                src={HARDWARE_TYPES_ICONS[row.asset_type]}
                width={30}
                height={30}
              />
              {" " + t(row.asset_type.replace("_", " ").toLowerCase())}
            </>
          ) : (
            t(row.asset_type.replace("_", " ").toLowerCase())
          )}
        </p>
      ),
    },
    {
      dataField: "manufacture_name",
      text: t("Manufacturer"),
      sort: true,
      key: 4,
      style: {
        width: "11.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <p className="text-truncate text-capitalize text-left">
          {row.manufacture_name}
        </p>
      ),
    },
    {
      dataField: "serial_no",
      text: t("Serial No"),
      sort: true,
      key: 5,
      style: {
        width: "8.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <p className="text-truncate text-capitalize text-left">
          {row.serial_no}
        </p>
      ),
    },
    {
      dataField: "model_name",
      text: t("Model"),
      sort: true,
      key: 6,
      style: {
        width: "8.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <p className="text-truncate text-capitalize text-left">
          {row.model_name}
        </p>
      ),
    },
    {
      dataField: "operating_system_name",
      text: t("Operating System"),
      sort: true,
      key: 7,
      style: {
        width: "8.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <p className="text-truncate text-capitalize text-left me-3">
          {row.operating_system_name}
        </p>
      ),
    },
    {
      dataField: "owner_name",
      text: t("Owner"),
      sort: true,
      key: 8,
      style: {
        width: "8.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <p className="text-truncate text-capitalize text-left me-3">
          {row.owner_name}
        </p>
      ),
    },
    {
      dataField: "analyst_assigned_name",
      text: t("Assigned Analyst"),
      sort: true,
      key: 9,
      style: {
        width: "8.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <p className="text-truncate text-capitalize text-left me-3">
          {row.analyst_assigned_name ? row.analyst_assigned_name : "---"}
        </p>
      ),
    },
    {
      dataField: "priority",
      text: t("Priority"),
      sort: true,
      key: 10,
      style: {
        width: "8.3%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <div className="text-truncate text-capitalize text-left d-flex justify-content-start">
          {handleRenderPriority(row.priority)}
        </div>
      ),
    },
    {
      dataField: "flag",
      text: t("Flag"),
      sort: true,
      key: 11,
      style: {
        width: "5%",
        textAlign: "left",
      },
      formatter: (cell, row) => (
        <div className="text-truncate text-capitalize text-left d-flex justify-content-start">
          {handleRenderFlag(row)}
        </div>
      ),
    },
    {
      dataField: "status",
      text: t("Status"),
      sort: true,
      key: 12,
      style: {
        width: "8.3%",
        textAlign: "center",
      },
      formatter: (cell, row) => (
        <div className="text-truncate text-capitalize text-center d-flex justify-content-center">
          {handleRenderStatus(row.status)}
        </div>
      ),
    },
    {
      dataField: "actions",
      text: t("Actions"),
      sort: false,
      key: 13,
      style: {
        width: "5.3%",
        textAlign: "center",
      },
      formatter: (cellContent, row) => {
        if (row.status !== "DELETED") {
          return (
            <div className="text-danger text-truncate text-capitalize text-center d-flex align-items-center justify-content-between remove">
              <span
                id={`handoverIcon-${row.id}`}
                onClick={() => onHandOverClick(row.id)}
                className="fa text-info fa-stack"
                style={{ cursor: "pointer" }}
              >
                <a>
                  <i className="fa position-absolute top-10 fa-box fa-stack-1x" />
                </a>
                <a>
                  <i
                    className="fa fa-hand-holding"
                    aria-hidden="true"
                  ></i>
                </a>
              </span>
              <UncontrolledTooltip
                target={`handoverIcon-${row.id}`}
                placement="bottom"
                trigger="hover"
              >
                {t("This action allows you to hand over the item to another user.")}
              </UncontrolledTooltip>
              <a onClick={() => onRemoveClick(row.id)}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
            </div>
          );
        } else {
          return (
            <span className="text-danger text-truncate text-capitalize text-center d-flex justify-content-center remove">
              {t("Deleted")}
            </span>
          );
        }
      },
    },
  ];

  const handleFetchHardwareListQuery = useQuery({
    queryKey: [
      "data-protection-hardwares-list",
      pageIndex,
      pageSize,
      tableFilters,
    ],
    queryFn: async () => {
      const service = DataProtectionHardwareService.getInstance();
      return await service.list(tableFilters.pageIndex, tableFilters.pageSize, {
        status:
          tab === "deletion-protocol"
            ? "DELETED"
            : tableFilters.status
            ? tableFilters.status
            : null,
        owner: tableFilters.owner ? tableFilters.owner : null,
        flag: tableFilters.flag ? tableFilters.flag : null,
        ...(tableFilters.sortField && { sortField: tableFilters.sortField }),
        ...(tableFilters.sortField && { sortOrder: tableFilters.sortOrder }),
      });
    },
    cacheTime: 0,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occured while fetching hardwares."), {
        type: "error",
      });
    },
  });

  const fetchUsers = useQuery({
    queryFn: async () => {
      const service = CommonService.getInstance();

      return await service.analystAdminsAndAnalysts();
    },
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: (error) => {
      if (process.env.NODE_ENV === 'development') console.error(error);

      toast(props.t("An error occurred while fetching users."), {
        type: "error",
      });
    },
  });

  const toggleHardwareFlagMutation = useMutation({
    mutationFn: async ({ hardwareId }) => {
      const service = DataProtectionHardwareService.getInstance();
      return await service.toggleFlag({
        hardwareId: hardwareId,
      });
    },
    onSuccess: () => {
      toast(props.t("Hardware flag status successfully changed."), {
        type: "success",
      });
    },
    onError: () => {
      toast(props.t("An error occurred while updating hardware flag status."), {
        type: "error",
      });
    },
    onMutate: ({ hardwareId }) => {
      setHardwares({
        ...hardwares,
        assets: hardwares.assets.map((hardware) => {
          if (hardware.id === hardwareId) {
            return {
              ...hardware,
              flagIsUpdating: true,
            };
          }
          return hardware;
        }),
      });
    },
  });

  const syncHardwaresListWithADMutation = useMutation({
    mutationFn: async () => {
      const service = DataProtectionADService.getInstance();
      return await service.syncAssetsListWithAD();
    },
    onSuccess: () => {
      handleFetchHardwareListQuery.refetch();

      toast(props.t("Hardwares list updated successfully."), {
        type: "success",
      });
    },
    onError: () => {
      toast(
        props.t("An error occurred while fetching hardwares list from azure."),
        {
          type: "error",
        }
      );
    },
    onMutate: () => {
      setSyncWithAzureRunning(true);
    },
    onSettled: () => {
      setSyncWithAzureRunning(false);
    },
  });

  const onRemoveClick = (id) => {
    setSelectedId(id);
    setDeletionModal(true);
  };

  const onHandOverClick = (id) => {
    setSelectedId(id);
    setHandoverModal(true);
  };

  const toggleAssetFlag = (asset) => {
    toggleHardwareFlagMutation.mutate(
      {
        hardwareId: asset.id,
      },
      {
        onSuccess: () => {
          setHardwares({
            ...hardwares,
            assets: hardwares.assets.map((hardware) => {
              if (hardware.id === asset.id) {
                return {
                  ...hardware,
                  flag: !asset.flag,
                };
              }
              return hardware;
            }),
          });
        },
      }
    );
  };

  const handleRenderFlag = (asset) => {
    return (
      <span
        onClick={() => toggleAssetFlag(asset)}
        className="flag-icon d-flex align-items-center justify-content-center text-center"
        role="button"
      >
        {asset.flagIsUpdating === true ? (
          <Spinner animation="border" variant="secondary" size="sm" />
        ) : (
          <img
            src={asset.flag ? flagOnIcon : flagOffIcon}
            alt="Flag Icon"
            className="object-fit-contain d-inline-block mw-100"
            style={{ width: "24px" }}
          />
        )}
      </span>
    );
  };

  const handleRenderPriority = (priority) => {
    switch (priority) {
      case "HIGH": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={highPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {t(priority.toLowerCase())}
          </div>
        );
      }
      case "MEDIUM": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={mediumPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {t(priority.toLowerCase())}
          </div>
        );
      }
      case "LOW": {
        return (
          <div className="priority text-capitalize">
            <span className="priority-icon d-flex align-items-center justify-content-center">
              <img
                src={lowPriorityIcon}
                alt="Flag Icon"
                className="object-fit-contain d-inline-block mw-100"
              />
            </span>
            {t(priority.toLowerCase())}
          </div>
        );
      }
    }

    return "---";
  };

  const handleRenderStatus = (status) => {
    const statusData = Assets_STATUS_LIST[status];
  
    if (!statusData) {
      return "---";
    }
  
    return (
      <div
        style={{
          fontSize: "10px",
          fontWeight: 700,
          padding: "3px 8px",
          background: statusData.backgroundColor,
          opacity: statusData?.opacity || 1,
          color:"#1D1D1D",
          borderRadius:'7px',
          whiteSpace:'nowrap',
          width:'fit-content'
        }}
        className={"dt-task-status "}
      >
        {t(statusData.title)}
      </div>
    );
  };

  const handleDownloadCSV = async (fileName) => {
    try {
      const service = DataProtectionHardwareService.getInstance();
      const result = await service.downloadCSV();
      const fileData = new Uint8Array(result);
      const blob = new Blob([fileData], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      // document.body.removeChild(link);
    } catch (error) {
      toast(props.t("An error occurred while downloading CSV."), {
        type: "error",
      });
      console.error("failed to download csv: ", error);
    }
  };

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        const { page, sizePerPage } = data
        setTableFilters({
          ...tableFilters,
          pageIndex: page,
          pageSize: sizePerPage,
        });
        break;
        case 'sort': {
          const { sortField, sortOrder } = data

          if (sortField === 'operating_system_name') {
            setTableFilters({
              ...tableFilters,
              sortField: 'os',
              sortOrder: sortOrder,
            });
          }
          else if (sortField === 'manufacture_name') {
            setTableFilters({
              ...tableFilters,
              sortField: 'manufacturer',
              sortOrder: sortOrder,
            });
          }
          else if (sortField === 'model_name') {
            setTableFilters({
              ...tableFilters,
              sortField: 'model',
              sortOrder: sortOrder,
            });
          }   
           else if (sortField === 'owner_name') {
            setTableFilters({
              ...tableFilters,
              sortField: 'owner',
              sortOrder: sortOrder,
            });
          }
          
          else if (sortField === 'analyst_assigned_name') {
            setTableFilters({
              ...tableFilters,
              sortField: 'assignedAnalyst',
              sortOrder: sortOrder,
            });
          }
          else{
            setTableFilters({
              ...tableFilters,
              sortField: sortField,
              sortOrder: sortOrder,
            });
          }
          break
      }
      default:
        return false;
    }
  };

  const NoDataIndication = () =>
    handleFetchHardwareListQuery.isFetched &&
    !handleFetchHardwareListQuery.length ? (
      <div className="alert m-0" role="alert">
        <p
          style={{
            textAlign: "center",
            marginBottom: 0,
          }}
        >
          {t(EMPTY_LIST)}
        </p>
      </div>
    ) : (
      <></>
    );

  const onTableFilterOkButtonClicked = (filters) => {
    let result = {};
    let displayFilters = {};
    for (const tableFilterKey in tableFilters) {
      result[[tableFilterKey]] = filters[tableFilterKey];
      displayFilters[tableFilterKey] = filters[tableFilterKey];
    }
    
    setSelectedFiltersDisplay(displayFilters);
    setTableFilters(result);
  };

  const onApproveSelection = useMutation({
    mutationFn: async () => {
      const service = DataProtectionHardwareService.getInstance();
      return await service.approveSelection({
        hardwareIds: selectedIds,
      });
    },
    onSuccess: () => {
      toast(t("Selected hardwares are approved"), {
        type: "success",
      });
      handleFetchHardwareListQuery.refetch();
    },
    onError: () => {
      toast(t("An error occurred while approving selected hardwares."), {
        type: "error",
      });
    },
  });

  const handleCheckboxChange = (id) => {
    setSelectedIds((currentIds) => {
      if (currentIds.includes(id)) {
        // Remove id from the array if it is already included
        return currentIds.filter((currentId) => currentId !== id);
      } else {
        // Add id to the array if it is not included
        return [...currentIds, id];
      }
    });
  };

  const handleDownloadTemplate = async (fileName) => {
    try {
      const service = DataProtectionHardwareService.getInstance();
      const result = await service.downloadTemplate();
      const fileData = new Uint8Array(result);
      const blob = new Blob([fileData], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.log(error)
      toast(props.t("An error occurred while downloading template."), {
        type: "error",
      });
      console.error("failed to download template: ", error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageIndex = searchParams.get("pageIndex");
    if (pageIndex !== null && !Number.isNaN(Number(pageIndex)))
      setPageIndex(Number(pageIndex));

    const pageSize = searchParams.get("pageSize");
    if (pageSize !== null && !Number.isNaN(Number(pageSize)))
      setPageSize(Number(pageSize));
  }, [location.search]);

  useEffect(() => {
    if (handleFetchHardwareListQuery.data) {
      setTableResultTotalCount(
        Number(handleFetchHardwareListQuery.data.itemsCount) || 0
      );
    }

    if (deletionComplete) {
      handleFetchHardwareListQuery.refetch();
      setDeletionComplete(false);
    }
  }, [deletionComplete, handleFetchHardwareListQuery.data]);

  useEffect(() => {
    if (fetchUsers.data && fetchUsers.data?.length) {
      // setTotalCount(Number(handleFetchHardwareListQuery.data.itemsCount));
      const users = fetchUsers.data.map((u) => {
        return {
          value: u.id,
          label: u.first_name + " " + u.last_name,
        };
      });
      setUsers(users);
    }
  }, [fetchUsers.data]);

  useEffect(() => {
    if (handleFetchHardwareListQuery.data) {
      setHardwares(handleFetchHardwareListQuery.data);
    }
  }, [handleFetchHardwareListQuery.data]);

  useEffect(() => {
    handleFetchHardwareListQuery.refetch();
  }, []);

  useEffect(() => {
    if (handleFetchHardwareListQuery.data) {
      setTotalCount(Number(handleFetchHardwareListQuery.data.itemsCount));
    }

    handleFetchHardwareListQuery.refetch();
  }, [tab]);
  
  useEffect(() => {
    const { flag, owner, status} = selectedFiltersDisplay;
    const filteredUser = owner ? userFilterQuery?.data?.filter(u => u?.id === owner)[0] : null;
    setOwnerSelected(filteredUser);
    setStatusState(HardwareStatuses[status]);
    setFlagState(flag);
    
  }, [selectedFiltersDisplay]);

  return (
    <PageContent classNames="data-protection-hardware-list-page pt-4 mt-3">
      <Container fluid>
        {/* page header */}
        <Row
          title={props.t("Software Assets List")}
          className="d-flex mb-5"
          style={{ justifyContent: "space-between" }}
        >
           <Col md="auto">
            <Button
              outline
              color="primary"
              disabled={!selectedIds?.length}
              onClick={() => onApproveSelection.mutate()}
            >
              {props.t("Approve Selected")}
            </Button>
          </Col>

          <Col className="flex-grow-1"></Col>

          {/* Select components for status, flags, and asset owner, with fit-content width */}
          <Col md="auto" className="d-flex justify-content-end">
          <div>
          <TableFilter
              defaultValues={{
                ...tableFilters,
              }}
              statuses={HardwareStatuses}
              onOkButtonClicked={onTableFilterOkButtonClicked}
              type={props.type}
            />
            
             <div className="d-flex gap-4 justify-content-end mt-2" hidden={
                            !ownerSelected && !flagState && !statusState
                          }>
                            <ul style={{maxWidth:"312px"}}> 
                            <li hidden={!ownerSelected}>{t("User")}: {`${ownerSelected?.first_name} ${ownerSelected?.last_name}`}</li>
                            <li hidden={flagState == null}>{t("Falg")}: {`${flagState ?  t("Flag On") :  t("Flag Of")}`}</li>
                            <li hidden={!statusState}>{t("Status")}: {`${statusState}`}</li>
                            </ul>
                          </div> 
          </div>
          
          </Col>
          <Col md="auto" className="d-flex justify-content-end">
            <Button
              disabled={syncWithAzureRunning}
              className="btn btn-primary me-2"
              color={syncWithAzureRunning ? "secondary" : "primary"}
              style={{ height: "38px" }}
              onClick={() => syncHardwaresListWithADMutation.mutate()}
            >
              {props.t("Fetch From Azure")}
              {syncWithAzureRunning && (
                <span className="ps-1">
                  <Spinner animation="border" variant="secondary" size="sm" />
                </span>
              )}
            </Button>

            <Button
              className="btn btn-primary me-2"
              color="primary"
              style={{ height: "38px" }}
              onClick={() => openUploadDialog(uploadCSV.mutate)}
            >
              {props.t("Upload CSV")}
              <span className="ps-1">
                <img className="pb-1" width={24} height={24} src={UploadIcon} />
              </span>
            </Button>
            <Button
              className="btn btn-primary me-2"
              color="primary"
              style={{ height: "38px" }}
              onClick={() =>
                handleDownloadCSV(
                  `Hardware List - ${new Date().toDateString()}.xlsx`
                )
              }
            >
              {props.t("Download Excel")}
              <span className="ps-1">
              <img className="pb-1" width={24} height={24} src={DownloadIcon} />
              </span>
            </Button>
            <Button
              className="btn btn-primary me-2"
              color="primary"
              style={{ height: "38px" }}
              onClick={() =>
                handleDownloadTemplate(
                  `Software List - ${new Date().toDateString()} - template.xlsx`
                )
              }
            >
              {props.t("Download Template")}
              <span className="ps-1">
              <img className="pb-1" width={24} height={24} src={DownloadIcon} />
              </span>
            </Button>
            <Link
         
              className=" max-content-height  btn d-flex  border-primary hover:bg-primary hover:text-white bg-white text-primary"
              to={"hardwares/create"}
            >
              {props.t("Add Asset")}
            </Link>
          </Col>
        </Row>

        {/* page body */}
        <PageBody classNames="px-4">
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: tableFilters.pageIndex,
              sizePerPage: tableFilters.pageSize,
              totalSize: tableResultTotalCount,
              withFirstAndLast: false,
              alwaysShowAllBtns: true,
              prePageText: (
                <span>
                  <i className="ri-arrow-left-s-line"></i> {t("Back")}
                </span>
              ),
              nextPageText: (
                <span>
                  {t("Next")} <i className="ri-arrow-right-s-line"></i>
                </span>
              ),
              prePageTitle: t("Pre page"),
              firstPageTitle: t("Next page"),
              showTotal: true,
              hideSizePerPage: false,
              sizePerPageList: [
                {
                  text: "25",
                  value: 25,
                },
                {
                  text: "50",
                  value: 50,
                },
              ],
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <React.Fragment>
                <Row>
                  <Col sm="12">
                    <BootstrapTable
                      remote={{
                        pagination: true,
                        filter: false,
                        sort: true,
                        cellEdit: false,
                        search: false,
                      }}
                      loading={
                        handleFetchHardwareListQuery.isFetching ||
                        handleFetchHardwareListQuery.isLoading
                      }
                      overlay={overlayFactory({
                        spinner: (
                          <Spinner
                            animation="border"
                            variant="primary"
                            size="md"
                          />
                        ),
                        text: "Loading...",
                      })}
                      onTableChange={handleTableChange}
                      defaultSorted={[]}
                      keyField={"id"}
                      responsive
                      bordered={false}
                      data={hardwares?.assets || []}
                      striped={false}
                      columns={tableColumns}
                      wrapperClasses="table-responsive"
                      classes={"table tpdd-table"}
                      headerWrapperClasses={"thead-light"}
                      style={{
                        overflowX: "auto",
                      }}
                      noDataIndication={() => <NoDataIndication />}
                      {...paginationTableProps}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6">
                    <div className="tpdd-pagination-style-1">
                      <PaginationListStandalone {...paginationProps} />

                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </PaginationProvider>
        </PageBody>
      </Container>

      {/* Deletion Modal */}
      {users?.length ? (
        <HandoverModal
          t={t}
          modal={showHandOverModal}
          setModal={setHandoverModal}
          users={users}
          id={selectedId}
        />
      ) : null}
      {users?.length ? (
        <Deletion
          t={t}
          modal={showDeletionModal}
          setModal={setDeletionModal}
          users={users}
          id={selectedId}
          setDeletionComplete={setDeletionComplete}
        />
      ) : null}
    </PageContent>
  );
};

const mapStatetoProps = (state) => {
  const { token } = state.Login;
  return {
    token,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      errorNotification,
      successNotification,
    })(memo(DataProtectionHardwareList))
  )
);
