import { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { 
    Row, 
    Col, 
    FormGroup,
    Label,
    Input,
    Alert
} from 'reactstrap';
import { API_BASE_URL } from 'src/modules/3rd-party-management/constants';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import QuestionnaireService from 'src/modules/3rd-party-management/apis/QuestionnaireService';
import LongQuestionnaire from './LongQuestionnaire';
import ShortQuestionnaire from './ShortQuestionnaire';
import {
    helpers
} from '@smartintegrity/questionnaire';

const questionnaireTypes = ['short', 'long'];

const QuestionsLoadingPlaceholder = () => {
    return (
        <div>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                        <Col lg="6">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mb-4">
                <Col lg="12">
                    <p style={{width: '90%'}} className={`dt-field dt-skeleton`}></p>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col lg="12">
                            <p style={{width: '100%'}} className={`dt-field dt-skeleton`}></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

const BafaQuestionnaire = ({
    t,
    token,
    supplierId
}) => {
    const [ questionnaireType, setQuestionnaireType ] = useState();
    const [ reportCase, setReportCase ] = useState({
        id: null,
        refetch: false,
        isLoading: true,
        isCreatedBefore: false
    });
    const [ questions, setQuestions ] = useState([]);
    const [ prevAnswers, setPrevAnswers ] = useState([]);
    const [ noReportRequestFound ,setNoReportRequestFound ] = useState(false);

    const {
        data: fetchQuestionsResponse,
        refetch: refechQuestions
    } = useQuery({
		queryKey: [
            '3rd-party-fetch-bafa-questionnaire-query',
            questionnaireType,
            supplierId
        ],
		queryFn: async () => {
			const service = QuestionnaireService.getInstance();

            setNoReportRequestFound(false);

            setReportCase({
                id: null,
                refetch: false,
                isLoading: true,
                isCreatedBefore: false
            });
	
			return await service.fetchPrevFilledAnswers('tpdd', supplierId, {
                qType: questionnaireType
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		retry: 0,
		onError: ({
			response
		}) => {
			if((response.data.error).toLowerCase() === 'there is not any report for this questionnaire'){
				axios.post(`${API_BASE_URL}/report.create`, {
					"questionnaireType"		:	questionnaireType === 'short' ? 'short_tpdd_bafa' : 'tpdd_bafa',
                    "supplier"              :   supplierId,
					"questionAnswers"	    :	[]
				}, {
					headers: {
						Authorization: `Bearer ${token}`,
					}
				})
				.then(() => {
					setReportCase((currentState) => {
                        return {
                            ...currentState,
                            refetch: true
                        }
                    });
				})
				.catch(({
                    response
                }) => {
                    if((response.data.error).toLowerCase() === 'there is not any report request'){
                        toast(t('It is not possible to send a report before the request is created by admin'), {
                            type: 'error',
                        });

                        setNoReportRequestFound(true);
                        setReportCase((currentState) => {
                            return {
                                ...currentState,
                                isLoading: false
                            }
                        });
                    }
                    else{
                        toast(t('An error occurred while starting!'), {
                            type: 'error',
                        });
                    }
				});
			}
		},
		onSuccess : (response) => {
            setReportCase({
                id: response.reportCaseId,
                refetch: false,
                isLoading: false,
                isCreatedBefore: response.hasSubsidiary
            });
		}
	});

    useEffect(() => {
        if(questionnaireTypes.includes(questionnaireType)){
            refechQuestions();
        }
    }, [ questionnaireType ]);

    useEffect(() => {
        if(reportCase.refetch){
            refechQuestions();
        }
	}, [reportCase.refetch]);

    useEffect(() => {
        if(fetchQuestionsResponse?.questions){
            setPrevAnswers(helpers.makePreviousAnswersReadyForQuestionnaire(
                fetchQuestionsResponse.questions,
                fetchQuestionsResponse.questions
            ));

            setTimeout(() => {
                setQuestions(helpers.makeQuestionsReadyForQuestionnaire(
                    fetchQuestionsResponse.questions,
                    true
                ));
            }, 1000);
        }
    }, [ fetchQuestionsResponse?.questions ]);

    return (
        <div className="p-4">
            <Row>
                <Col sm='12'>
                    <Alert color='info'>
                        <FormGroup>
                            <Label>
                                {t('Would you prefer to complete the Full Reporting Obligation questionnaire or the Abbreviated version?')}
                            </Label>
                            <Input 
                                onChange={(e) => {
                                    setQuestionnaireType(e.target.value)
                                }} 
                                value={questionnaireType} 
                                type='select'
                                style={{
                                    width: '260px'
                                }}
                            >
                                <option>
                                    {t('Select...')}
                                </option>
                                <option value='short'>
                                    {t('Abbreviated Reporting Obligation')}
                                </option>
                                <option value='long'>
                                    {t('Full Reporting Obligation')}
                                </option>
                            </Input>
                        </FormGroup>
                    </Alert>
                </Col>
            </Row>

            {questionnaireTypes.includes(questionnaireType) && (
                <Row>
                    <Col sm='12'>
                        {noReportRequestFound && (
                            <Alert color="warning" className="d-flex justify-content-start">
                                <div className="d-flex align-items-center me-2">
                                    <i className="ri-alert-line" style={{fontSize: '32px'}}/>
                                </div>
                                <div className="d-flex align-items-start justify-content-center flex-column">
                                    <strong>{t('Please Pay Attention')}</strong>
                                    {t('It is not possible to send a report before the request is created by admin')}
                                </div>
                            </Alert>
                        )}

                        {(reportCase.isLoading) && (
                            <QuestionsLoadingPlaceholder />
                        )}

                        {reportCase.id && questions.length > 0 && (
                            <>
                                {questionnaireType === 'long' && (
                                    <LongQuestionnaire 
                                        supplierId={supplierId}
                                        questions={questions}
                                        prevAnswers={prevAnswers}
                                        reportCaseId={reportCase.id}
                                        reportIsNew={!reportCase.isCreatedBefore}
                                    />
                                )}

                                {questionnaireType === 'short' && (
                                    <ShortQuestionnaire
                                        supplierId={supplierId}
                                        questions={questions}
                                        prevAnswers={prevAnswers}
                                        reportCaseId={reportCase.id}
                                        reportIsNew={!reportCase.isCreatedBefore}
                                    />
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            )}
        </div>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	
	return {
		token,
		user
	};
};

export default withNamespaces()(
    connect(mapStatetoProps)(
        BafaQuestionnaire
    )
);