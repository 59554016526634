import { withNamespaces } from "react-i18next";
import { useState } from "react";
import { 
    Row, 
    Col, 
    NavItem, 
    NavLink
} from 'reactstrap';

const Wizard = ({
    t,
    displaySubsidiariesTab,
    children
}) => {
    const [ tabs, setTabs ] = useState([
        {
			name		:	'select-subsidiaries-step',
			title		:	'Select Subsidiaries',
			completed	:	false,
			isCurrent	:	displaySubsidiariesTab,
            hide        :   !displaySubsidiariesTab
		},
		{
			name		:	'fill-questions-step',
			title		:	'Fill Questionnaire',
			completed	:	false,
			isCurrent	:	!displaySubsidiariesTab
		},
		{
			name		:	'review-details',
			title		:	'Review Details',
			completed	:	false,
			isCurrent	:	false
		},
		{
			name		:	'declaration',
			title		:	'Declaration',
			completed	:	false,
			isCurrent	:	false
		}	
	]);

    const setActiveTab = (tab) => {
		setTabs((tabs) => {
			const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);
			const nextActiveTabIndex = tabs.findIndex((t) => t.name === tab);

			if(currentActiveTabIndex > -1){
				tabs[currentActiveTabIndex].completed = true;
				tabs[currentActiveTabIndex].isCurrent = false;
			}

			if(nextActiveTabIndex > -1){
				tabs[nextActiveTabIndex].isCurrent = true;
			}

			return [
				...tabs
			];
		});
	}

    const currentActiveTabIndex = tabs.findIndex((t) => t.isCurrent);

    return (
        <>
            <Row>
                <Col sm={12}>
                    <div className="m-2 bg-white wizard-steps-container mb-5">
                        <div className="twitter-bs-wizard">
                            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills p-3">
                                {
                                    tabs.filter((tab) => !tab.hide).map((tab, index) => {
                                        return (
                                            <NavItem key={ index } className={ tab.completed ? 'completed' : '' }>
                                                <NavLink className={ (index <= currentActiveTabIndex ? 'active ' : '') + (tab.isCurrent ? 'current' : '') }>
                                                    <span className="step-number">
                                                        {
                                                            tab.completed ? (
                                                                <i className='fa fa-check'></i>
                                                            ) : ( index + 1 )
                                                        }
                                                    </span>

                                                    <span className="step-title">
                                                        {t(tab.title)}
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    {children({
                        activeTab: tabs[currentActiveTabIndex], 
                        setActiveTab
                    })}
                </Col>
            </Row>
        </>
    )
}

export default withNamespaces()(
    Wizard
);