import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { EditorState } from "draft-js";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Label,
    Row
} from "reactstrap";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import {
  CompanySizes
} from "src/modules/3rd-party-management/constants";

import ThirdpartyUsersService from "src/modules/3rd-party-management/apis/ThirdpartyUsersService";

import { isFeatureEnabledForActiveModule } from "src/helpers/module_helper";

const filterOptions = (
    candidate,
    input
) => {
    if (input) {
        const candidateInput = candidate.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        const normalizedInput = input.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        return candidateInput.indexOf(normalizedInput) >= 0 || ((candidate.data.keywords || []).findIndex((keyword) => {
            const normalizedKeyword = keyword.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

            return normalizedKeyword.indexOf(normalizedInput) >= 0
        }) >= 0);
    }

    return true;
};

const CompanyDetails = ({
    t,
    supplierType,
    defaultValues,
    setCompanyDetailsTabCompleted,
    memoizedIndustriesList,
    memoizedEntitiesList,
    isLoading
}) => {
    const [ selectedIndusries, setSelectedIndustries ] = useState(defaultValues?.industries || []);
    const [ legalEntities, setLegalEntities ] = useState(defaultValues?.entities || []);
    const [ description, setDescription ] = useState(defaultValues?.description || EditorState.createEmpty());

    const {
        mutate      :   checkThirdpartyExistenceMutation,
        isLoading   :   checkingThirdpartyExistenceInProcess
    } = useMutation({
        mutationFn: async (payload) => {
            const service = ThirdpartyUsersService.getInstance();

            return await service.checkThirdpartyExistence(payload);
        }
    });

    useEffect(() => {
        if(selectedIndusries.length > 0 && memoizedIndustriesList.length > 0){
            const result = selectedIndusries.map((sIndustry) => {
                return memoizedIndustriesList.find((listItem) => listItem.value === sIndustry.value);
            });

            setSelectedIndustries(result);
        }
    }, [ memoizedIndustriesList ]);
    

    const onValidSubmit = (_e, values) => {
        checkThirdpartyExistenceMutation({
            name        :   values.company_name,
            websiteUrl  :   values.website,
            vatNumber   :   values.vat_number || undefined
        }, {
            onSuccess: () => {
                setCompanyDetailsTabCompleted({
                    ...values,
                    entities: legalEntities,
                    description: description
                })
            },
            onError: (error) => {
                if(error.response?.data?.error === 'invalid_vatNumber'){
                    toast(t('A company with this VAT ID already exists.'), {
                        type: "error",
                    });
                }
                else{
                    toast(t('A company with this name already exists.'), {
                        type: "error",
                    });
                }
            }
        })
    }

    const sizes = [];

    for(const sizeKey in CompanySizes){
        sizes.push({
            value : sizeKey,
            label : `${CompanySizes[sizeKey]?.title} ${t("Employees")}`
        });
    }

    const renderCreditorBody = () => {
        return (
            <CardBody className="p-0">
                <p className="sub-header medium">
                    {t("General Information")}
                </p>

                <Row>
                    <Col md="4" sm="12">
                        <Label for="company_name">
                            {t("Company Name")}: 
                        </Label>

                        <AvField
                            name="company_name"
                            placeholder=""
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            defaultValue={defaultValues?.company_name}
                            id="company_name"
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="selected-industries">
                            {t("Industries")}: 
                        </Label>

                        <Select
                            isDisabled={ isLoading }
                            isLoading={ isLoading }
                            placeholder={t("Select") + "..."}
                            classNamePrefix="select2-selection"
                            id='selected-industries'
                            options={memoizedIndustriesList}
                            menuPortalTarget={document.body}
                            onChange={(e) => setSelectedIndustries(e)}
                            value={selectedIndusries}
                            filterOption={filterOptions}
                            isMulti
                        />

                        <AvField
                            name="industries"
                            type="hidden"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            value={selectedIndusries}
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="vat_number">
                            {t("VAT ID")}: 
                        </Label>
                        <AvField
                            id="vat_number"
                            name="vat_number"
                            placeholder=""
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: false },
                            }}
                            defaultValue={defaultValues?.vat_number}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    {isFeatureEnabledForActiveModule('entity_management') && (
                        <Col md="4" sm="12">
                            <Label for="connected-legal-entities">
                                {t("Connected Legal Entities")}: 
                            </Label>

                            <Select
                                isMulti
                                isDisabled={ isLoading }
                                isLoading={ isLoading }
                                placeholder={t("Select") + "..."}
                                classNamePrefix="select2-selection"
                                id="connected-legal-entities"
                                options={memoizedEntitiesList}
                                menuPortalTarget={document.body}
                                onChange={(e) => {
                                    setLegalEntities(e)
                                }}
                                value={legalEntities}
                                filterOption={filterOptions}
                            />
                        </Col>
                    )}

                    <Col md="4" sm="12">
                        <Label for="company_size">
                            {t("Company Size")}: 
                        </Label>
                        <AvField
                            id="company_size"
                            placeholder={t('Select...')}
                            name="company_size"
                            type="select"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            defaultValue={defaultValues?.company_size || '0-10'}
                        >
                            {sizes.map((size, index) => {
                                return (
                                    <option key={index} value={size.value}>
                                        {size.label}
                                    </option>
                                )
                            })}
                        </AvField>
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="website">
                            {t("Website")}: 
                        </Label>
                        <AvField
                            name="website"
                            id="website"
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            defaultValue={defaultValues?.website}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Label for="description">
                            {t("Description")}: 
                        </Label>
                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => setDescription(e)}
                            toolbar={{
                                options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "colorPicker",
                                    "link",
                                    "remove",
                                    "history",
                                ],
                                inline: {
                                    options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "monospace",
                                    ],
                                    bold: { className: "bordered-option-classname" },
                                    italic: { className: "bordered-option-classname" },
                                    underline: { className: "bordered-option-classname" },
                                    strikethrough: {
                                    className: "bordered-option-classname",
                                    },
                                    code: { className: "bordered-option-classname" },
                                },
                                blockType: {
                                    className: "bordered-option-classname",
                                },
                                fontSize: {
                                    className: "bordered-option-classname",
                                }
                            }}
                        />
                    </Col>
                </Row>
            </CardBody>
        )
    }

    const renderOwnCompanyBody = () => {
        return (
            <CardBody className="p-0">
                <p className="sub-header medium">
                    {t("General Information")}
                </p>

                <Row>
                    <Col md="4" sm="12">
                        <Label for="industry">
                            {t("Legal Entity")}: 
                        </Label>

                        <Select
                            isDisabled={ isLoading }
                            isLoading={ isLoading }
                            placeholder={t("Select") + "..."}
                            classNamePrefix="select2-selection"
                            id="legal-entities"
                            options={memoizedEntitiesList}
                            menuPortalTarget={document.body}
                            onChange={(e) => {
                                setLegalEntities(e)
                            }}
                            value={legalEntities}
                            filterOption={filterOptions}
                        />

                        <AvField
                            name="company_name"
                            type="hidden"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            id="company_name"
                            value={legalEntities?.label}
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="selected-industries">
                            {t("Industries")}: 
                        </Label>

                        <Select
                            id="selected-industries"
                            isDisabled={ isLoading }
                            isLoading={ isLoading }
                            placeholder={t("Select") + "..."}
                            classNamePrefix="select2-selection"
                            options={memoizedIndustriesList}
                            menuPortalTarget={document.body}
                            onChange={(e) => setSelectedIndustries(e)}
                            value={selectedIndusries}
                            filterOption={filterOptions}
                            isMulti
                        />

                        <AvField
                            name="industries"
                            type="hidden"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            value={selectedIndusries}
                        />
                    </Col>

                    <Col md="4" sm="12">
                        <Label for="vat_number">
                            {t("VAT ID")}: 
                        </Label>
                        <AvField
                            name="vat_number"
                            id="vat_number"
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: false },
                            }}
                            defaultValue={defaultValues?.vat_number}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col md="4" sm="12">
                        <Label for="company_size">
                            {t("Company Size")}: 
                        </Label>
                        <AvField
                            id="company_size"
                            placeholder={t('Select...')}
                            name="company_size"
                            type="select"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            defaultValue={defaultValues?.company_size || '0-10'}
                        >
                            {sizes.map((size, index) => {
                                return (
                                    <option key={index} value={size.value}>
                                        {size.label}
                                    </option>
                                )
                            })}
                        </AvField>
                    </Col>

                    <Col md="8" sm="12">
                        <Label for="website">
                            {t("Website")}: 
                        </Label>

                        <AvField
                            name="website"
                            type="text"
                            errorMessage={t("This field cannot be blank")}
                            className="form-control"
                            validate={{
                                required: { value: true },
                            }}
                            id="website"
                            defaultValue={defaultValues?.website}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Label for="description">
                            {t("Description")}: 
                        </Label>

                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => setDescription(e)}
                            toolbar={{
                                options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "list",
                                    "textAlign",
                                    "colorPicker",
                                    "link",
                                    "remove",
                                    "history",
                                ],
                                inline: {
                                    options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                    "monospace",
                                    ],
                                    bold: { className: "bordered-option-classname" },
                                    italic: { className: "bordered-option-classname" },
                                    underline: { className: "bordered-option-classname" },
                                    strikethrough: {
                                    className: "bordered-option-classname",
                                    },
                                    code: { className: "bordered-option-classname" },
                                },
                                blockType: {
                                    className: "bordered-option-classname",
                                },
                                fontSize: {
                                    className: "bordered-option-classname",
                                }
                            }}
                        />
                    </Col>
                </Row>
            </CardBody>
        )
    }

    return (
        <>
            <AvForm
                className="needs-validation"
                onValidSubmit={onValidSubmit}
                id="company_details"
                autocomplete="off"
            >
                <Card className="wizard-steps-content-container">
                    {supplierType === 'own' ? (
                        <>
                            {renderOwnCompanyBody()}
                        </>
                    ) : (
                        <>
                            {renderCreditorBody()}
                        </>
                    )}
                </Card>

                <Row className="mt-2 mb-4">
                    <FormGroup
                        style={{
                            display: "flex",
                            flexFlow: "row wrap",
                            justifyContent: "flex-end",
                        }}
                        className="actions-buttons-container"
                    >
                        <Button disabled={checkingThirdpartyExistenceInProcess} color="primary">
                            {checkingThirdpartyExistenceInProcess && (
                                <Spinner className="me-2" animation="border" variant="white" size="sm"/>
                            )}
                            {t("Next")}
                        </Button>
                    </FormGroup>
                </Row>
            </AvForm>
        </>
    );
};

const mapStateToProps = (state) => {
	return {
		modules: state.Modules
	};
};

export default withNamespaces()(
    connect(mapStateToProps, null)(
        CompanyDetails
    )
);