import { useState } from "react";
import { withNamespaces } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, { 
    PaginationProvider, 
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import { useQuery } from "@tanstack/react-query";
import SupplierService from "src/modules/3rd-party-management/apis/SupplierService";
import { toast } from "react-toastify";
import { Col, Row, Spinner } from "reactstrap";
import { EMPTY_LIST } from "src/common/constants";
import OverviewHeader from "../../../../components/overview-header";

const RelatedIncidents = ({
    t,
    supplierId
}) => {
    const [ filters, setFilters ] = useState({
        pageIndex: 1,
        pageSize: 25
    });

    const tableColumns = [
        {
            dataField :   "secondaryId",
            text      :   t("Secondary ID"),
            sort      :   false,
            key       :   1,
            style     :   {
                width : '160px'
            }
        },
        {
            dataField :   "description",
            text      :   t("Title"),
            sort      :   false,
            key       :   2
        },
        {
            dataField :   "adminCategory",
            text      :   t("Admin Category"),
            sort      :   false,
            key       :   3,
            formatter :   (cellContent) => {
                return (cellContent || []).join(', ')
            }
        },
        {
            dataField :   "adminDescription",
            text      :   t("Admin Description"),
            sort      :   false,
            key       :   4
        }
    ];

    const fetchIncidentsQuery = useQuery({
		queryKey: [
            '3rd-party-management-fetch-related-incidents-query', 
            supplierId,
            filters
        ],
		queryFn: async () => {
			const service = SupplierService.getInstance();

            return await service.fetchRelatedAssets({
                supplierId,
                ...filters
            });
		},
		cacheTime: 0,
		refetchOnWindowFocus: false,
		onError: () => {
			toast(t('An error occurred while fetching the related incidents list.'), {
				type: 'error',
			});
		},
	});

    const NoDataIndication = () => (
        (fetchIncidentsQuery.isFetched && !(fetchIncidentsQuery?.data?.data?.incidents || []).length) ? <div className="alert m-0" role="alert">
            <p style={{
                    textAlign: "center",
                    marginBottom: 0
                }}>
                {t(EMPTY_LIST)}
            </p>
        </div> : <></>
    );

    const handleTableChange = (type, options) => {
        const {
            page,
            sizePerPage,

            sortField,
            sortOrder
        } = options;

        switch(type){
            case 'pagination':
                    setFilters({
                        ...filters,
                        pageIndex   :   page,
                        pageSize    :   sizePerPage
                    });
                break;
            case 'sort':
                    setFilters({
                        ...filters,
                        sortField   :   sortField,
                        sortOrder   :   sortOrder.toUpperCase()
                    })
                break;
            default:
                return false;
        }
    }

    const totalItemsCount = parseInt(fetchIncidentsQuery?.data?.data?.itemsCount || 0);

    return (
        <div className="p-4">
            <Row>
                <Col sm="12" className="mb-4">
                    <OverviewHeader 
                        supplierId={ supplierId } 
                    />
                </Col>

                <Col 
                    sm="12" 
                    md="12"
                    className="mb-4"
                >
                    <PaginationProvider
                        pagination={
                            paginationFactory({
                                custom              :   true,
                                page                :   filters.pageIndex,
                                sizePerPage         :   filters.pageSize,
                                totalSize           :   totalItemsCount,
                                withFirstAndLast    :   true,
                                alwaysShowAllBtns   :   true,
                                prePageText         :   <span><i className="ri-arrow-left-s-line"></i> {t('Back')}</span>,
                                nextPageText        :   <span>{t('Next')} <i className="ri-arrow-right-s-line"></i></span>,
                                prePageTitle        :   t('Pre page'),
                                firstPageTitle      :   t('First page'),
                                lastPageTitle       :   t('Last page'),
                                showTotal           :   false,
                                paginationTotalRenderer :   (from, to, size) => {
                                    return (
                                        <span className="react-bootstrap-table-pagination-total">
                                            {`${t('Total Items')} ${size}`}
                                        </span>
                                    )
                                },
                                hideSizePerPage     :   false,
                                sizePerPageList     :   [
                                    {
                                        text: '25', 
                                        value: 25
                                    }, 
                                    {
                                        text: '50', 
                                        value: 50
                                    }
                                ]
                            })
                        }>
                        {
                            ({
                                paginationProps,
                                paginationTableProps
                            }) => (
                                <>
                                    <Row>
                                        <Col sm="12">
                                            <BootstrapTable
                                                remote={{
                                                    pagination: true,
                                                    filter: false, 
                                                    sort: true,
                                                    cellEdit: false,
                                                    search: false
                                                }}
                                                loading={ fetchIncidentsQuery.isFetching || fetchIncidentsQuery.isLoading }
                                                overlay={ overlayFactory({ spinner: <Spinner animation="border" variant="primary" size="md"/>, text : `${t("Loading")}...` }) }
                                                onTableChange={ handleTableChange }
                                                defaultSorted={[]}
                                                keyField="secondary_id"
                                                responsive
                                                bordered={ false }
                                                data={ fetchIncidentsQuery?.data?.data?.incidents || [] }
                                                striped={true}
                                                columns={tableColumns}
                                                wrapperClasses="table-responsive"
                                                classes={"table tpdd-table"}
                                                headerWrapperClasses={"thead-light"}
                                                style={{
                                                    overflowX: "auto",
                                                }}
                                                noDataIndication={ () => <NoDataIndication /> }
                                                { ...paginationTableProps }
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="12">
                                            <div className="tpdd-pagination-style-1">
                                                <PaginationTotalStandalone
                                                    { ...paginationProps }
                                                />
                                                
                                                {totalItemsCount > filters.pageSize && (
                                                    <div>
                                                        <PaginationListStandalone
                                                            { ...paginationProps }
                                                        />
                                                        
                                                        <SizePerPageDropdownStandalone
                                                            { ...paginationProps }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }

                    </PaginationProvider>
                </Col>
            </Row>
        </div>
    )
};

export default withNamespaces()(
    RelatedIncidents
);