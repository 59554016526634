const API_BASE_URL              =   process.env.REACT_APP_GDPR_CUSTOMER_API_ENDPOINT;
const CORE_API_URL              =   process.env.REACT_APP_GDPR_CORE_API_ENDPOINT;
const CHAT_BOT_DOMAIN           =   process.env.REACT_APP_GDPR_CHAT_BOT_DOMAIN;
const RISK_AI_DOMAIN            =   process.env.REACT_APP_GDPR_RISK_AI_DOMAIN;
const SOFTWARE_AI_API_DOMAIN    =   process.env.REACT_APP_GDPR_SOFTWARE_GENERATE_BY_AI;

const API_URL_FETCH_COUNTRIES               =   API_BASE_URL + '/report.country.list';
const API_URL_FETCH_LANGUAGES               =   API_BASE_URL + "/language/list";
const API_URL_CREATE_THIRD_PARTY            =   CORE_API_URL + '/organization/create';
const API_URL_CREATE_THIRD_PARTY_WITH_CSV   =   CORE_API_URL + '/organization/create_csv';

const API_URL_FETCH_RISK_RATINGS                =   API_BASE_URL + '/risk_rate/ratings';
const API_URL_GET_ANALYST_REPORT_LIST           =   API_BASE_URL + '/report.list';
const API_URL_GET_ANALYST_REPORT_LISTS_COUNT    =   API_BASE_URL + '/reports/count';
const API_URL_GET_REPORT_LIST                   =   API_BASE_URL + '/report.listMyCases';
const API_URL_REPORT_CATEGORY_LIST_CASE         =   API_BASE_URL + '/report-category-case.list';
const API_URL_CREATE_REPORT                     =   API_BASE_URL + '/report.create';
export const API_URL_FETCH_RISK_LIKELIHOODS     =   API_BASE_URL + "/risk_rate/likelihoods";
export const API_URL_RISK_COSTOMIZATION         =   API_BASE_URL + "/risk_rate/customization";
export const API_URL_RISK_RESTORE_COSTOMIZATION =   API_BASE_URL + "/risk_rate/customization_restore";
  

const API_URL_GET_HISTORY_LOGS                     =   API_BASE_URL + '/logs';

const API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST     =   API_BASE_URL + "/analystAndAdminAnalyst.list";
const API_URL_ADMIN_AND_TASK_OWNERS_LIST        =  API_BASE_URL + "/analystAndAdminAnalystAndTaskOwners.list";
const API_URL_REPORT_UPDATE_ADMIN_CATEGORIES    =   API_BASE_URL + "/report.changeAdminCategory";
const API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION   =   API_BASE_URL + "/report.changeAdminDescription";
const API_URL_REPORT_MARK_AS_DELETE             =   API_BASE_URL + "/report.markAsDelete";
const API_URL_UPDATE_REPORT_RISK_RATE           =   API_BASE_URL + "/report.updateRiskRate";
const API_URL_GET_INTERNAL_COMMENT_LIST         =   API_BASE_URL + "/report.listCommentInternal";
const API_URL_GET_COMMENT_LIST                  =   API_BASE_URL + "/report.listComment";
const API_URL_REPORT_ADD_LINK_CASE              =   API_BASE_URL + "/report/add_link_case";
const API_URL_REPORT_REMOVE_LINK_CASE           =   API_BASE_URL + "/report/remove_link_case";
const API_URL_REPORT_DELETE                     =   API_BASE_URL + "/report.delete";
const API_URL_REPORT_ASSIGNED_ANALYST           =   API_BASE_URL + "/report.changeAnalyst";
const API_URL_GET_REPORT_DETAIL                 =   API_BASE_URL + "/report.view";
const API_URL_UPDATE_REPORT_STATUS              =   API_BASE_URL + "/report.updateStatus";
const API_URL_ADD_INTERNAL_COMMENT              =   API_BASE_URL + "/report.addCommentInternal";
const API_URL_REPORT_UPLOAD_FILE                =   API_BASE_URL + "/report.uploadAttachment";
const API_URL_REPORT_UPLOAD_MULTIPLE_FILE       =   API_BASE_URL + "/report.uploadAttachments"
const API_URL_ADD_COMMENT                       =   API_BASE_URL + "/report.addComment";    
const API_URL_TASK_DOWNLOAD_COMMENT_FILES       =   API_BASE_URL;
const API_URL_TASK_POST_COMMENT                 =   API_BASE_URL + "/task_comment/create";
const API_URL_RISK_UPLOAD_FILES                 =   API_BASE_URL + "/risk_rate/upload_files";
const API_URL_TASK_UPLOAD_FILES                 =   API_BASE_URL + "/task/upload_files";
const API_URL_CREATE_RISK                       =   API_BASE_URL + "/risk_rate/create";
const API_URL_RISK_UPLOAD_FILE                  =   API_BASE_URL + "/risk_rate/upload_file";
const API_URL_FETCH_MANAGERS                    =   API_BASE_URL + "/manager.list";
const API_URL_FETCH_RISK_LOCATIONS              =   API_BASE_URL + "/report.country.list";
const API_URL_FETCH_RISK_CATEGORIES             =   API_BASE_URL + "/risk_rate_category/index";
const API_URL_FETCH_RISK_MATRIX                 =   API_BASE_URL + "/risk_rate/matrix";
const API_URL_TASK_UPLOAD_FILE                  =   API_BASE_URL + "/task/upload_file";
const API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS  =   API_BASE_URL + "/report.downloadUserAttachment";
const API_URL_REPORT_DOWNLOAD_ANALYST_ATTACHMENTS   = API_BASE_URL + "/report.downloadAnalystAttachment";
const API_URL_DELETE_VOICE_BY_ANALYST_ADMIN     =   API_BASE_URL + "/voice_report/delete_voice";
const API_URL_TASK_DETAILS                      =   API_BASE_URL;
const API_URL_TASK_GET_COMMENTS                 =   API_BASE_URL;

const API_URL_GET_ADMIN_DASHBAORD               =   API_BASE_URL + "/dashboard";
const API_URL_ADMIN_DASHBOARD_LAST_CASES        =   API_BASE_URL + "/last_cases";

const API_URL_RISK_AI_TRANSLATION               =   CHAT_BOT_DOMAIN + "api/risk/change.get";
const API_URL_RISK_AI_GET_ADDITIONAL_DETAILS    =   CHAT_BOT_DOMAIN + "api/gdpr/risk-ai.get";
const API_URL_GET_RISK_AI_CLASS                 =   RISK_AI_DOMAIN + "promts";

const API_URL_SOFTWARE_GENERATE_BY_AI           =   SOFTWARE_AI_API_DOMAIN + "api/getsoftwareinfo";

const API_URL_GET_NOTIFICATION_LIST             =   API_BASE_URL + "/notification.list";
const API_URL_NOTIFICATION_MARK_READ            =   API_BASE_URL + "/notification.markAsRead";
const API_URL_NOTIFICATION_REMOVE        =   API_BASE_URL + "/notification/";
const API_URL_NOTIFICATION_MARK_ALL_READ        =   API_BASE_URL + "/notification.markAllAsRead";
const API_URL_GET_FEATURE_FLAGGING_SETTINGS     =   API_BASE_URL + "/user.featureFlagging";
const API_URL_GET_MODULE_SETTINGS               =   API_BASE_URL + "/organization.verify";
const API_URL_UPDATE_MODULE_SETTINGS            =   API_BASE_URL + "/organization.edit";
const API_URL_GET_NOTIFICATION_SETTINGS_LIST    =   API_BASE_URL + "notificationSetting.list";

const API_URL_ADMIN_GET_GDPR_USER_MANAGEMENT_LIST  =  API_BASE_URL + "/employee.list";
export const API_URL_ADMIN_GET_USER_MANAGEMENT_DETAIL = API_BASE_URL + "/employee.detail";
export const API_URL_ADMIN_UPDATE_USER_MANAGEMENT   =    API_BASE_URL + "/employee.edit";
const API_URL_ADMIN_DELETE_GDPR_USER_MANAGEMENT    =  API_BASE_URL + "/employee.delete";
const API_URL_ADMIN_CREATE_GDPR_USER_MANAGEMENT    =  API_BASE_URL + "/employee.create";
  

export {
    CORE_API_URL,
    API_BASE_URL,
    CHAT_BOT_DOMAIN,
    SOFTWARE_AI_API_DOMAIN,
    RISK_AI_DOMAIN,
    API_URL_FETCH_COUNTRIES,
    API_URL_FETCH_LANGUAGES,
    API_URL_CREATE_THIRD_PARTY,
    API_URL_CREATE_THIRD_PARTY_WITH_CSV,
    API_URL_FETCH_RISK_RATINGS,
    API_URL_GET_ANALYST_REPORT_LIST,
    API_URL_GET_ANALYST_REPORT_LISTS_COUNT,
    API_URL_GET_REPORT_LIST,
    API_URL_REPORT_CATEGORY_LIST_CASE,
    API_URL_CREATE_REPORT,

    API_URL_ADMIN_GET_ASSIGN_ANALYST_LIST,
    API_URL_ADMIN_AND_TASK_OWNERS_LIST,
    API_URL_REPORT_UPDATE_ADMIN_CATEGORIES,
    API_URL_REPORT_UPDATE_ADMIN_DESCRIPTION,
    API_URL_REPORT_MARK_AS_DELETE,
    API_URL_UPDATE_REPORT_RISK_RATE,
    API_URL_GET_INTERNAL_COMMENT_LIST,
    API_URL_GET_COMMENT_LIST,
    API_URL_REPORT_ADD_LINK_CASE,
    API_URL_REPORT_REMOVE_LINK_CASE,
    API_URL_REPORT_DELETE,
    API_URL_REPORT_ASSIGNED_ANALYST,
    API_URL_GET_REPORT_DETAIL,
    API_URL_UPDATE_REPORT_STATUS,
    API_URL_ADD_INTERNAL_COMMENT,
    API_URL_REPORT_UPLOAD_FILE,
    API_URL_REPORT_UPLOAD_MULTIPLE_FILE,
    API_URL_ADD_COMMENT,
    API_URL_TASK_DOWNLOAD_COMMENT_FILES,
    API_URL_TASK_POST_COMMENT,
    API_URL_RISK_UPLOAD_FILES,
    API_URL_TASK_UPLOAD_FILES,
    API_URL_CREATE_RISK,
    API_URL_RISK_UPLOAD_FILE,
    API_URL_FETCH_MANAGERS,
    API_URL_FETCH_RISK_LOCATIONS,
    API_URL_FETCH_RISK_CATEGORIES,
    API_URL_FETCH_RISK_MATRIX,
    API_URL_TASK_UPLOAD_FILE,
    API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS,
    API_URL_REPORT_DOWNLOAD_ANALYST_ATTACHMENTS,
    API_URL_DELETE_VOICE_BY_ANALYST_ADMIN,
    API_URL_TASK_DETAILS,
    API_URL_TASK_GET_COMMENTS,

    API_URL_GET_ADMIN_DASHBAORD,
    API_URL_ADMIN_DASHBOARD_LAST_CASES,

    API_URL_RISK_AI_TRANSLATION,
    API_URL_RISK_AI_GET_ADDITIONAL_DETAILS,
    API_URL_GET_RISK_AI_CLASS,

    API_URL_SOFTWARE_GENERATE_BY_AI,

    API_URL_GET_NOTIFICATION_LIST,
    API_URL_NOTIFICATION_MARK_READ,
    API_URL_NOTIFICATION_REMOVE,
    API_URL_GET_FEATURE_FLAGGING_SETTINGS,
    API_URL_GET_MODULE_SETTINGS,
    API_URL_UPDATE_MODULE_SETTINGS,
    API_URL_NOTIFICATION_MARK_ALL_READ,
    API_URL_GET_NOTIFICATION_SETTINGS_LIST,

    API_URL_GET_HISTORY_LOGS,

    API_URL_ADMIN_GET_GDPR_USER_MANAGEMENT_LIST,
    API_URL_ADMIN_DELETE_GDPR_USER_MANAGEMENT,
    API_URL_ADMIN_CREATE_GDPR_USER_MANAGEMENT
};
