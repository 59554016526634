import { Progress, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import { useEffect, useState } from "react";
const TwitterWizardNavItem = ({ children, currentActiveTabKey, uniqueKey, onClick}) => {
    return (
        <NavItem onClick={() => onClick(uniqueKey)}>
            <NavLink style={{
                cursor: 'default'
            }} className={classnames({
                active: uniqueKey === currentActiveTabKey,
            })}>
                {children}
            </NavLink>
        </NavItem>
    );
}

const TwitterWizard = ({ children, activeTab: gotActiveTab }) => {
    const childrenLength = children.filter((child) => !!child).length;

    const [progressValue, setProgressValue] = useState(1 / childrenLength * 100);
    const [activeTab, setActiveTab] = useState(gotActiveTab);

    const findTabNumber = (tabUniqueKey) => {
        return (children.findIndex((child) => {
            return child.props?.uniqueKey === tabUniqueKey;
        })) + 1;
    }

    const renderProgressBar = () => {
        return (
            <div id="bar" className="mt-4">
                <Progress
                    color="success"
                    striped
                    animated
                    value={ progressValue }
                />
            </div>
        );
    }

    const handleNavClick = (tabNumber, tabUniqueKey) => {
        // setActiveTab(tabUniqueKey);
        // setProgressValue(tabNumber / childrenLength * 100);
    }

    useEffect(() => {
        setActiveTab(gotActiveTab);
        setProgressValue(findTabNumber(gotActiveTab) / childrenLength * 100);
    }, [gotActiveTab]);

    return (
        <div className="twitter-bs-wizard">
            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                {
                    children.map((child, index) => {
                        if(child){
                            return (
                                <child.type {...child.props} 
                                    key={ index }  
                                    currentActiveTabKey={ activeTab }
                                    onClick={ (tabUniqueKey) => handleNavClick(index + 1, tabUniqueKey) }>
                                    {child.props?.children}
                                </child.type>
                            );
                        }
                    })
                }
            </ul>
            
            {renderProgressBar()}
        </div>
    );
}

export default TwitterWizard;

export {
    TwitterWizard,
    TwitterWizardNavItem
};