import { useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom"; 
import {
    API_BASE_URL
} from 'src/modules/3rd-party-management/constants';
import FillQuestionnaire from './components/fill-questionnaire';
import SelectSubsidiaries from './components/select-subsidiaries';
import Wizard from './components/wizard';
import {
    AnswerSheet
} from '@smartintegrity/questionnaire';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from "react-toastify";
import { confirmPromptHide } from "src/store/actions";
import { 
    Card, 
    CardBody, 
    CardTitle, 
    Button,
    Spinner
} from 'reactstrap';

const ShortQuestionnaire = ({
    t,
    supplierId,
    questions,
    prevAnswers,
    reportCaseId,
    reportIsNew,
    hideConfirmPrompt,
    token
}) => {
    const history = useHistory();

    const [ questionnaireData, setQuestionnaireData ] = useState({
		reportCaseId	:	reportCaseId,
		questions		:	questions,
		answers			:	null
	});

    const [ isCreateNewFormSubmiting, setIsCreateNewFormSubmiting ] = useState(false);
    const [ declarationFormConfirmed, setDeclarationFormConfirmed ] = useState(false);

    const handleSubmit = () => {
		setIsCreateNewFormSubmiting(true);

		axios.post(`${API_BASE_URL}/report/${questionnaireData.reportCaseId}/submit`, questionnaireData.answers, {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		})
		.then(() => {
			toast(t('Request Submitted'), {
				type: 'success',
			});

			setTimeout(() => {
                history.push(`/admin/3rd-party/suppliers/${supplierId}/details?tab=reports`)
			}, 250);
		})
		.catch(() => {
			toast(t('Error!'), {
				type: 'error',
			});
		})
		.finally(() => {
			setIsCreateNewFormSubmiting(false);
            hideConfirmPrompt();
		});
	}

    return (
        <Wizard
            displaySubsidiariesTab={reportIsNew}
        >
            {({
                activeTab,
                setActiveTab
            }) => {
                if(activeTab.name === 'fill-questions-step'){
                    return (
                        <FillQuestionnaire 
                            questionnaireType='short'
                            supplierId={supplierId}
                            reportCaseId={reportCaseId}
                            questions={questions}
                            prevAnswers={prevAnswers}
                            onBackButtonClicked={() => {
                                setActiveTab('select-subsidiaries-step')
                            }}
                            onValidSubmit={ ({
                                answers
                            }) => {
                                setQuestionnaireData((currentState) => {
                                    return {
                                        ...currentState,
                                        answers: answers
                                    }
                                });
                                setActiveTab('review-details')
                            }}
                        />
                    )
                }
                else if(activeTab.name === 'review-details'){
                    return (
                        <div className="m-2">
                            <AnswerSheet 
                                settings={{
                                    buttons	:	{
                                        back	:	{
                                            enabled	:	true
                                        },
                                        confirm	:	{
                                            enabled	:	true,
                                            title	:	'Next'
                                        }
                                    }
                                }}
                                questions={ questionnaireData.questions }
                                answers={ questionnaireData.answers }
                                onConfirmed={() => {
                                    setActiveTab('declaration');
                                }}
                                onBackButtonClicked={() => {
                                    setActiveTab('fill-questions-step');
                                }}
                            />
                        </div>
                    )
                }
                else if(activeTab.name === 'declaration'){
                    return (
                        <div className="declaration-confirm-form m-2 mt-0">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-3">
                                        { t('Declaration of Accuracy and Completeness') }
                                    </CardTitle>

                                    <p className="mb-5">
                                        {
                                            t('I hereby affirm that the information provided in this questionnaire is true, accurate, and complete to the best of my knowledge and belief. I understand that any false statements, omissions, or misrepresentations may result in consequences as determined by the entity requesting this information (which could include legal actions or disqualification from a process, depending on the context). I commit to notify the relevant parties immediately should any information change after the submission of this questionnaire.')
                                        }
                                    </p>

                                    <div>
                                        <Form.Check
                                            onChange={({ target }) => setDeclarationFormConfirmed(target.checked)} 
                                            type='checkbox' 
                                            id={`confirm-declaration-checkbox`} 
                                            label={t('I confirm the information above.')} />
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="d-flex justify-content-end align-items-center mb-4">
                                <Button color="primary"
                                    disabled={ !declarationFormConfirmed || isCreateNewFormSubmiting }
                                    onClick={ handleSubmit }
                                    type="button">
                                        {
                                            isCreateNewFormSubmiting ? (
                                                <>
                                                    <Spinner className="me-2" animation="border" size="sm"/>
                                                    {t('Submitting')}...
                                                </>
                                            ) : (
                                                <>{t('Submit')}</>
                                            )
                                        }
                                </Button>
                            </div>
                        </div>
                    )
                }
                else {
                    return (
                        <SelectSubsidiaries 
                            reportCaseId={reportCaseId}
                            supplierId={supplierId}
                            onValidSubmit={() => {
                                setActiveTab('fill-questions-step')
                            }}
                        />
                    )
                }
            }}
        </Wizard>
    )
};

const mapStatetoProps = (state) => {
	const { token, user } = state.Login;
	const { Organization, App } = state;
	
	return {
		token,
		user,
		Organization,
		App
	};
};

export default withNamespaces()(
	withRouter(
		connect(mapStatetoProps, {
            hideConfirmPrompt	:	() => confirmPromptHide()
		})(ShortQuestionnaire)
	)
);