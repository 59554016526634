import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Button, Row, Col, Label } from "reactstrap";
import {
  ASSIGN_CASE_BEFORE_CHANGING_STATUS,
  CASE_ALREADY_HAS_BEEN_DELETED,
  ASSIGN_CASE_TO_AN_ANALYST,
  CLOSE_CASE_BEFORE_PENDING_DELETION,
  SET_PENDING_DELETION_BEFORE_DELETE,
  DELETE_CASE_SUCCESSFUL,
  DELETE_CASE_FAILED,
  UPDATE_STATUS_SUCCESSFUL,
  UPDATE_STATUS_FAILED,
} from "../../../../../../../../common/constants";
import {
  REPORT_STATUS_ASSIGNED,
  REPORT_STATUS_CLOSED,
  REPORT_STATUS_PENDING_DELETION,
  REPORT_STATUS_DELETED,
} from "src/components/constants/Reports";
import ReportIncidenService from "../../../components/api/ReportIncidenService";
import Request from "../../../../../../../../services/Request";
import Select from "react-select";
import {
  REPORT_STATUS_LIST,
  ANALYST_REPORT_STATUS_LIST,
} from "../../../../../../../../components/constants";
import UserUtils from "src/services/utils/UserUtils";
import { INTERNATIONAL_DATE_FORMAT } from "../../../../../../../../common/constants";
import DateUtils from "src/services/utils/DateUtils";
import LinkCasess from "./components/linkCasess";
import ReadOnlyPermissions from "./components/readOnlyPermissions";
import Describtion from "./components/describtion";
import CategoryByAdmin from "./components/categoryByAdmin";
import RelatedAssets from "./components/relatedAssets";
import MemorizedDeletionReasonModal from "src/pages/Reporting/Components/ReportComponents/DeletionReasonModal";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
// import { ReportDetailPDF } from "src/components/documents";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import ReportDetailPDF from "src/modules/data-protection/components/documents/ReportDetailPDF";
import { API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS } from "src/modules/data-protection/constants";
import { map } from "rxjs";

const ReportFiled = (props) => {
  const {
    t,
    caseId,
    categories,
    reportLoading,
    report,
    analystAdminsList,
    adminsListIsLoading,
    token,
    currentUser,
  } = props;
  const request = new Request();
  const userUtils = new UserUtils();
  const dateUtils = new DateUtils();
  const [showDeletionReasonModal, setShowDeletionReasonModal] = useState(false);
  const [ analystAdmin, setAnalystAdmin] = useState(null);

  const handleSetPendingDeletionStatusMutation= useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.PendingDeletionStatus(payload);
    },
  });

  const handleDeleteReportMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.DeleteReport(payload);
    },
  });

  const handleUpdateStatusMutation = useMutation({
    mutationFn: async (payload) => {
      const service = ReportIncidenService.getInstance();

      return await service.UpdateStatus(payload);
    },
  });

  const handleUpdateAssignedAnalystMutation = useMutation({
    mutationFn: async (payload) => {
        const service = ReportIncidenService.getInstance();

        return await service.updateAssignedAnalyst(payload);
    },
    onError: () => {
        toast(t("An error occurred while updating assigned analyst."), {
            type: "error",
        });
    }
});

  const handleSupplierAssignedAnalystChange = (newAnalyst) => {
    handleUpdateAssignedAnalystMutation.mutate({
        report_case_id        :   caseId,
        analyst_id    :   parseInt(newAnalyst.value)
    }, {
        onSuccess   :   () => {
          setAnalystAdmin(newAnalyst);
          props.reportRefetch();
            toast(t("Assigned analyst updated successfully."), {
                type: "success",
            });
        }
    });
  }

  useEffect(() => {
    if (report && !reportLoading) {
      if(report?.analyst_assigned?.id){
      const analystName =  {
        value:report?.analyst_assigned?.id,
        label:`${report?.analyst_assigned?.first_name} ${report?.analyst_assigned?.last_name}`}
        setAnalystAdmin(analystName);
      }
        else setAnalystAdmin(null);
    }
    
  }, [report, reportLoading]);

  // Function to toggle the deletion reason modal
  const handleToggleDeletionReasonModal = () => {
    setShowDeletionReasonModal(!showDeletionReasonModal);
  };

  const isAnalystOrAnalystAdmin = () => {
    return userUtils.isAnalystOrAnalystAdmin(currentUser);
  };

  const isAnalystAdmin = () => {
    return userUtils.isAnalystAdmin(currentUser);
  };

  const handleGetFormatedUpdateDate = (title, date, format) => {
    return (
      <small className="text-muted">
        {title}
        <span className="text-info">
          {dateUtils.convertTimeStampToDate(date, format)}
        </span>
      </small>
    );
  };




  const handleExportCase = async (
    name,
    organizationLogo,
    organizationQuestions,
    organizationQuestionsCategories,
    currentUser,
    report,
    comments
  ) => {
    const fileName = `${name}.pdf`;

    const blob = await pdf(
      <ReportDetailPDF
        logo={organizationLogo}
        orgQuestions={organizationQuestions}
        categories={organizationQuestionsCategories}
        user={currentUser}
        report={report}
        comments={comments}
        downloadId={2}
      />
    ).toBlob();

    saveAs(blob, fileName);
  };

  const downloadAttachments = async (type) => {
    const {organization, report, caseId} = props;
    if(type === "internal") {
      return handleExportCase(
          caseId,
          organization.logo,
          report?.contents,
          props.organizationQuestionsCategories,
          props.currentUser,
          report,
          props.comments
        )
    }
      
    const url = API_URL_REPORT_DOWNLOAD_USER_ATTACHMENTS

    try {
      const response = request.downloadZip(
        url,
        "POST",
        {
          report_case_id: report?.case_id,
        },
        token,
        {
          responseType: "arraybuffer",
        }
      );

      // Handle response
      response.pipe(
        map((response) => {
          return response;
        })
      );
    } catch (error) {
      toast(t("Failed to download. Please try again later."), {
        type: "error",
      });
    }
  };

  const handlehandleDeleteReport = (item) => {
    handleDeleteReportMutation.mutate(
      {
        caseId: caseId,
      },
      {
        onSuccess: () => {
          toast(t(DELETE_CASE_SUCCESSFUL), {
            type: "success",
          });
          props.reportRefetch();
        },
        onError: (error) => {
          toast(t(DELETE_CASE_FAILED), {
            type: "error",
          });
        },
      }
    );
  };

  const handleUpdateStatusChange = (item) => {
    handleUpdateStatusMutation.mutate(
      {
        caseId: caseId,
        status: item,
      },
      {
        onSuccess: () => {
          toast(t(UPDATE_STATUS_SUCCESSFUL), {
            type: "success",
          });
          props.reportRefetch();
        },
        onError: (error) => {
          toast(t(UPDATE_STATUS_FAILED), {
            type: "error",
          });
        },
      }
    );
  };

  const handleSetPendingDeletionStatus = (caseId, deletionReason, token) => {
    handleSetPendingDeletionStatusMutation.mutate(
      {
        reason: deletionReason,
        caseId: caseId,
      },
      {
        onSuccess: () => {
          toast(t("Data was saved successfully"), {
            type: "success",
          });
          props.reportRefetch();
        },
        onError: (error) => {
          toast(t(error?.response?.data?.message?.status), {
            type: "error",
          });
        },
      }
    );
  };
  const handleChangeStatus = (newStatus, caseStatus, caseAnalyst) => {
    // avoid any change if the new status is equal to the current status of the case.
    if (newStatus === caseStatus) return;

    if (!caseAnalyst) {
      toast(t(ASSIGN_CASE_BEFORE_CHANGING_STATUS), {
        type: "error",
      });
      return;
    }

    // avoid change the status, if selected case has been already deleted.
    if (caseStatus === REPORT_STATUS_DELETED) {
      toast(t(CASE_ALREADY_HAS_BEEN_DELETED), {
        type: "error",
      });
      return;
    }
    // avoid change the status to ASSIGNED, if there is no analyst assigned to the case.
    else if (newStatus === REPORT_STATUS_ASSIGNED && !caseAnalyst) {
      toast(t(ASSIGN_CASE_TO_AN_ANALYST), {
        type: "error",
      });
      return;
    } else if (newStatus === REPORT_STATUS_PENDING_DELETION) {
      // avoid change the status to pending deletion, if the case already is not closed.
      if (caseStatus !== REPORT_STATUS_CLOSED) {
        toast(t(CLOSE_CASE_BEFORE_PENDING_DELETION), {
          type: "error",
        });
        return;
      }
      // open deletion reason modal.
      else {
        handleToggleDeletionReasonModal();
        return;
      }
    } else if (newStatus === REPORT_STATUS_DELETED) {
      // avoid delete case if the current status of the case is not equal to PENDING DELETION.
      if (caseStatus !== REPORT_STATUS_PENDING_DELETION) {
        toast(t(SET_PENDING_DELETION_BEFORE_DELETE), {
          type: "error",
        });
        return;
      }

      // delete case.
      else {
        handlehandleDeleteReport();
        return;
      }
    }

    // update status if the flow was correct.
    handleUpdateStatusChange(newStatus);
  };

  const renderReportStatus = () => {
    const currentStatus = REPORT_STATUS_LIST.find(
      (i) => i.id === report?.status
    );
    let current = {};
    if (currentStatus) {
      current = {
        value: report?.status,
        label: t(currentStatus.titleKey),
      };
    }

    let statuses = null;
    if (isAnalystAdmin) {
      statuses = REPORT_STATUS_LIST.map((item) => {
        return {
          value: item.id,
          label: t(item.titleKey),
        };
      });
    } else if (isAnalystOrAnalystAdmin) {
      statuses = ANALYST_REPORT_STATUS_LIST.map((item) => {
        return {
          value: item.id,
          label: t(item.titleKey),
        };
      });
    }

    return (
      <>
        {!reportLoading ? (
          isAnalystOrAnalystAdmin ? (
            <>
              <Select
                placeholder={t("Select")}
                classNamePrefix="select2-selection"
                options={statuses}
                title={t("Status")}
                value={current}
                onChange={(newStatus) =>
                  handleChangeStatus(
                    newStatus.value,
                    current.value,
                    report?.analyst_assigned
                  )
                } //
                isDisabled={
                  report?.is_read_only_analyst ||
                  report?.deleted_by_name ||
                  report?.is_deleted_content
                }
              />
              {handleGetFormatedUpdateDate(
                `${t("Status Updated")} `,
                report?.status_updating_date,
                INTERNATIONAL_DATE_FORMAT
              )}
            </>
          ) : (
            // non-analyst member
            <>
              {/* <div >{props?.t(status.label)}</div> */}
              <br />
              {handleGetFormatedUpdateDate(
                `${t("Status Updated")} `,
                report?.status_updating_date,
                INTERNATIONAL_DATE_FORMAT
              )}
            </>
          )
        ) : (
          // skeleton
          <>
            <p
              className={`dt-field dt-skeleton ${
                isAnalystOrAnalystAdmin ? "dt-select-list" : ""
              }`}
            ></p>
            <p className="dt-field dt-skeleton dt-update-date"></p>
          </>
        )}
      </>
    );
  };

  const renderAssignedAnalysts = () => {

    const adminUpdateInProgress = handleUpdateAssignedAnalystMutation.isFetching || handleUpdateAssignedAnalystMutation.isLoading;
    const isLoading = adminsListIsLoading || adminUpdateInProgress || reportLoading;
  
  
    return (
      <div className="d-flex justify-content-center align-items-center flex-column">
       <Select
        key="select"
        isDisabled={isLoading || report?.deleted_by_name || report?.is_deleted_content || report?.is_read_only_analyst}
        isLoading={isLoading}
        placeholder={`${t("Select")}...`}
        classNamePrefix="select2-selection"
        options={analystAdminsList}
        menuPortalTarget={document.body}
        onChange={handleSupplierAssignedAnalystChange}
       
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            width: '100%', // Set width to 100%
            maxWidth: '396px', // Set the maximum width to 396px
            backgroundColor: 'transparent',
            padding: ' 0px 4px',
            borderColor: 'hsl(0, 0%, 80%)',
            height:'32px',
            
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            display: 'flex',
            alignItems: 'center',
            width:'100%',
            paddingtop:'-18px'
          }),
          container: (baseStyles) => ({
            ...baseStyles,
            width: '100%', // Set container width to 100%
            maxWidth: '396px' // Set max width for the container
          }),
      
        }}
        value={analystAdmin}
      />
        {/* {!analystAdmin ? null : <>
        {avatarSrc && avatarSrc !== "" ? (
          <div className="avatar-xs">
            <span className="avatar-title bg-soft-primary text-primary rounded-circle text-capitalize" style={{ overflow: "hidden" }}>
              <img
                alt={report?.analyst_assigned?.first_name}
                src={avatarSrc}
                style={{
                  width: "40px",
                  height: "40px",
                  display: "block",
                  objectFit: "cover",
                }}
              />
            </span>
          </div>
        ) : (
          <AvatarGroup max={4}>{Avatars}</AvatarGroup>
        )}
         </>} */}
      </div>
    );
  };
  
  return (
    <div className="d-flex flex-column gap-4 mb-4 mt-1">
      <div className="d-flex justify-content-end gap-3">
        <Button
          onClick={() => downloadAttachments("internal")}
          className="incident-download-button"
          disabled={
            report?.deleted_by_name || report?.is_deleted_content
          }
        >
          {t("Download Incident Report")}
        </Button>
        <Button
          onClick={() => downloadAttachments("case")}
          className="incident-download-button"
          disabled={report?.attachments.length === 0}
        >
          {t("Download Attachments")}
        </Button>
      </div>

      <MemorizedDeletionReasonModal
        title="Delete Data Breach Incident"
        t={t}
        isDeleted={report?.is_deleted_content}
        handleSetPendingDeletionStatus={handleSetPendingDeletionStatus} 
        reportId={caseId}
        authToken={token}
        isOpen={showDeletionReasonModal}
        handleToggleDeletionReasonModal={handleToggleDeletionReasonModal}
      />

      <div className="incident-Details-card">
        <Row className=" w-100">
          <Col sm="12" md="4" lg="4" className="p-2">
            <CategoryByAdmin
              reportLoading={reportLoading}
              categories={categories}
              caseId={caseId}
              report={report}
              reportRefetch={()=>props.reportRefetch()}
              
            />
          </Col>
          <Col sm="12" md="4" lg="4" className="p-2">
            <RelatedAssets
              report={report}
              caseId={caseId}
              reportLoading={reportLoading}
            />
          </Col>
          <Col sm="12" md="4" lg="4" className="p-2">
            <div style={{ maxWidth: "396px" }}>
              <Label>{t("Assigned Analyst")}</Label>
              {renderAssignedAnalysts()}
            </div>
          </Col>
        </Row>
        <Row className=" w-100 mt-5 mb-2">
        <Col sm="12" md="4" lg="4" className="p-2">
            <div style={{ maxWidth: "396px" }}>
              <Label>{t("Status")}</Label>
              {renderReportStatus()}
            </div>
          </Col>
            <Col sm="12" md="4" lg="4" className="p-2">
              <ReadOnlyPermissions
                caseId={caseId}
                report={report}
                
                reportLoading={reportLoading}
               
              />
            </Col>
          <Col sm="12" md="4" lg="4" className="p-2">
            <LinkCasess
              report={report}
              caseId={caseId}
              reportLoading={reportLoading}
            />
          </Col>
        </Row>

        <Row className=" w-100" hidden={!userUtils.isAnalystOrAnalystAdmin(props?.currentUser)}>
          <Col sm="12" md="4" lg="4" className="p-2">
            <div className="mb-3">
                <Label>
                  {props.t("Channel")}:
                </Label>
                {!props.reportLoading ? (
                  <p className="dt-field">
                    <span className="badge p-2 bg-primary">
                      {props.t(report?.source?.charAt(0)?.toUpperCase() + report?.source?.slice(1)?.toLowerCase() || "Web")}
                    </span>
                  </p>
                ) : (
                  <p className="dt-field dt-skeleton"></p>
                )}
              </div>
          </Col>
        </Row>

        <Row className=" w-100 mt-4 mb-2">
          <Col sm="12" md="12" lg="12" className="p-2">
             <Describtion
              caseId={caseId}
              report={report}
              reportLoading={reportLoading}
              reportRefetch={()=>props.reportRefetch()}
            />
          </Col>
          </Row>
      </div>
    </div>
  );

  
};

const mapStatetoProps = (state) => {
  const { token, user: currentUser } = state.Login;
  const { Feature, CMFeature, Organization } = state;

  const activeModule = localStorage.getItem("module");
  return {
    currentUser,
    token,
    organization: Organization,
    Feature:
      activeModule === "wb"
        ? Feature
        : activeModule === "cm"
        ? CMFeature
        : null,
  };
};

export default withNamespaces()(connect(mapStatetoProps)(ReportFiled));
