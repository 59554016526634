import { getFeatureStatusByTitle } from "./feature_helper";

import store from '../store';

const getActiveModuleInfos = () => {
	const { 
		Modules : modules
	} = store.getState();

	return modules.list.find((m) => m.name === modules.active);
}

const isSettingEnabledForActiveModule = (settingKey) => {
	const activeModule = getActiveModuleInfos();

	return (activeModule && activeModule.settings && (!!activeModule.settings[settingKey]));
}

const isFeatureEnabledForActiveModule = (featureName) => {
	const activeModule = getActiveModuleInfos();

	return (activeModule && activeModule.features && getFeatureStatusByTitle(activeModule.features, featureName))
}

const hasUserAccessToModule = (moduleName) => {
    const { 
		Modules : modules
	} = store.getState();

    return modules.list.findIndex((module) => {
        return module.name === moduleName
    }) > -1;
}

export { 
	getActiveModuleInfos,
	isSettingEnabledForActiveModule,
	isFeatureEnabledForActiveModule,
    hasUserAccessToModule
};