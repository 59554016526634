import { all } from 'redux-saga/effects'

import loginSaga from './auth/login/saga';
import LayoutSaga from './layout/saga';
import WorkspaceSaga from './workspace/saga';
import NotificationSaga from './notification/saga';
import chatSaga from "./chat/saga"
import modulesSaga from './modules/saga';
import organizationSaga from './organization/saga';

export default function* rootSaga() {
    yield all([
        loginSaga(),
        organizationSaga(),
        LayoutSaga(),
        chatSaga(),
        WorkspaceSaga(),
        NotificationSaga(),
        modulesSaga()
    ])
}